import { Grid } from "@mui/material";

const MainContentGridContainer = ({ children }) => {
  return (
    <Grid
      container
      position="relative"
      justifyContent="center"
      alignItems="stretch"
      spacing={2}
    >
      {children}
    </Grid>
  );
};

export default MainContentGridContainer;
