import { Button, Grid, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { HubLogo } from "assets/img/images";
import Logo from "Components/AppHeader/Logo";
import LegalPageContent from "Components/LegalPage/LegalPageContent";
import { getYYYYMMDDfromIso } from "helpers/helpers";
import { getUserId } from "helpers/localstoragehelper";
import { useIsDesktop } from "hooks/useIsDesktop";
import PropTypes from "prop-types";
import { useState } from "react";
import { useGetLegalQuery } from "reduxStore/api/legalApiSlice";
import { useSendPrivacyAcceptedMutation } from "reduxStore/api/userApiSlice";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingY: 1, lineHeight: "1.5rem" }}>{children}</Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-privacytab-${index}`,
  };
}

const TermsTabs = ({ handleClose }) => {
  const isDesktop = useIsDesktop();
  const [sendPrivacy, { isLoading }] = useSendPrivacyAcceptedMutation();

  const { data: termsData, isSuccess: termsSuccess } =
    useGetLegalQuery("terms");
  const { data: privacyData, isSuccess: privacySuccess } =
    useGetLegalQuery("privacy");

  const [value, setValue] = useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const sendAcceptPrivacy = async () => {
    const today = getYYYYMMDDfromIso();
    await sendPrivacy({
      id: getUserId(),
      payload: {
        isTermsApprovalRequired: false,
        termsAcceptedOn: today,
        termsAcceptedVersion: termsData?.version,
        dataPrivacyAcknowledgedOn: today,
        dataPrivacyAcknowledgedVersion: privacyData?.version,
      },
    })
      .unwrap()
      .then(() => handleClose())
      .catch((error) => console.error("rejected", error));
  };

  return (
    <Paper
      elevation={4}
      sx={{
        width: { xs: "100%", md: "80%" },
        maxWidth: { md: "1500px" },
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Grid
        container
        sx={{
          height: { xs: "100vh", md: "calc(-200px + 100vh)" },
          flexFlow: { xs: "column", md: "row" },
          overflow: "hidden",
        }}
      >
        <Grid
          item
          xs={12}
          md={2}
          sx={{
            paddingY: 2,
            paddingLeft: 2,
            backgroundColor: (theme) => theme.palette.inputBackgroundColor.main,
            flexBasis: { xs: "0%", md: "100%" },
          }}
        >
          <Logo logo={HubLogo} />
          <Tabs
            orientation={isDesktop ? "vertical" : "horizontal"}
            variant="scrollable"
            selectionFollowsFocus={false}
            value={value}
            onChange={handleChange}
            aria-label="Vertical terms tabs"
            sx={{
              borderRight: { md: 1 },
              borderColor: { md: "divider" },
              marginTop: 3,
            }}
          >
            <Tab label="Terms and Conditions" {...a11yProps(0)} />
            <Tab label="Privacy Policy" {...a11yProps(1)} />
          </Tabs>
        </Grid>
        <Grid
          item
          xs={12}
          md={10}
          sx={{
            height: "100%",
            overflowY: "auto",
            paddingY: 2,
            paddingX: 2,
          }}
        >
          <Typography>Please accept our latest conditions of use</Typography>
          <TabPanel value={value} index={0}>
            <LegalPageContent page="terms" />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <LegalPageContent page="privacy" />
          </TabPanel>
          <Box sx={{ textAlign: "right" }}>
            <Button
              disabled={!(termsSuccess && privacySuccess && !isLoading)}
              onClick={sendAcceptPrivacy}
              variant="contained"
            >
              Accept
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default TermsTabs;
