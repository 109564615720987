import { Box } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

const LinearLoader = () => {
  return (
    <Box sx={{ width: "20%", maxWidth: "225px" }}>
      <LinearProgress />
    </Box>
  );
};

export default LinearLoader;
