import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import ListIcon from "@mui/icons-material/List";
import {
  Alert,
  Box,
  Button,
  ButtonBase,
  ButtonGroup,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import { useActions } from "hooks/useActions";
import useUser from "hooks/useUser";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "../UI/PhoneInput/PhoneInput";
import { PageSubHeading } from "../UI/Typography/SharedTypography";
import OICollectionAddress from "./OICollectionAddress";
import DestinationDropdown from "./OIForm/DestinationDropdown";
import DeliverIntakeTable from "./OITable";

const OIOverview = ({ isCollect }) => {
  const { t } = useTranslation();
  const { addAnotherDelivery } = useActions();
  const userData = useUser();

  const [isCopyPreviousOrderVisible, setIsCopyPreviousOrderVisible] =
    useState(true);

  const handleIsAlertVisible = () =>
    setIsCopyPreviousOrderVisible((prev) => !prev);
  const handleAddAnotherDelivery = () => addAnotherDelivery();

  return (
    <>
      <PageSubHeading marginBottom={2}>
        {isCollect
          ? t("orderIntake.collectionAddress")
          : t("orderIntake.deliveryAddress")}
      </PageSubHeading>
      {isCollect ? <OICollectionAddress /> : <DestinationDropdown />}
      {isCopyPreviousOrderVisible && (
        <Alert
          icon={false}
          action={
            <ButtonGroup
              variant="text"
              sx={{
                ".MuiButtonGroup-grouped": {
                  border: 0,
                  mx: 1,
                  lineHeight: "0",
                },
              }}
            >
              <Button size="small" startIcon={<DoneIcon />}>
                <span>{t("orderIntake.materialPersistency.yes")}</span>
              </Button>
              <Button size="small" startIcon={<ListIcon />}>
                {t("orderIntake.materialPersistency.viewLastOrder")}
              </Button>
              <ButtonBase onClick={handleIsAlertVisible}>
                <CloseIcon sx={{ fontSize: "20px" }} />
              </ButtonBase>
            </ButtonGroup>
          }
          severity="info"
          sx={{ mt: 4 }}
          onClose={() => {}}
        >
          {t("orderIntake.materialPersistency.lastOrderDetails")}
        </Alert>
      )}
      <Stack marginTop={4}>
        <PageSubHeading marginBottom={2}>
          {t("orderIntake.cementTypeDelivery")}
        </PageSubHeading>
        <DeliverIntakeTable isCollect={isCollect} />
        <Paper
          onClick={handleAddAnotherDelivery}
          sx={{
            p: 1,
            backgroundColor: "rgba(1, 125, 209, .02)",
            "&:hover": { boxShadow: 4 },
            cursor: "pointer",
          }}
        >
          <Button
            startIcon={<AddIcon />}
            size="small"
            sx={{ textTransform: "capitalize" }}
          >
            {t("orderIntake.addDelivery")}
          </Button>
        </Paper>
      </Stack>
      <Box marginTop={2}>
        <PageSubHeading marginBottom={1}>
          {t("orderIntake.contactDetails")}
        </PageSubHeading>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            columnGap: 2,
          }}
        >
          <TextField
            id="contact-fullname"
            label={t("orderIntake.fullName")}
            defaultValue={userData?.name}
            helperText={t("required")}
            variant="filled"
            FormHelperTextProps={{
              sx: { ml: 0 },
            }}
            sx={{ flexBasis: "45%" }}
          />
          <PhoneInput label={t("orderIntake.mainPhone")} visible />
          {!isCollect && (
            <PhoneInput label={t("orderIntake.backupPhone")} isHideable />
          )}
        </Box>
      </Box>
    </>
  );
};

export default OIOverview;
