import DeliveryItem from "./DeliveryItem";

const InvoiceDeliveryCards = ({ isViewAll, deliveryCards, currency }) => {
  const formattedDeliveryData = deliveryCards.map((deliveryCard) => ({
    deliveryNumber: deliveryCard.delivery[0].deliveryNumber,
    orderNumber: deliveryCard.delivery[0].orderNumber,
    truckName: deliveryCard.delivery[0].truckName,
    shippingType: deliveryCard.delivery[0].shippingType,
    materialDescription: deliveryCard.materialDescription,
    loadQuantity: deliveryCard.quantity,
    loadQuantityUom: deliveryCard.quantityUom,
    rate: deliveryCard.unitPrice,
    currency,
  }));

  return isViewAll ? (
    formattedDeliveryData.map((delivery, index) => (
      <DeliveryItem key={index} delivery={delivery} isShown={isViewAll} />
    ))
  ) : (
    <DeliveryItem delivery={formattedDeliveryData[0]} />
  );
};

export default InvoiceDeliveryCards;
