import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import { ButtonBase, Divider } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useGetTableData } from "hooks/useGetTableData";
import SkeletonCard from "../../SkeletonCard/SkeletonCard";
import PageCardOrder from "./PageCardOrder";
import PageCardInvoice from "./PageCardInvoice";

const PageCard = () => {
  const { isLoading, data, isInvoices } = useGetTableData();

  return (
    <Box sx={{ minWidth: 275, maxWidth: 900, backgroundColor: "white" }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          padding: "8px 16px",
        }}
      >
        <Box>
          <Typography variant="h6">{`${data?.results?.length} item(s) listed`}</Typography>
        </Box>
        <Box>
          <ButtonBase>
            <SearchIcon sx={{ width: "35px", height: "35px" }} />
          </ButtonBase>
          <ButtonBase>
            <FilterListIcon sx={{ width: "35px", height: "35px" }} />
          </ButtonBase>
        </Box>
      </Box>
      <Divider
        sx={{
          margin: "0 16px",
        }}
      />
      {isLoading && <SkeletonCard />}
      {data &&
        data.results.map((item, index) =>
          isInvoices ? (
            <PageCardInvoice item={item} key={index} />
          ) : (
            <PageCardOrder item={item} key={index} />
          )
        )}
    </Box>
  );
};

export default PageCard;
