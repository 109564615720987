import { getYYYYMMDDfromIso } from "helpers/helpers";
import { useExportOrderListMutation } from "reduxStore/api/orderListApiSlice";
import useUser from "./useUser";

const useRequestDownload = () => {
  const userData = useUser();
  const [sendExportReq, { isLoading }] = useExportOrderListMutation();

  const makeRequest = async (criteria, type, format) => {
    const downloadRequest = await sendExportReq({
      criteria: JSON.stringify(criteria),
      type: type,
      format: format,
      name: `DeliveryExport-${format}-${getYYYYMMDDfromIso(new Date())}`,
      locale: "en-US",
      fullName: userData?.name,
      email: userData?.eMail,
      sendEmail: false,
      country: userData?.country,
      clientTimezone: "Europe/Moscow",
    }).unwrap();

    return downloadRequest;
  };

  return { makeRequest, isLoading };
};

export default useRequestDownload;
