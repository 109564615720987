import { Box } from "@mui/material";
import OrderListOIButtons from "Components/OrderList/OrderListOIButtons";
import ContentTable from "Components/UI/PageComponents/ContentTable/ContentTable";
import ItemDetails from "Components/UI/PageComponents/ItemDetails";
import LeftGrid from "Components/UI/PageComponents/LeftGrid";
import MainContentGridContainer from "Components/UI/PageComponents/MainContentGridContainer";
import PageCard from "Components/UI/PageComponents/PageCard/PageCard";
import PageFilters from "Components/UI/PageComponents/PageFilters";
import PageWrapper from "Components/UI/PageComponents/PageWrapper";
import RightGrid from "Components/UI/PageComponents/RightGrid";
import { PageHeading } from "Components/UI/Typography/SharedTypography";
import { PageTypeProvider } from "context/PageTypeProvider";
import { useIsComponentEnabled } from "hooks/useIsComponentEnabled";
import { useIsDesktop } from "hooks/useIsDesktop";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";

const OrderList = () => {
  const isDesktop = useIsDesktop();
  const { getIsComponentEnabled } = useIsComponentEnabled();
  const isComponentEnabled = getIsComponentEnabled("OrderList");
  const { t } = useTranslation();

  if (!isComponentEnabled) return <Navigate to="/overview" replace={true} />;

  return (
    <PageWrapper>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <PageHeading sx={{ paddingY: 5 }}>{t("order.title")}</PageHeading>
        <OrderListOIButtons />
      </Box>
      <PageTypeProvider PageType="OrderList">
        <MainContentGridContainer>
          <LeftGrid>
            {isDesktop ? (
              <>
                <PageFilters />
                <ContentTable />
              </>
            ) : (
              <PageCard />
            )}
          </LeftGrid>
          {isDesktop && (
            <RightGrid>
              <ItemDetails />
            </RightGrid>
          )}
        </MainContentGridContainer>
      </PageTypeProvider>
    </PageWrapper>
  );
};

export default OrderList;
