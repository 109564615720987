import InfoIcon from "@mui/icons-material/Info";
import { Box } from "@mui/material";
import LinearLoader from "Components/UI/Loader/LinearLoader";
import { useIsComponentEnabled } from "hooks/useIsComponentEnabled";
import { useIsDesktop } from "hooks/useIsDesktop";
import { useTranslation } from "react-i18next";
import { CaptionHeading } from "../UI/Typography/SharedTypography";
import DesktopButtonsOI from "./DesktopButtonsOI";
import MobileButtonsOI from "./MobileButtonsOI";

const OrderListOIButtons = () => {
  const { t } = useTranslation();
  const { getIsComponentEnabled, isFetching } = useIsComponentEnabled();
  const isDesktop = useIsDesktop();
  const isComponentEnabled = getIsComponentEnabled("OrderListOIButtons");

  if (isFetching) return <LinearLoader />;

  if (!isComponentEnabled)
    return (
      <Box sx={{ display: "flex", columnGap: 1 }}>
        <CaptionHeading sx={{ display: "contents" }}>
          <InfoIcon />
        </CaptionHeading>
        <CaptionHeading>
          {t("orderIntake.notAllowedToPlaceOrderForThisCustomer")}
        </CaptionHeading>
      </Box>
    );

  return isDesktop ? <DesktopButtonsOI /> : <MobileButtonsOI />;
};

export default OrderListOIButtons;
