import { apiSlice } from "./apiSlice";

export const creditWidgetApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCreditWidgetData: builder.query({
      query: (payerId) => `creditmanagement/?payerId=${payerId}`,
      transformResponse: (response) => response.results,
    }),
  }),
});

export const { useGetCreditWidgetDataQuery } = creditWidgetApiSlice;
