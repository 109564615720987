import ReceiptIcon from "@mui/icons-material/Receipt";
import { Button, CircularProgress } from "@mui/material";
import { downloadFileUsingType } from "helpers/helpers";
import { useGetTableData } from "hooks/useGetTableData";
import useRequestDownload from "hooks/useRequestDownload";
import { useTranslation } from "react-i18next";

const ContentTableExportButton = () => {
  const { t } = useTranslation();

  const { query } = useGetTableData();

  const { makeRequest, isLoading } = useRequestDownload();

  const downloadXlsx = async () => {
    const request = {
      startDate: query.shippingDate,
      endDate: query.endDate,
      excludeCancelledOrders: true,
      customerId: query.customerId,
    };

    const requestDownload = await makeRequest(
      request,
      "orderDeliveryExport",
      "xlsx"
    );

    await downloadFileUsingType(requestDownload);
  };

  return (
    <Button
      onClick={downloadXlsx}
      startIcon={
        isLoading ? (
          <CircularProgress size={".8em"} color="info" />
        ) : (
          <ReceiptIcon sx={{ paddingBottom: "2px" }} />
        )
      }
    >
      {t("order.export.buttontitle")}
    </Button>
  );
};

export default ContentTableExportButton;
