import EastIcon from "@mui/icons-material/East";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import {
  Box,
  Button,
  ButtonBase,
  CardContent,
  Grid,
  Paper,
} from "@mui/material";
import FormatCurrency from "Components/UI/FormatCurrency/FormatCurrency";
import FormatDate from "Components/UI/FormatDate/FormatDate";
import {
  OrderCardHeading,
  OrderCardText,
} from "Components/UI/Typography/OrderCard";
import { useActions } from "hooks/useActions";
import { useTranslation } from "react-i18next";

const FSMobileGroupedDelivery = ({
  groupedTableRows,
  groupedDeliveriesValues,
  setIsDeliveryItemsOpen,
}) => {
  const { t } = useTranslation();
  const { setInvoiceDeliveryDetails } = useActions();

  const handleOpenDeliveryItem = () => setIsDeliveryItemsOpen(true);

  const handleRowClick = (deliveryGroup) => {
    setIsDeliveryItemsOpen(true);
    setInvoiceDeliveryDetails(deliveryGroup);
  };

  return groupedTableRows.map((row, index) => (
    <Paper variant="outlined" sx={{ mb: 1 }}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid xs={12} item>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <OrderCardHeading variant="subtitle1">
                  {t("invoiceDetail.lineItems.dateShipped")}
                </OrderCardHeading>
                <OrderCardText>
                  <FormatDate
                    day="2-digit"
                    month="2-digit"
                    year="numeric"
                    timeStart={row.shippingDate}
                  />
                </OrderCardText>
              </Box>
              <ButtonBase
                onClick={() =>
                  handleRowClick({
                    summedData: groupedTableRows[index],
                    deliveries: groupedDeliveriesValues[index],
                  })
                }
              >
                <OpenInFullIcon />
              </ButtonBase>
            </Box>
          </Grid>
          <Grid xs={12} item>
            <OrderCardHeading variant="subtitle1">
              {t("invoiceDetail.lineItems.expand.materialDescription")}
            </OrderCardHeading>
            <OrderCardText>{row.materialDescription}</OrderCardText>
          </Grid>
          <Grid xs={12} item>
            <OrderCardHeading variant="subtitle1">
              {t("invoiceDetail.lineItems.contract")}
            </OrderCardHeading>
            <OrderCardText>{row.contractNumber}</OrderCardText>
          </Grid>
          <Grid xs={6} item>
            <OrderCardHeading variant="subtitle1">
              {t("invoiceDetail.lineItems.expand.quantity")}
            </OrderCardHeading>
            <OrderCardText>{`${row.quantity} ${row.quantityUom}`}</OrderCardText>
          </Grid>
          <Grid xs={6} item>
            <OrderCardHeading variant="subtitle1">
              {t("invoiceDetail.lineItems.netAmount")}
            </OrderCardHeading>
            <OrderCardText>
              <FormatCurrency value={row.unitPrice} currency={row.currency} />
            </OrderCardText>
          </Grid>
          <Grid xs={12} item>
            <Button
              variant="outlined"
              fullWidth
              startIcon={<EastIcon />}
              onClick={handleOpenDeliveryItem}
              sx={{ padding: "12px 10px" }}
            >
              {t("viewDetails")}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Paper>
  ));
};

export default FSMobileGroupedDelivery;
