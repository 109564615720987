import EastIcon from "@mui/icons-material/East";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { Button, ButtonBase, Card, CardContent, Divider } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { hourglass } from "assets/img/images";
import FormatDate from "Components/UI/FormatDate/FormatDate";
import { useNavigate } from "react-router-dom";

const PageCardInvoice = ({ item }) => {
  const navigate = useNavigate();
  const navigateToFullscreen = () =>
    navigate(`/invoices/${item.orderNumber}`, {
      state: item,
    });

  return (
    <Card
      variant="outlined"
      sx={{
        margin: "20px 16px",
        textAlign: "left",
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexGrow: 1,
              marginBottom: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <img src={hourglass} alt="hourglass" height="16px" />
              <Box sx={{ margin: "4px" }} />
              <Typography variant="h6">{item.status}</Typography>
            </Box>
            <ButtonBase onClick={navigateToFullscreen}>
              <OpenInFullIcon />
            </ButtonBase>
          </Box>
          <Box sx={{ marginBottom: "10px" }}>
            <Typography>Invoice date</Typography>
            <Box>
              <Box>
                <Typography variant="h6">
                  <FormatDate
                    day="2-digit"
                    month="2-digit"
                    year="numeric"
                    timeStart={item.invoiceDate}
                  />
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              marginBottom: "10px",
            }}
          >
            <Typography>Invoice number</Typography>
            <Box>
              <Typography variant="h6">{item.customerInvoiceNumber}</Typography>
            </Box>
          </Box>
          <Divider />
          <Box>
            <Typography>Net</Typography>
            <Typography>{item.invoiceNetValue}</Typography>
          </Box>
          <Box>
            <Typography>Gross</Typography>
            <Typography>{item.invoiceGrossValue}</Typography>
          </Box>
          <Box>
            <Button
              onClick={navigateToFullscreen}
              startIcon={<EastIcon />}
              fullWidth
              variant="outlined"
            >
              View details
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
export default PageCardInvoice;
