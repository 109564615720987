import { Divider, Grid, Paper } from "@mui/material";
import { Box } from "@mui/system";
import FullScreenDeliveriesContainer from "Components/FullscreenOrder/FullScreenDeliveriesContainer";
import FullScreenGridItem from "Components/FullscreenOrder/FullScreenGridItem";
import BackButton from "Components/UI/BackButton/BackButton";
import FormatDate from "Components/UI/FormatDate/FormatDate";
import StatusIcon from "Components/UI/StatusIcon/StatusIcon";
import StyledExportButton from "Components/UI/StyledExportButton/StyledExportButton";
import {
  OrderCardHeading,
  OrderCardText,
} from "Components/UI/Typography/OrderCard";
import {
  convertDeliveryTypeToText,
  convertOrderStatusToText,
  downloadFileUsingType,
} from "helpers/helpers";
import { useIsDesktop } from "hooks/useIsDesktop";
import useRequestDownload from "hooks/useRequestDownload";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

const iconStyles = { display: "flex", columnGap: "5px" };

const FullscreenOrder = () => {
  const { t } = useTranslation();
  const { state: currentOrderData } = useLocation();
  const isDesktop = useIsDesktop();

  const { makeRequest, isLoading } = useRequestDownload();

  const currentCustomer = useSelector(
    (state) => state.contentTable.currentCustomer
  );

  const downloadXlsx = async () => {
    const request = {
      customerId: currentCustomer.customerId,
      orderNumber: currentOrderData.orderNumber,
    };

    const requestDownload = await makeRequest(
      request,
      "orderDeliveryExport",
      "xlsx"
    );

    await downloadFileUsingType(requestDownload);
  };

  if (!currentOrderData) return <Navigate to="/orders" replace={true} />;

  const deliveries = currentOrderData?.deliveries[0];

  return (
    <Box sx={{ paddingX: { xs: 1, xl: 4 }, paddingBottom: 4 }}>
      <Box sx={{ paddingY: 2 }}>
        <BackButton>{t("order.details")}</BackButton>
      </Box>

      <Grid container spacing={1}>
        <Grid item xs={12} xl={9}>
          <Paper sx={{ padding: 4 }}>
            <Grid container>
              <Grid item xs={isDesktop ? 9 : 12}>
                <Grid container spacing={2}>
                  <FullScreenGridItem>
                    <OrderCardHeading>
                      {t("order.orderDetails.orderNumber")}
                    </OrderCardHeading>
                    <OrderCardText>
                      {currentOrderData.orderNumber}
                    </OrderCardText>
                  </FullScreenGridItem>
                  <FullScreenGridItem>
                    <OrderCardHeading>
                      {t("order.orderDetails.statusLabel")}
                    </OrderCardHeading>
                    <Box sx={iconStyles}>
                      <StatusIcon small />
                      <OrderCardText>
                        {convertOrderStatusToText(currentOrderData.orderStatus)}
                      </OrderCardText>
                    </Box>
                  </FullScreenGridItem>
                  <FullScreenGridItem>
                    <OrderCardHeading>
                      {t("order.orderDetails.placedDate")}
                    </OrderCardHeading>
                    <OrderCardText>
                      <FormatDate
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        timeStart={currentOrderData.placedDate}
                      />
                    </OrderCardText>
                    <OrderCardText>
                      <FormatDate
                        hour="2-digit"
                        minute="2-digit"
                        timeStart={currentOrderData.placedDate}
                      />
                    </OrderCardText>
                  </FullScreenGridItem>
                  <FullScreenGridItem>
                    <OrderCardHeading>
                      {t("order.orderDetails.requestedDate")}
                    </OrderCardHeading>
                    <OrderCardText>
                      <FormatDate
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        timeStart={currentOrderData.shippingDate}
                      />
                    </OrderCardText>
                    <OrderCardText>
                      <FormatDate
                        hour="2-digit"
                        minute="2-digit"
                        timeStart={
                          currentOrderData.schedules[0]
                            .scheduleEarliestStartDate
                        }
                        timeEnd={
                          currentOrderData.schedules[0].scheduleStartDate
                        }
                      />
                    </OrderCardText>
                  </FullScreenGridItem>
                  <FullScreenGridItem>
                    <OrderCardHeading>
                      {t("order.orderDetails.customerReference")}
                    </OrderCardHeading>
                    <OrderCardText>
                      {currentOrderData.customerReference}
                    </OrderCardText>
                  </FullScreenGridItem>
                  <FullScreenGridItem>
                    <OrderCardHeading>
                      {t("order.orderDetails.contractNumber")}
                    </OrderCardHeading>
                    <OrderCardText>
                      {currentOrderData.contractNumber}
                    </OrderCardText>
                  </FullScreenGridItem>
                  <FullScreenGridItem>
                    <OrderCardHeading>
                      {t("order.orderDetails.shippingTypeLabel")}
                    </OrderCardHeading>
                    <OrderCardText>
                      {convertDeliveryTypeToText(currentOrderData.shippingType)}
                    </OrderCardText>
                  </FullScreenGridItem>
                  <FullScreenGridItem>
                    <OrderCardHeading>
                      {t("order.orderDetails.orderPlacerName")}
                    </OrderCardHeading>
                    <OrderCardText>
                      {currentOrderData.orderPlacerName}
                    </OrderCardText>
                  </FullScreenGridItem>
                  {deliveries && (
                    <>
                      <FullScreenGridItem>
                        <OrderCardHeading>
                          {t("order.orderDetails.vehicleType")}
                        </OrderCardHeading>
                        <OrderCardText>
                          {deliveries.modeOfTransport}
                        </OrderCardText>
                      </FullScreenGridItem>
                      <FullScreenGridItem>
                        <OrderCardHeading>
                          {t("order.orderDetails.vehicleLicencePlate")}
                        </OrderCardHeading>
                        <OrderCardText>
                          {deliveries.truckLicensePlate}
                        </OrderCardText>
                      </FullScreenGridItem>
                    </>
                  )}
                  <FullScreenGridItem>
                    <OrderCardHeading>
                      {t("order.orderDetails.materialAndVolume")}
                    </OrderCardHeading>
                    <OrderCardText>
                      {`${currentOrderData.lineItems[0].quantity} ${currentOrderData.lineItems[0].quantityUom} - ${currentOrderData.lineItems[0].materialDescription}`}
                    </OrderCardText>
                  </FullScreenGridItem>
                  <FullScreenGridItem>
                    <OrderCardHeading>
                      {t("order.orderDetails.plantName")}
                    </OrderCardHeading>
                    <OrderCardText>
                      {currentOrderData.orgUnit.name}
                    </OrderCardText>
                    <OrderCardText>
                      {`${currentOrderData.orgUnit.street}, ${currentOrderData.orgUnit.city}, ${currentOrderData.orgUnit.postalCode}`}
                    </OrderCardText>
                  </FullScreenGridItem>
                  <FullScreenGridItem xs={12}>
                    <OrderCardHeading>
                      {t("order.orderDetails.destination")}
                    </OrderCardHeading>
                    <OrderCardText>
                      {currentOrderData.shippingAddress.siteName}
                    </OrderCardText>
                    <OrderCardText>
                      {`${currentOrderData.shippingAddress.street}, ${currentOrderData.shippingAddress.city}, ${currentOrderData.shippingAddress.postalCode}`}
                    </OrderCardText>
                  </FullScreenGridItem>
                </Grid>
              </Grid>
              {isDesktop && (
                <>
                  <Divider orientation="vertical" flexItem />
                  <Grid item xs={3}></Grid>
                </>
              )}
            </Grid>
            {deliveries && (
              <FullScreenDeliveriesContainer deliveries={deliveries} />
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} xl={3}>
          <Paper sx={{ padding: 4 }}>
            <StyledExportButton onClick={downloadXlsx} disabled={isLoading} />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FullscreenOrder;
