import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { Button, ButtonBase, Divider, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import FormatDate from "Components/UI/FormatDate/FormatDate";
import {
  convertDeliveryTypeToText,
  convertOrderStatusToText,
  localizeTime,
} from "helpers/helpers";
import { useDetailsCard } from "hooks/useDetailsCard";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import StatusIcon from "../UI/StatusIcon/StatusIcon";
import {
  OrderCardHeading,
  OrderCardSub,
  OrderCardText,
} from "../UI/Typography/OrderCard";

const OrderDetails = ({ currentOrderData }) => {
  const { t } = useTranslation();

  const shippingDate = `${localizeTime(
    currentOrderData.schedules[0].scheduleEarliestStartDate
  )} - ${localizeTime(currentOrderData.schedules[0].scheduleStartDate)}`;

  const navigate = useNavigate();
  const navigateToFullscreen = () =>
    navigate(`/order/${currentOrderData.orderNumber}`, {
      state: {
        ...currentOrderData,
        shippingTime: shippingDate,
      },
    });

  const { closeDetailsCard, setOrderId } = useDetailsCard();
  const handleCloseDetailsCard = () => {
    closeDetailsCard();
    setOrderId(undefined);
  };

  const deliveries = currentOrderData?.deliveries[0];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <OrderCardText>{t("order.orderDetails.summary")}</OrderCardText>
        <Box>
          <ButtonBase>
            <OpenInFullIcon
              onClick={navigateToFullscreen}
              sx={{
                padding: "12px",
              }}
            />
          </ButtonBase>
          <ButtonBase onClick={handleCloseDetailsCard}>
            <CloseIcon
              sx={{
                padding: "12px",
              }}
            />
          </ButtonBase>
        </Box>
      </Box>
      <Divider sx={{ marginY: 1 }} />
      <Grid container spacing={2} sx={{ alignItems: "center" }}>
        <Grid item xs={6} sx={{ display: "flex" }}>
          <StatusIcon status={currentOrderData.orderStatus} />
          <OrderCardText sx={{ marginLeft: 1 }}>
            {convertOrderStatusToText(currentOrderData.orderStatus)}
          </OrderCardText>
        </Grid>
        <Grid item xs={6}>
          <OrderCardHeading>
            {t("order.orderDetails.shippingTypeLabel")}
          </OrderCardHeading>
          <OrderCardText>
            {convertDeliveryTypeToText(currentOrderData.shippingType)}
          </OrderCardText>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "left" }}>
          <OrderCardHeading>
            {t("order.orderDetails.requestedDate")}
          </OrderCardHeading>
          <Box>
            <OrderCardSub>
              <FormatDate
                day="2-digit"
                month="2-digit"
                year="numeric"
                timeStart={currentOrderData.shippingDate}
              />
            </OrderCardSub>
            <OrderCardSub>
              <FormatDate
                hour="2-digit"
                minute="2-digit"
                timeStart={
                  currentOrderData.schedules[0].scheduleEarliestStartDate
                }
                timeEnd={currentOrderData.schedules[0].scheduleStartDate}
              />
            </OrderCardSub>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "left" }}>
          <OrderCardHeading>
            {t("order.orderDetails.placedDate")}
          </OrderCardHeading>
          <Box>
            <OrderCardSub>
              <FormatDate
                day="2-digit"
                month="2-digit"
                year="numeric"
                timeStart={currentOrderData.placedDate}
              />
            </OrderCardSub>
            <OrderCardSub>
              <FormatDate
                hour="2-digit"
                minute="2-digit"
                timeStart={currentOrderData.placedDate}
              />
            </OrderCardSub>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "left" }}>
          <OrderCardHeading>
            {t("order.orderDetails.destination")}
          </OrderCardHeading>
          <Box>
            <OrderCardText>
              {currentOrderData.shippingAddress.siteName}
            </OrderCardText>
            <OrderCardSub>
              {`${currentOrderData.shippingAddress.street}`}
            </OrderCardSub>
            <OrderCardSub>
              {`${currentOrderData.shippingAddress.city}, ${currentOrderData.shippingAddress.postalCode}`}
            </OrderCardSub>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <OrderCardHeading variant="subtitle1">
            {t("order.orderDetails.productCode")}
          </OrderCardHeading>
          <OrderCardText>
            {currentOrderData.lineItems[0].materialDescription}
          </OrderCardText>
        </Grid>
        <Grid item xs={6}>
          <OrderCardHeading variant="subtitle1">
            {t("order.orderDetails.orderedQty")}
          </OrderCardHeading>
          <OrderCardText>
            {currentOrderData.lineItems[0].quantity}
            {t("capacityTons")}
          </OrderCardText>
        </Grid>
        <Grid item xs={6}>
          <OrderCardHeading variant="subtitle1">
            {t("order.orderDetails.plantName")}
          </OrderCardHeading>
          <OrderCardText>{currentOrderData.orgUnit.name || "-"}</OrderCardText>
        </Grid>
      </Grid>
      <Divider sx={{ marginY: 2 }} />
      {deliveries && (
        <Box>
          <Button
            variant="outlined"
            fullWidth
            startIcon={<DownloadIcon />}
            sx={{ textTransform: "none", fontWeight: 700, marginBottom: 2 }}
          >
            {t("order.downloadAllDeliveryNotes")}
          </Button>
          <Button
            onClick={navigateToFullscreen}
            variant="contained"
            fullWidth
            sx={{ textTransform: "none", fontWeight: 700 }}
          >
            {t("order.viewAllDeliveries", {
              totalDeliveries: currentOrderData.deliveries.length,
            })}
          </Button>
        </Box>
      )}
    </>
  );
};

export default OrderDetails;
