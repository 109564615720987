import { FormControl, FormHelperText, Input } from "@mui/material";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

const InputHelperCount = ({
  label = "default data",
  id = "unknown id",
  value = "",
}) => {
  const [helperValue, setHelperValue] = useState("");
  const handleHelperValueChange = (e) => {
    setHelperValue(e.target.value);
  };

  const helperMaxLength = 35;

  const { setValue } = useFormContext();

  useEffect(() => {
    setValue(`haulerInfo.${id}`, helperValue);
  }, [setValue, helperValue, id]);

  useEffect(() => {
    setHelperValue(value);
  }, [value]);

  return (
    <FormControl
      fullWidth
      sx={{
        marginBottom: 2,
        backgroundColor: "#F7F7F7",
        border: "1px solid #E7E7E7",
        borderRadius: "4px",
        transition: "none",
        paddingX: 2,
        paddingY: 1,
        boxSizing: "border-box",
        "&:hover": {
          background: "#E7E7E7",
        },
      }}
    >
      <Input
        value={helperValue}
        onChange={handleHelperValueChange}
        disableUnderline
        placeholder={label}
        inputProps={{
          maxLength: helperMaxLength,
        }}
      />
      <FormHelperText
        sx={{ margin: 0 }}
      >{`${helperValue.length}/${helperMaxLength}`}</FormHelperText>
    </FormControl>
  );
};

export default InputHelperCount;
