import { Box, Paper, Toolbar } from "@mui/material";
import LegalPageContent from "Components/LegalPage/LegalPageContent";
import BackButton from "Components/UI/BackButton/BackButton";

const LegalPage = ({ page = "terms" }) => {
  return (
    <>
      <Toolbar>
        <BackButton>Back</BackButton>
      </Toolbar>
      <Box sx={{ background: "white", height: "100%" }}>
        <Paper sx={{ maxWidth: "1200px", margin: "0 auto", padding: "30px" }}>
          <LegalPageContent page={page} />
        </Paper>
      </Box>
    </>
  );
};

export default LegalPage;
