import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { Box, IconButton, TextField } from "@mui/material";
import { useGetTableData } from "hooks/useGetTableData";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import NumberFilterSelect from "./NumberFilterSelect";

const NumberFilter = () => {
  const { refetch, query, isInvoices, findOne } = useGetTableData();

  const { t } = useTranslation();
  const labelinvoiceNo = "invoice.lookup.labelinvoiceNo";
  const labelorderNo = "order.lookup.labelorderNo";
  const labelText = isInvoices ? labelinvoiceNo : labelorderNo;

  const [label, setLabel] = useState(labelText);
  const handleLabelChange = (label) => setLabel(label);

  const [Number, setNumber] = useState("");
  const handleInputChange = (e) => setNumber(e.target.value);

  const handleFilterByNumber = () => {
    if (Number === "") return;

    findOne({
      customerId: query.customerId,
      [`${label === labelinvoiceNo ? "invoiceNumber" : "orderNumber"}`]: Number,
    });
  };

  const clearNumberFilter = () => {
    setNumber("");
    refetch();
  };

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        backgroundColor: theme.palette.inputBackgroundColor.main,
        border: `1px solid ${theme.palette.inputBorderColor.main}`,
        borderRadius: "4px",
        "&:hover": {
          background: "#e7e7e7",
        },
      })}
    >
      <TextField
        label={t(label)}
        value={Number}
        onChange={handleInputChange}
        variant="filled"
        onKeyDown={(e) => e.key === "Enter" && handleFilterByNumber()}
        sx={{
          width: "100%",
          "& .MuiInputBase-root": {
            background: "none",
            border: "none",
          },
        }}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: (
            <IconButton onClick={handleFilterByNumber}>
              <SearchIcon />
            </IconButton>
          ),
          sx: {
            background: "none",
            borderRadius: 0,
            "&:hover": {
              background: "none",
            },
          },
        }}
      />
      {isInvoices && (
        <NumberFilterSelect handleLabelChange={handleLabelChange} />
      )}
      <Box
        sx={(theme) => ({
          display: "flex",
          borderLeft: `1px solid ${theme.palette.inputBorderColor.main}`,
        })}
      >
        <IconButton
          onClick={clearNumberFilter}
          sx={{
            "&:hover": {
              background: "none",
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default NumberFilter;
