import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  ButtonBase,
  FormControl,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Popover from "@mui/material/Popover";
import { useActions } from "hooks/useActions";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const MaterialDropdown = ({ propOptions, selection }) => {
  const { t } = useTranslation();

  const currentOption = useSelector((state) => state.deliveryOI.currentOption);
  const isCutOffTime = useSelector((state) => state.deliveryOI.isCutOffTime);
  const { setDeliveryDates, setCurrentMaterial } = useActions();

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
    setPopoverWidth(e.currentTarget.offsetWidth);
  };

  const handleClose = () => setAnchorEl(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverWidth, setPopoverWidth] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [materialInput, setMaterialInput] = useState(propOptions[0]);

  const popoverContentProps = {
    popoverWidth,
    propOptions,
    handleClose,
    setMaterialInput,
  };

  useEffect(() => {
    setMaterialInput(selection);
  }, [selection]);

  const { setValue } = useFormContext();

  useEffect(() => {
    const materialSites = currentOption.options.filter((option) =>
      option.materials.some(
        (material) =>
          material.materialEnteredNumber === materialInput.materialEnteredNumber
      )
    );

    const materialDates = materialSites
      .map((site) =>
        isCutOffTime
          ? site.businessDays.daysAfterCutOffTime
          : site.businessDays.daysBeforeCutOffTime
      )
      .flat();

    setDeliveryDates(materialDates);
    setCurrentMaterial(materialInput);

    setValue("materials", materialInput);
  }, [setValue, materialInput]);

  return (
    <Grid item>
      <ButtonBase
        onClick={handleClick}
        sx={{
          display: "block",
          width: "100%",
        }}
      >
        <Paper
          square={false}
          elevation={0}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: (theme) => theme.palette.inputBackgroundColor.main,
            border: (theme) =>
              `1px solid ${theme.palette.inputBorderColor.main}`,
            padding: "5px",
            paddingBottom: "8px",
            paddingLeft: "12px",
            "&:hover": {
              background: "#E7E7E7",
            },
          }}
        >
          <Box maxWidth="90%" flexGrow={1}>
            <Stack textAlign="left">
              <TextField
                value={materialInput.materialDescription}
                id="oi-delivery-form-material"
                onMouseDown={(e) => e.preventDefault()}
                label={t("orderIntake.material")}
                variant="filled"
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  input: { cursor: "pointer" },
                  ".MuiInputBase-root": {
                    cursor: "pointer",
                  },
                  "& .MuiInputBase-root": {
                    background: "none",
                    border: "none",
                  },
                }}
              />
            </Stack>
          </Box>
          <Box>
            <ArrowDropDownIcon />
          </Box>
        </Paper>
      </ButtonBase>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableScrollLock
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ marginTop: 1 }}
      >
        <PopoverContent {...popoverContentProps} />
      </Popover>
    </Grid>
  );
};

const PopoverContent = ({
  popoverWidth,
  propOptions,
  handleClose,
  setMaterialInput,
}) => {
  const { t } = useTranslation();

  const [searchInput, setSearchInput] = useState("");
  const handleSearchInput = (e) => setSearchInput(e.target.value);

  return (
    <Paper sx={{ width: popoverWidth, pl: 1, pt: 1 }}>
      <FormControl fullWidth>
        <TextField
          value={searchInput}
          onChange={handleSearchInput}
          label={t("dropdown.searchList")}
          InputLabelProps={{ shrink: true }}
          variant="standard"
          id="site-search"
          aria-describedby="search site input"
          InputProps={{
            disableUnderline: false,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            sx: {
              "&.MuiInputBase-root": {
                backgroundColor: "white",
                borderColor: "white",
                mr: 1,
              },
            },
          }}
        />
      </FormControl>
      <List
        sx={{
          paddingBottom: 0,
          maxHeight: "250px",
          overflowY: "auto",
        }}
      >
        {propOptions
          .filter((option) => {
            const inputArray = searchInput.toLowerCase().split(" ");
            const optionString = `${option.materialDescription}`.toLowerCase();
            return inputArray.every((item) => optionString.includes(item));
          })
          .map((option, index, options) => (
            <ListItem
              key={index}
              disablePadding
              divider={index < options.length - 1}
              sx={{
                alignItems: "flex-start",
              }}
            >
              <ListItemButton
                sx={{
                  alignItems: "flex-start",
                  pl: "5px",
                }}
                onClick={() => {
                  handleClose();
                  setMaterialInput(option);
                }}
              >
                <Box>
                  <Typography>{option.materialDescription}</Typography>
                  {option.caption && (
                    <Typography display="block" variant="caption">
                      {option.caption}
                    </Typography>
                  )}
                  {option.stringId && (
                    <Typography display="block" variant="caption">
                      {option.stringId}
                    </Typography>
                  )}
                </Box>
              </ListItemButton>
            </ListItem>
          ))}
      </List>
    </Paper>
  );
};

export default MaterialDropdown;
