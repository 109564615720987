import TermsModal from "Components/Terms/TermsModal";
import { Outlet } from "react-router-dom";
import AppHeader from "../AppHeader/AppHeader";

const Layout = () => {
  return (
    <TermsModal>
      <AppHeader>
        <Outlet />
      </AppHeader>
    </TermsModal>
  );
};

export default Layout;
