import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box } from "@mui/material";
import { OverviewBoxHeadingStyled } from "Components/UI/Typography/SharedTypography";
import { useTranslation } from "react-i18next";

const CreditWidgetTopText = ({ payer, payerNumber }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        columnGap: 1,
        alignItems: "center",
        marginBottom: 4,
      }}
    >
      <OverviewBoxHeadingStyled>
        {t("overview.widgetTitles.accountBalance", {
          payerName: payer,
          payerNumber,
        })}
      </OverviewBoxHeadingStyled>
      <InfoOutlinedIcon
        fontSize="small"
        sx={{
          cursor: "pointer",
          color: (theme) => theme.palette.text.secondary,
        }}
      />
    </Box>
  );
};

export default CreditWidgetTopText;
