import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
  Box,
  Button,
  ButtonBase,
  Divider,
  FormControlLabel,
  Paper,
  Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { OrderCardText } from "Components/UI/Typography/OrderCard";
import { downloadFileUsingType } from "helpers/helpers";
import useRequestDownload from "hooks/useRequestDownload";
import { useSelectedItemsCard } from "hooks/useSelectedItemsCard";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const flexBoxCentered = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const PageCardOrderExport = () => {
  const { t } = useTranslation();

  const { deliveriesToExport, closeSelectedCard } = useSelectedItemsCard();

  const handleClose = () => closeSelectedCard();

  const { makeRequest, isLoading } = useRequestDownload();

  const [checkboxValue, setCheckboxValue] = useState(true);
  const handleCheckboxChange = () => setCheckboxValue((prev) => !prev);
  const isButtonDisabled = !checkboxValue || isLoading;

  const downloadPdfs = async () => {
    const request = { deliveryIds: deliveriesToExport };
    const requestDownload = await makeRequest(
      request,
      "bulkOrderDeliveryDownload",
      "pdf"
    );

    await downloadFileUsingType(requestDownload);
  };

  const downloadZip = async () => {
    const request = { deliveryIds: deliveriesToExport };
    const requestDownload = await makeRequest(
      request,
      "bulkOrderDeliveryDownload",
      "zip"
    );

    await downloadFileUsingType(requestDownload);
  };

  return (
    <Box>
      <Box sx={flexBoxCentered}>
        <OrderCardText sx={{ textTransform: "none" }}>
          {t("order.selectedOrders")}
        </OrderCardText>
        <Box>
          <ButtonBase onClick={handleClose}>
            <CloseIcon
              sx={{
                padding: "12px",
              }}
            />
          </ButtonBase>
        </Box>
      </Box>
      <Divider sx={{ marginBottom: 1 }} />
      <Paper variant="outlined" sx={{ padding: 2 }}>
        <Typography> {t("order.bulkDownload.downloadOptions")}</Typography>
        <Divider sx={{ marginBottom: 2, marginTop: 1 }} />
        <Box sx={flexBoxCentered}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkboxValue}
                onChange={handleCheckboxChange}
              />
            }
            label={t("order.bulkDownload.tickets")}
          />
          <Typography>{deliveriesToExport.length}</Typography>
        </Box>
        <Box sx={flexBoxCentered}>
          <Typography>
            {t("order.bulkDownload.deliveriesWithoutDocuments")}
          </Typography>
          <Typography>0</Typography>
        </Box>
        <Button
          onClick={downloadPdfs}
          disabled={isButtonDisabled}
          variant="contained"
          startIcon={<DownloadIcon />}
          sx={{ marginY: 2 }}
        >
          {t("order.bulkDownload.downloadSelectedPDFs")}
        </Button>
        <Button
          onClick={downloadZip}
          disabled={isButtonDisabled}
          variant="outlined"
          startIcon={<PictureAsPdfIcon />}
          sx={{ marginY: 1 }}
        >
          {t("invoice.multiple.combineToPdfAndDownload")}
        </Button>
      </Paper>
    </Box>
  );
};

export default PageCardOrderExport;
