import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import FormatDate from "Components/UI/FormatDate/FormatDate";
import { OrderCardHeading } from "Components/UI/Typography/OrderCard";
import { downloadFileUsingType, localizeCurrency } from "helpers/helpers";
import { useTranslation } from "react-i18next";

const FullscreenInvoicedItemTable = ({
  invoicedItem,
  setIsDeliveryItemsOpen,
}) => {
  const { t } = useTranslation();
  const downloadFile = () =>
    downloadFileUsingType(
      invoicedItem.links[0].href,
      invoicedItem.links[0].media
    );

  const tableHeadCells = [
    t("invoiceDetail.lineItems.dateShipped"),
    t("invoiceDetail.lineItems.expand.materialDescription"),
    t("invoiceDetail.lineItems.expand.quantity"),
    t("invoiceDetail.lineItems.contract"),
    t("invoiceDetail.lineItems.netAmount"),
  ];

  return (
    <TableContainer sx={{ mb: 4, mt: 2 }}>
      <Table size="small" sx={{ minWidth: 600 }} aria-label="delivery table">
        <TableHead
          sx={{
            backgroundColor: "rgb(245, 246, 247)",
          }}
        >
          <TableRow>
            {tableHeadCells.map((cell, index) => (
              <TableCell key={index}>
                <OrderCardHeading sx={{ fontSize: "10px", paddingY: "8px" }}>
                  {cell}
                </OrderCardHeading>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {invoicedItem.lineItems.map((item, index) => (
            <TableRow
              key={index}
              onClick={setIsDeliveryItemsOpen}
              sx={{
                "&:hover": { background: "#ebf3fc" },
                "&>td": { paddingY: "16px" },
              }}
            >
              <TableCell>
                <FormatDate
                  day="2-digit"
                  month="2-digit"
                  year="numeric"
                  timeStart={new Date()}
                />
              </TableCell>
              <TableCell>{item.materialDescription}</TableCell>
              <TableCell>{`${item.quantity} ${item.quantityUom}`}</TableCell>
              <TableCell>{item.contractNumber}</TableCell>
              <TableCell>
                {localizeCurrency(item.unitPrice, invoicedItem.invoiceCurrency)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FullscreenInvoicedItemTable;
