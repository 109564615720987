import DateRangeIcon from "@mui/icons-material/DateRange";
import { Box, Button, Typography } from "@mui/material";
import Popover from "@mui/material/Popover";
import FormatDate from "Components/UI/FormatDate/FormatDate";
import { useGetTableData } from "hooks/useGetTableData";
import { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useTranslation } from "react-i18next";
import FilterSelectedBox from "../FilterSelectedBox/FilterSelectedBox";
import "./DateRangeFilter.css";
import QuickSelection from "./QuickSelection";

const DateRangeFilter = () => {
  const { t, i18n } = useTranslation();

  const { query, setQueryParams } = useGetTableData();
  const initialDateStart = new Date(query.shippingDate);
  const initialDateEnd = new Date(query.endDate);

  const [anchorEl, setAnchorEl] = useState(null);
  const openPopover = (e) => setAnchorEl(e.currentTarget);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "datepicker-popover" : undefined;

  const [presetPeriod, setPresetPeriod] = useState("left");
  const [chosenPeriod, setChosenPeriod] = useState([
    initialDateStart,
    initialDateEnd,
  ]);
  const [activeDate, setActiveDate] = useState(null);

  const setDate = (e) => {
    setChosenPeriod([e[0], e[1]]);
  };

  const cancelPresetDate = () => {
    setActiveDate(null);
    setPresetPeriod(null);
  };

  const sendDate = () => {
    handleClose();
    setQueryParams({
      shippingDate: new Date(chosenPeriod[0]).toISOString(),
      endDate: new Date(chosenPeriod[1]).toISOString(),
    });
  };

  return (
    <Box>
      <FilterSelectedBox
        label={"datePicker.label"}
        handleClick={openPopover}
        icon={<DateRangeIcon />}
      >
        <Typography variant="body1" noWrap>
          <FormatDate
            day="2-digit"
            month="2-digit"
            year="numeric"
            timeStart={chosenPeriod[0]}
            timeEnd={chosenPeriod[1]}
            isDateRange
          />
        </Typography>
      </FilterSelectedBox>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ backgroundColor: "#001E2A", color: "white" }}>
          <Box sx={{ display: "flex" }}>
            <Calendar
              selectRange
              onActiveStartDateChange={() => setActiveDate(null)}
              onChange={setDate}
              value={chosenPeriod}
              onClickDay={cancelPresetDate}
              activeStartDate={activeDate}
              locale={i18n.language}
            />
            <QuickSelection
              setChosenPeriod={setChosenPeriod}
              setActiveDate={setActiveDate}
              presetPeriod={presetPeriod}
              setPresetPeriod={setPresetPeriod}
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={sendDate} sx={{ margin: "16px" }}>
              {t("datePicker.apply")}
            </Button>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default DateRangeFilter;
