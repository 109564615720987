import { useIsComponentEnabled } from "hooks/useIsComponentEnabled";
import { useTranslation } from "react-i18next";
import NavBurger from "./NavBurger";
import NavLinks from "./NavLinks";

const NavMenu = () => {
  const { t } = useTranslation();

  const pages = [
    { pageName: t("overview.navigationLabel"), pageLink: "/overview" },
    { pageName: t("order.navigationLabel"), pageLink: "/orders" },
    { pageName: t("invoice.navigationLabel"), pageLink: "/invoices" },
  ];

  const { getIsComponentEnabled, isSuccess } = useIsComponentEnabled();

  const availablePages = pages.filter((page) =>
    getIsComponentEnabled(page.pageLink)
  );

  if (isSuccess)
    return (
      <>
        <NavBurger pages={availablePages} />
        <NavLinks pages={availablePages} />
      </>
    );

  return null;
};

export default NavMenu;
