import { Box, Typography } from "@mui/material";
import { useGetDeliveryOptionsQuery } from "reduxStore/api/deliveryApiSlice";

const DeliveryOICollectionAddress = () => {
  const { data: CustomerData } = useGetDeliveryOptionsQuery("collect");
  return (
    <Box>
      <Typography>{CustomerData[0]?.siteName}</Typography>
      <Typography>{CustomerData[0]?.street}</Typography>
      <Typography>{`${CustomerData[0]?.city}, ${CustomerData?.postalCode}`}</Typography>
    </Box>
  );
};

export default DeliveryOICollectionAddress;
