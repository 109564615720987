import { Divider } from "@mui/material";
import CreditWidgetInvoicePage from "Components/CreditWidget/CreditWidgetInvoicePage";
import NoDataOverlay from "Components/UI/PageComponents/PageCard/NoDataOverlay";
import { OrderCardText } from "Components/UI/Typography/OrderCard";
import useCreditWidget from "hooks/useCreditWidget";
import { useTranslation } from "react-i18next";

const PaymentSummary = () => {
  const { t } = useTranslation();
  const { creditWidgetData } = useCreditWidget();

  if (!creditWidgetData?.length) return <NoDataOverlay />;

  return (
    <>
      <OrderCardText>{t("finance.summary")}</OrderCardText>
      <Divider sx={{ marginTop: 1, marginBottom: 2 }} />
      <CreditWidgetInvoicePage />
    </>
  );
};

export default PaymentSummary;
