import { styled, ToggleButton } from "@mui/material";

const StyledCalendarToggleButton = styled((props) => (
  <ToggleButton {...props} />
))(() => ({
  color: "white",
  textTransform: "none",
  flexDirection: "column",
  alignItems: "start",
  padding: "8px 16px",
  "&:hover": {
    backgroundColor: "rgba(231, 231, 231, .1)",
  },
  "&.Mui-selected": {
    backgroundColor: "rgba(231, 231, 231, .2)",
    color: "white",
  },
  "&.Mui-selected:hover": {
    backgroundColor: "rgba(231, 231, 231, .1)",
  },
}));

export default StyledCalendarToggleButton;
