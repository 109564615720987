import DownloadIcon from "@mui/icons-material/Download";
import { Button, styled } from "@mui/material";
import { useTranslation } from "react-i18next";

const StyledExportButton = styled(({ onClick, ...rest }) => {
  const { t } = useTranslation();
  return (
    <Button
      onClick={onClick}
      variant="contained"
      fullWidth
      {...rest}
      startIcon={<DownloadIcon />}
    >
      {t("invoice.export.buttontitle")}
    </Button>
  );
})(() => ({
  background: "white",
  color: "black",
  fontWeight: 700,
  padding: "10px 16px",
}));

export default StyledExportButton;
