import DeliveryItem from "./DeliveryItem";

const InvoiceDeliveryCards = ({ isViewAll, deliveryCards, rate }) => {
  console.log(rate);

  return isViewAll ? (
    deliveryCards.map((delivery, index) => (
      <DeliveryItem
        key={index}
        delivery={delivery}
        isShown={isViewAll}
        rate={rate}
      />
    ))
  ) : (
    <DeliveryItem delivery={deliveryCards[0]} rate={rate} />
  );
};

export default InvoiceDeliveryCards;
