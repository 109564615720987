import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useActions } from "./useActions";

export const useSelectedItemsCard = (apiRef) => {
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const isSelectedOpen = useSelector(
    (state) => state.contentTable.isSelectedOpen
  );

  const { setIsSelectedOpen, setDeliveriesToExport, setIsDetailsCardOpen } =
    useActions();

  const orderId = useSelector((state) => state.contentTable.orderId);
  const deliveriesToExport = useSelector(
    (state) => state.contentTable.deliveriesToExport
  );

  const openSelectedCard = () => {
    setIsSelectedOpen(true);
  };
  const closeSelectedCard = () => {
    if (orderId === undefined) setIsDetailsCardOpen(false);
    setIsSelectedOpen(false);
    setDeliveriesToExport([]);
  };

  useEffect(() => {
    if (!isSelectedOpen) {
      rowSelectionModel.forEach((rowId) =>
        apiRef.current.selectRow(rowId, false, true)
      );
    }
  }, [rowSelectionModel, isSelectedOpen, apiRef]);

  return {
    isSelectedOpen,
    closeSelectedCard,
    openSelectedCard,
    deliveriesToExport,
    setDeliveriesToExport,
    rowSelectionModel,
    setRowSelectionModel,
  };
};
