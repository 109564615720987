import { Box, Paper } from "@mui/material";
import { HCLogo } from "assets/img/images";
import ByPassword from "Components/Login/ByPassword";
import ByQuickCode from "Components/Login/ByQuickCode";
import Footer from "Components/Login/Footer";
import { useState } from "react";

const Login = () => {
  const [loginType, setLoginType] = useState("password");

  const setCodeLogin = () => setLoginType("quickCode");
  const setPasswordLogin = () => setLoginType("password");

  const [username, setUsername] = useState("");
  const handleUsername = ({ target: { value } }) => setUsername(value);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexGrow: 1,
        flexDirection: "column",
      }}
    >
      <Paper
        component="main"
        square={false}
        sx={{
          padding: "48px",
          display: "flex",
          flexDirection: "column",
          minHeight: "500px",
          alignSelf: "center",
          width: "450px",
          margin: "auto 0",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 5,
          }}
        >
          <Box sx={{ width: "200px" }}>
            <img src={HCLogo} alt="branding logo" />
          </Box>
        </Box>
        {loginType === "password" && (
          <ByPassword
            setCodeLogin={setCodeLogin}
            username={username}
            handleUsername={handleUsername}
          />
        )}
        {loginType === "quickCode" && (
          <ByQuickCode
            setPasswordLogin={setPasswordLogin}
            username={username}
          />
        )}
      </Paper>
      <Footer />
    </Box>
  );
};

export default Login;
