import { MenuItem, Typography } from "@mui/material";
import { useCustomerQuery } from "hooks/useCustomerQuery";
import { useTranslation } from "react-i18next";

const AccountsMenu = () => {
  const { t } = useTranslation();
  const { data, isSuccess } = useCustomerQuery();

  return (
    <MenuItem
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <Typography variant="caption">{t("accountMenu.accounts")}</Typography>
      <Typography>{isSuccess && data[0]?.customerNumber}</Typography>
    </MenuItem>
  );
};

export default AccountsMenu;
