import { getUserId } from "helpers/localstoragehelper";
import { useGetUserQuery } from "reduxStore/api/userApiSlice";

const useUser = () => {
  const { data } = useGetUserQuery(getUserId());

  return data;
};

export default useUser;
