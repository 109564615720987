//general
export const copyright = `© Copyright ${new Date().getFullYear()} HeidelbergCement`;

//content table
export const rowsPerPage = [10, 50, 100];

//TImePicker OI
export const timeOptions = [
  { label24h: "00:00" },
  { label24h: "00:30" },
  { label24h: "01:00" },
  { label24h: "01:30" },
  { label24h: "02:00" },
  { label24h: "02:30" },
  { label24h: "03:00" },
  { label24h: "03:30" },
  { label24h: "04:00" },
  { label24h: "04:30" },
  { label24h: "05:00" },
  { label24h: "05:30" },
  { label24h: "06:00" },
  { label24h: "06:30" },
  { label24h: "07:00" },
  { label24h: "07:30" },
  { label24h: "08:00" },
  { label24h: "08:30" },
  { label24h: "09:00" },
  { label24h: "09:30" },
  { label24h: "10:00" },
  { label24h: "10:30" },
  { label24h: "11:00" },
  { label24h: "11:30" },
  { label24h: "12:00" },
  { label24h: "12:30" },
  { label24h: "13:00" },
  { label24h: "13:30" },
  { label24h: "14:00" },
  { label24h: "14:30" },
  { label24h: "15:00" },
  { label24h: "15:30" },
  { label24h: "16:00" },
  { label24h: "16:30" },
  { label24h: "17:00" },
  { label24h: "17:30" },
  { label24h: "18:00" },
  { label24h: "18:30" },
  { label24h: "19:00" },
  { label24h: "19:30" },
  { label24h: "20:00" },
  { label24h: "20:30" },
  { label24h: "21:00" },
  { label24h: "21:30" },
  { label24h: "22:00" },
  { label24h: "22:30" },
  { label24h: "23:00" },
  { label24h: "23:30" },
  { label24h: "23:59" },
];

// credit widget
export const unpaidColor =
  "linear-gradient(90deg, rgb(8, 117, 185) 0%, rgb(78, 185, 255) 100%)";
export const dispatchedColor =
  "linear-gradient(-90deg, rgb(26, 196, 99) 0%, rgb(4, 151, 66) 100%)";
export const notDispatchedColor =
  "linear-gradient(-90deg, rgb(133, 113, 212) 0%, rgb(109, 29, 130) 100%)";
