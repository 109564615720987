import { useTranslation } from "react-i18next";

const getDateString = (value) => {
  if (!value) {
    return undefined;
  }

  try {
    const valueAsDate = new Date(value);

    return valueAsDate.toISOString();
  } catch {
    return undefined;
  }
};

const safelyFormatValue = (formatter, value) => {
  if (!value) return "";

  try {
    const dateFromValue = new Date(value);
    return formatter.format(dateFromValue);
  } catch {
    return "";
  }
};

const FormatDate = ({
  fractionalSecondDigits,
  hour,
  minute,
  day,
  month,
  year,
  second,
  timeStart,
  timeEnd,
  timeZoneName,
  locale = "default",
  isDateRange = false,
  ...rest
}) => {
  const { i18n } = useTranslation();

  const isSameDates =
    isDateRange &&
    new Date(timeStart).toDateString() === new Date(timeEnd).toDateString();

  const dateFormatter = new Intl.DateTimeFormat(i18n?.language || locale, {
    fractionalSecondDigits,
    hour,
    minute,
    day,
    month,
    year,
    second,
    timeZoneName,
  });

  return (
    <>
      <time dateTime={getDateString(timeStart)} {...rest}>
        {safelyFormatValue(dateFormatter, timeStart)}
      </time>
      {timeEnd && !isSameDates && (
        <time dateTime={getDateString(timeEnd)} {...rest}>
          {" - "}
          {safelyFormatValue(dateFormatter, timeEnd)}
        </time>
      )}
    </>
  );
};

export default FormatDate;
