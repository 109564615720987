import { Box, Tooltip } from "@mui/material";
import * as statuses from "assets/img/statuses/statusImages";
import { useTranslation } from "react-i18next";

const StatusIcon = ({ status = "unconfirmed", small = false }) => {
  const { t } = useTranslation();

  const statusTooltips = {
    unconfirmed: t("order.orderStatusLabel.tooltip.pending"),
    created: t("order.orderStatusLabel.tooltip.pending"),
    confirmed: t("order.orderStatusLabel.tooltip.confirmed"),
    declined: t("order.orderStatusLabel.tooltip.declined"),
    canceled: t("order.orderStatusLabel.tooltip.cancelled"),
    active: t("order.orderStatusLabel.tooltip.active"),
    completed: t("order.orderStatusLabel.tooltip.completed"),
  };

  const tooltipText = statusTooltips[status] || status;

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Tooltip title={tooltipText}>
        <img
          src={statuses[status]}
          alt={status}
          width={small ? "16px" : "24px"}
        />
      </Tooltip>
    </Box>
  );
};

export default StatusIcon;
