import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import StatusIcon from "../UI/StatusIcon/StatusIcon";

const FullScreenDeliveriesStatus = ({ status }) => {
  const { t } = useTranslation();

  switch (status) {
    case "invoiced":
      return <Button>{t("order.expand.label.invoiced")}</Button>;
    case "intransit":
      return (
        <Box
          sx={{
            paddingTop: "2px",
            display: "flex",
            columnGap: "5px",
            textDecoration: "underline",
          }}
        >
          <StatusIcon small status="intransit" />
          {t("order.expand.label.intransit")}
        </Box>
      );
    default:
      return null;
  }
};

export default FullScreenDeliveriesStatus;
