import { Modal, Paper, useMediaQuery } from "@mui/material";
import InvoiceCostSummary from "Components/Invoices/InvoiceCostSummary";
import { ease, slideCard } from "Theme";
import FSGroupedDelivery from "./FSGroupedDelivery";

const FSInvoiceSide = ({
  isDeliveryItemsOpen,
  setIsDeliveryItemsOpen,
  currentInvoiceData,
}) => {
  const isBelowLgr = useMediaQuery((theme) => theme.breakpoints.down("lgr"));
  const isBelowMd = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const commonStyles = {
    padding: 4,
    zIndex: 1,
    overflowY: "auto",
    scrollbarGutter: "stable",
    height: "100%",
    right: "0",
  };

  const renderContentSwitch = () => {
    switch (true) {
      case isBelowLgr && isBelowMd:
        return (
          <>
            <Modal
              open={Boolean(isDeliveryItemsOpen)}
              hideBackdrop
              sx={{
                top: "63px",
                zIndex: 3,
                animation: `${ease} .3s ease`,
              }}
            >
              <Paper
                sx={(theme) => ({
                  [theme.breakpoints.down("md")]: {
                    display: !isDeliveryItemsOpen && "none",
                    position: "fixed",
                    top: "63px",
                    left: "0",
                    bottom: "0",
                    ...commonStyles,
                  },
                })}
              >
                <FSGroupedDelivery
                  setIsDeliveryItemsOpen={setIsDeliveryItemsOpen}
                />
              </Paper>
            </Modal>
            <InvoiceCostSummary currentInvoiceData={currentInvoiceData} />
          </>
        );
      case isBelowLgr:
        return (
          <>
            <Paper
              sx={(theme) => ({
                [theme.breakpoints.down("lgr")]: {
                  display: !isDeliveryItemsOpen && "none",
                  position: "absolute",
                  top: "0",
                  width: "40%",
                  maxHeight: "calc(-180px + 100vh)",
                  animation: `${slideCard} .2s`,
                  ...commonStyles,
                },
              })}
            >
              <FSGroupedDelivery
                setIsDeliveryItemsOpen={setIsDeliveryItemsOpen}
              />
            </Paper>
            <InvoiceCostSummary currentInvoiceData={currentInvoiceData} />
          </>
        );
      default:
        return isDeliveryItemsOpen ? (
          <FSGroupedDelivery setIsDeliveryItemsOpen={setIsDeliveryItemsOpen} />
        ) : (
          <InvoiceCostSummary currentInvoiceData={currentInvoiceData} />
        );
    }
  };

  return <Paper sx={commonStyles}>{renderContentSwitch()}</Paper>;
};

export default FSInvoiceSide;
