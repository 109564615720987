import { Divider, Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import FSExportButton from "Components/FullscreenInvoice/FSExportButton";
import FSInvoiceSide from "Components/FullscreenInvoice/FSInvoiceSide";
import FSMobileGroupedDelivery from "Components/FullscreenInvoice/FSMobileGroupedDelivery";
import FullscreenInvoicedItemTable from "Components/FullscreenInvoice/FullscreenInvoicedItemTable";
import FullScreenGridItem from "Components/FullscreenOrder/FullScreenGridItem";
import BackButton from "Components/UI/BackButton/BackButton";
import FormatDate from "Components/UI/FormatDate/FormatDate";
import {
  OrderCardHeading,
  OrderCardSub,
  OrderCardText,
} from "Components/UI/Typography/OrderCard";
import { formatInvoiceStatusText } from "helpers/helpers";
import { useIsDesktop } from "hooks/useIsDesktop";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation } from "react-router-dom";

const iconStyles = { display: "flex", columnGap: "5px" };

const FullscreenInvoice = () => {
  const { t } = useTranslation();
  const { state: currentInvoiceData } = useLocation();
  const isDesktop = useIsDesktop();

  const [isDeliveryItemsOpen, setIsDeliveryItemsOpen] = useState(false);
  if (!currentInvoiceData) return <Navigate to="/invoices" replace={true} />;

  return (
    <Box
      sx={{
        paddingX: { xs: 1, xl: 4 },
        paddingBottom: 4,
        overflowY: "auto",
        maxHeight: "calc(100vh - 96px)",
      }}
    >
      <Box
        sx={{
          paddingY: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <BackButton>{t("invoiceDetail.invoiceDetails")}</BackButton>
        <Box
          sx={{
            textAlign: "right",
            flexGrow: 1,
          }}
        >
          <FSExportButton />
        </Box>
      </Box>

      <Grid
        container
        spacing={1}
        sx={{
          minHeight: "calc(-180px + 100vh)",
          position: "relative",
          overflowY: "auto",
        }}
      >
        <Grid
          item
          xs={12}
          lgr={8}
          sx={{
            overflowY: "auto",
            xs: { maxHeight: "initial" },
            lgr: { maxHeight: "calc(-180px + 100vh)" },
          }}
        >
          <Paper sx={{ padding: 4, height: "100%" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Grid container spacing={2}>
                  <FullScreenGridItem md={12}>
                    <OrderCardHeading>
                      {t("invoiceDetail.invoiceStatus")}
                    </OrderCardHeading>
                    <Box sx={iconStyles}>
                      <OrderCardText sx={{ fontWeight: 700, color: "#29AAFF" }}>
                        {formatInvoiceStatusText(currentInvoiceData.status)}
                      </OrderCardText>
                    </Box>
                  </FullScreenGridItem>
                  <FullScreenGridItem md={12}>
                    <OrderCardHeading>
                      {t("invoiceDetail.invoiceNumber.invoice")}
                    </OrderCardHeading>
                    <OrderCardText>
                      {currentInvoiceData.customerInvoiceNumber}
                    </OrderCardText>
                  </FullScreenGridItem>
                  <FullScreenGridItem xs={6} md={12}>
                    <OrderCardHeading>
                      {t("invoiceDetail.invoiceDate.invoice")}
                    </OrderCardHeading>
                    <OrderCardText>
                      <FormatDate
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        timeStart={currentInvoiceData.invoiceDate}
                      />
                    </OrderCardText>
                  </FullScreenGridItem>
                  <FullScreenGridItem xs={6} md={12}>
                    <OrderCardHeading>
                      {t("invoiceDetail.creationDate")}
                    </OrderCardHeading>
                    <OrderCardText>
                      <FormatDate
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        timeStart={currentInvoiceData.creationDate}
                      />
                    </OrderCardText>
                  </FullScreenGridItem>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <FullScreenGridItem>
                  <OrderCardHeading>
                    {t("invoiceDetail.soldTo")}
                  </OrderCardHeading>
                  <Box>
                    <OrderCardText>
                      {currentInvoiceData.payer.customerName}
                    </OrderCardText>
                    <OrderCardSub>{`${currentInvoiceData.payer.street}`}</OrderCardSub>
                    <OrderCardSub>
                      {`${currentInvoiceData.payer.city}, ${currentInvoiceData.payer.postalCode}`}
                    </OrderCardSub>
                  </Box>
                </FullScreenGridItem>
              </Grid>
              <Grid item xs={12} md={4}>
                <FullScreenGridItem>
                  <OrderCardHeading>
                    {t("invoiceDetail.billTo")}
                  </OrderCardHeading>
                  <Box>
                    <OrderCardText>
                      {currentInvoiceData.billTo.customerName}
                    </OrderCardText>
                    <OrderCardSub>{`${currentInvoiceData.billTo.street}`}</OrderCardSub>
                    <OrderCardSub>
                      {`${currentInvoiceData.billTo.city}, ${currentInvoiceData.billTo.postalCode}`}
                    </OrderCardSub>
                  </Box>
                </FullScreenGridItem>
              </Grid>
            </Grid>
            <Divider sx={{ marginTop: 1 }} />
            <Typography variant="caption">
              {t("invoiceDetail.taxRegulationCompliant")}
            </Typography>
            {currentInvoiceData.lineItems.length > 0 && (
              <>
                <OrderCardText sx={{ marginTop: 6 }}>
                  {t("invoiceDetail.itemsGroupedByMaterial")}
                </OrderCardText>
                <Divider sx={{ marginY: 1 }} />
                {isDesktop ? (
                  <FullscreenInvoicedItemTable
                    invoicedItem={currentInvoiceData}
                    setIsDeliveryItemsOpen={setIsDeliveryItemsOpen}
                  />
                ) : (
                  <FSMobileGroupedDelivery
                    setIsDeliveryItemsOpen={setIsDeliveryItemsOpen}
                    invoicedItem={currentInvoiceData}
                  />
                )}
              </>
            )}
          </Paper>
        </Grid>
        <Grid
          item
          xs={12}
          lgr={4}
          sx={{
            maxHeight: "calc(-180px + 100vh)",
          }}
        >
          <FSInvoiceSide
            setIsDeliveryItemsOpen={setIsDeliveryItemsOpen}
            isDeliveryItemsOpen={isDeliveryItemsOpen}
            currentInvoiceData={currentInvoiceData}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FullscreenInvoice;
