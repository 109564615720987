import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import FormatCurrency from "Components/UI/FormatCurrency/FormatCurrency";
import FormatDate from "Components/UI/FormatDate/FormatDate";
import { OrderCardHeading } from "Components/UI/Typography/OrderCard";
import { useActions } from "hooks/useActions";
import { useTranslation } from "react-i18next";

const FSInvoicedItemTable = ({
  groupedTableRows,
  groupedDeliveriesValues,
  setIsDeliveryItemsOpen,
}) => {
  const { t } = useTranslation();
  const { setInvoiceDeliveryDetails } = useActions();

  const tableHeadCells = [
    t("invoiceDetail.lineItems.dateShipped"),
    t("invoiceDetail.lineItems.expand.materialDescription"),
    t("invoiceDetail.lineItems.expand.quantity"),
    t("invoiceDetail.lineItems.contract"),
    t("invoiceDetail.lineItems.netAmount"),
  ];

  const handleRowClick = (deliveryGroup) => {
    setIsDeliveryItemsOpen(true);
    setInvoiceDeliveryDetails(deliveryGroup);
  };

  return (
    <TableContainer sx={{ mb: 4, mt: 2 }}>
      <Table size="small" sx={{ minWidth: 600 }} aria-label="delivery table">
        <TableHead
          sx={{
            backgroundColor: "rgb(245, 246, 247)",
          }}
        >
          <TableRow>
            {tableHeadCells.map((cell, index) => (
              <TableCell key={index}>
                <OrderCardHeading sx={{ fontSize: "10px", paddingY: "8px" }}>
                  {cell}
                </OrderCardHeading>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {groupedTableRows.map((row, index) => (
            <TableRow
              hover
              key={index}
              onClick={() =>
                handleRowClick({
                  summedData: groupedTableRows[index],
                  deliveries: groupedDeliveriesValues[index],
                })
              }
              sx={{ "&>td": { py: 2 } }}
            >
              <TableCell>
                <FormatDate
                  day="2-digit"
                  month="2-digit"
                  year="numeric"
                  timeStart={row.shippingDate}
                />
              </TableCell>
              <TableCell>{row.materialDescription}</TableCell>
              <TableCell>{`${row.quantity} ${row.quantityUom}`}</TableCell>
              <TableCell>{row.contractNumber}</TableCell>
              <TableCell>
                <FormatCurrency value={row.unitPrice} currency={row.currency} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FSInvoicedItemTable;
