import { styled } from "@mui/material";
import Button from "@mui/material/Button";

const OrderListOIButton = styled((props) => (
  <Button size="medium" fullWidth {...props} />
))(() => ({
  margin: "5px",
  color: "white",
  borderColor: "#99afb7",
  ":hover": {
    borderColor: "white",
  },
}));

export default OrderListOIButton;
