import { useContext } from "react";
import PageTypeContext from "../context/PageTypeProvider";

const usePageType = () => {
  const PageType = useContext(PageTypeContext);
  const isInvoices = PageType === "Invoices";

  return { PageType, isInvoices };
};

export default usePageType;
