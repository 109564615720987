import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const QRCodeCaption = () => {
  const { t } = useTranslation();

  return (
    <Typography
      sx={{
        fontSize: "12px",
        color: "#99afb7",
      }}
    >
      {t("orderIntake.collect.generateQrCodeCaption")}
    </Typography>
  );
};

export default QRCodeCaption;
