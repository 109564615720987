import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import OrderListOIButton from "../UI/OrderListOIButton/OrderListOIButton";

const OIButtons = ({ variant = "outlined", sx = null }) => {
  const { t } = useTranslation();

  return (
    <>
      <Link to="/bulkCementDelivery">
        <OrderListOIButton variant={variant} sx={sx}>
          {t("overview.scheduleNewDelivery")}
        </OrderListOIButton>
      </Link>
      <Link to="/bulkCementCollection">
        <OrderListOIButton variant={variant} sx={sx}>
          {t("overview.orderIntakeCollectWidgetTitle")}
        </OrderListOIButton>
      </Link>
    </>
  );
};

export default OIButtons;
