import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Grid, Typography } from "@mui/material";
import Popover from "@mui/material/Popover";
import { useGetTableData } from "hooks/useGetTableData";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetSitesQuery } from "reduxStore/api/orderListApiSlice";
import FilterSelectedBox from "../FilterSelectedBox/FilterSelectedBox";
import SitesPopover from "./SitesPopover";

const SitesFilter = () => {
  const { t } = useTranslation();

  const initialOptionState = [
    {
      siteName: "projectsSitesDropdown.noSelectionLabel",
    },
  ];

  const { query } = useGetTableData();

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverWidth, setPopoverWidth] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "sites-dropdown" : undefined;
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
    setPopoverWidth(e.currentTarget.offsetWidth);
  };
  const handleClose = () => setAnchorEl(null);

  const { data, isFetching } = useGetSitesQuery(query.customerId, {
    skip: !query.customerId,
  });

  const [options, setOptions] = useState(initialOptionState);
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const popoverContentProps = {
    popoverWidth,
    setSelectedOption,
    options,
    handleClose,
  };

  useEffect(() => {
    data && setOptions(data);
  }, [data]);

  return (
    <Grid item>
      <FilterSelectedBox
        label={"projectsSitesDropdown.label"}
        icon={<ArrowDropDownIcon />}
        handleClick={handleClick}
      >
        <Typography variant="body1" noWrap>
          {isFetching ? t("loadingDots") : t(`${selectedOption.siteName}`)}
        </Typography>
      </FilterSelectedBox>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableScrollLock
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ marginTop: 1 }}
      >
        <SitesPopover {...popoverContentProps} />
      </Popover>
    </Grid>
  );
};

export default SitesFilter;
