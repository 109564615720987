import { Box } from "@mui/material";
import NavLinkStyled from "../../UI/NavLinkStyled/NavLinkStyled";

const NavLinks = ({ pages }) => {
  return (
    <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
      {pages.map((page) => {
        return (
          <NavLinkStyled key={page.pageLink} to={page.pageLink}>
            {page.pageName}
          </NavLinkStyled>
        );
      })}
    </Box>
  );
};

export default NavLinks;
