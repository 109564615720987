import CloseIcon from "@mui/icons-material/Close";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import { ButtonBase, Divider, Modal, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { OrderCardText } from "Components/UI/Typography/OrderCard";
import { useGetTableData } from "hooks/useGetTableData";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ease } from "Theme";
import SkeletonCard from "../../SkeletonCard/SkeletonCard";
import PageFilters from "../PageFilters";
import PageCardInvoice from "./PageCardInvoice";
import PageCardOrder from "./PageCardOrder";

const PageCard = () => {
  const { t } = useTranslation();
  const { isLoading, data, isInvoices } = useGetTableData();
  const [isFilterVisible, setFilterVisible] = useState(false);
  const commonStyles = {
    padding: 4,
    zIndex: 1,
    overflowY: "auto",
    scrollbarGutter: "stable",
    height: "100%",
    right: "0",
  };

  const handleFilterClick = () => {
    setFilterVisible((prev) => !prev);
  };

  return (
    <>
      <Box sx={{ minWidth: 275, maxWidth: 900, backgroundColor: "white" }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            padding: "8px 16px",
          }}
        >
          <Box>
            <Typography variant="h6">{`${data?.results?.length} item(s) listed`}</Typography>
          </Box>
          <Box>
            <ButtonBase>
              <SearchIcon sx={{ width: "35px", height: "35px" }} />
            </ButtonBase>
            <ButtonBase onClick={handleFilterClick}>
              <FilterListIcon sx={{ width: "35px", height: "35px" }} />
            </ButtonBase>
          </Box>
        </Box>
        <Divider
          sx={{
            margin: "0 16px",
          }}
        />
        {isLoading && <SkeletonCard />}
        {data &&
          data.results.map((item, index) =>
            isInvoices ? (
              <PageCardInvoice item={item} key={index} />
            ) : (
              <PageCardOrder item={item} key={index} />
            )
          )}
      </Box>
      <Modal
        open={isFilterVisible}
        keepMounted
        hideBackdrop
        sx={{
          top: "63px",
          zIndex: 3,
          animation: `${ease} .3s ease`,
        }}
      >
        <Paper
          sx={{
            // display: !isDeliveryItemsOpen && "none",
            position: "fixed",
            top: "63px",
            left: "0",
            bottom: "0",
            ...commonStyles,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <OrderCardText>{t("filterMenu.filter")}</OrderCardText>
            <Box>
              <ButtonBase onClick={handleFilterClick}>
                <CloseIcon
                  sx={{
                    padding: "12px",
                  }}
                />
              </ButtonBase>
            </Box>
          </Box>
          <Divider sx={{ marginY: 1 }} />
          <PageFilters />
        </Paper>
      </Modal>
    </>
  );
};

export default PageCard;
