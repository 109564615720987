import { OrderCardHeading } from "Components/UI/Typography/OrderCard";
import { OverviewBoxHeadingStyled } from "Components/UI/Typography/SharedTypography";
import useCreditWidget from "hooks/useCreditWidget";
import { useIsDesktop } from "hooks/useIsDesktop";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import CreditWidgetInstance from "./CreditWidgetInstance";
import CreditWidgetLegendBox from "./CreditWidgetLegendBox";

const CreditWidgetInvoicePage = () => {
  const { t } = useTranslation();
  const IsDesktop = useIsDesktop();
  const { creditWidgetData, isSuccess, localStorageCustomer } =
    useCreditWidget();

  if (creditWidgetData?.length === 0) return null;

  return (
    isSuccess &&
    creditWidgetData.map((creditData, index) => (
      <Fragment key={index}>
        <OrderCardHeading>
          {t("overview.cashBalance.summaryFor")}
        </OrderCardHeading>
        <OverviewBoxHeadingStyled sx={{ marginBottom: 1 }}>
          {localStorageCustomer?.customerName}
        </OverviewBoxHeadingStyled>
        <CreditWidgetInstance
          creditData={creditData}
          payer={localStorageCustomer?.customerName}
        />
        <CreditWidgetLegendBox
          creditData={creditData}
          flexDirection={IsDesktop ? "column" : "row"}
        />
      </Fragment>
    ))
  );
};

export default CreditWidgetInvoicePage;
