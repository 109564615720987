import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actions as orderListActions } from "reduxStore/slices/contentTable";
import { actions as deliveryOIActions } from "reduxStore/slices/deliveryOI.js";
import { actions as invoicesActions } from "reduxStore/slices/invoices.js";

const rootActions = {
  ...orderListActions,
  ...deliveryOIActions,
  ...invoicesActions,
};

export function useActions() {
  const dispatch = useDispatch();
  return useMemo(() => bindActionCreators(rootActions, dispatch), [dispatch]);
}
