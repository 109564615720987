import { styled, Typography } from "@mui/material";
import moment from "moment";
import { useEffect } from "react";
import Countdown from "react-countdown";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const CountdownTypography = styled(Typography)(() => ({
  ".countdown": {
    color: "rgb(204, 8, 1)",
  },
}));

const OICountdown = ({ date, setIsTimeOut }) => {
  const { t } = useTranslation();

  const currentOption = useSelector((state) => state.deliveryOI.currentOption);
  const cutOffTime = currentOption?.businessDays?.cutOffTime;

  const diffInDays = moment(date)
    .startOf("day")
    .diff(moment().startOf("day"), "days");

  const handleTimeOut = () => {
    if (diffInDays === 1) setIsTimeOut(true);
  };

  const renderer = ({ completed, formatted: { hours, minutes, seconds } }) => {
    if (diffInDays > 1) return;

    let countdown;
    if (completed) {
      return;
    } else if (hours >= 1) {
      countdown = t("orderIntake.timeLeftToOrderHours", { count: hours });
    } else if (hours < 1 && minutes >= 1) {
      countdown = t("orderIntake.timeLeftToOrderMinutes", { count: minutes });
    } else if (minutes < 1) {
      countdown = t("orderIntake.timeLeftToOrderSeconds", { count: seconds });
    }

    return (
      <CountdownTypography variant="caption">
        {t("orderIntake.timeLeftToOrder")}{" "}
        <span className="countdown">{countdown}</span>
      </CountdownTypography>
    );
  };

  useEffect(() => {
    if (diffInDays > 1) setIsTimeOut(false);
  }, [date, setIsTimeOut, diffInDays]);

  return (
    <Countdown
      zeroPadTime={0}
      daysInHours
      renderer={renderer}
      date={cutOffTime}
      onComplete={handleTimeOut}
    />
  );
};

export default OICountdown;
