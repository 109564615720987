import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";
import contentTableReducer from "./slices/contentTable";
import deliveryOIReducer from "./slices/deliveryOI";
import invoicesReducer from "./slices/invoices";

export const store = configureStore({
  reducer: {
    contentTable: contentTableReducer,
    deliveryOI: deliveryOIReducer,
    invoices: invoicesReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(apiSlice.middleware),
  devTools: true,
});
