import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import FormatDate from "Components/UI/FormatDate/FormatDate";
import PdfLink from "Components/UI/PdfLink/PdfLink";
import { downloadFileUsingType } from "helpers/helpers";
import { useTranslation } from "react-i18next";
import FullScreenDeliveriesStatus from "./FullScreenDeliveriesStatus";

const FullscreenDeliveriesDesktop = ({ deliveries }) => {
  const { t } = useTranslation();
  const handleDownload = () => downloadFileUsingType(deliveries.links[0]);

  const tableHeadCells = [
    t("order.expand.deliveryNumber"),
    t("order.expand.leftAt"),
    t("order.expand.qtyLoaded"),
    t("order.expand.plantName"),
    t("order.expand.modeoftransport.truck"),
    t("order.expand.deliveryStatus"),
  ];

  return (
    <TableContainer component={Paper} sx={{ mb: 4 }}>
      <Table size="small" sx={{ minWidth: 600 }} aria-label="delivery table">
        <TableHead
          sx={{
            backgroundColor: "rgb(245, 246, 247)",
          }}
        >
          <TableRow>
            {tableHeadCells.map((cell, index) => (
              <TableCell key={index}>{cell}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            sx={{
              background: "#ebf3fc",
              "&>td": { paddingY: "16px" },
            }}
          >
            <TableCell>
              <PdfLink onClick={handleDownload}>
                {deliveries.deliveryNumber}
              </PdfLink>
            </TableCell>
            <TableCell>
              <FormatDate
                day="2-digit"
                month="2-digit"
                year="numeric"
                timeStart={deliveries.shippingDate}
              />
            </TableCell>
            <TableCell>{`${deliveries.loadQuantity} ${deliveries.loadQuantityUom}`}</TableCell>
            <TableCell>{deliveries.plantName}</TableCell>
            <TableCell>{deliveries.truckLicensePlate}</TableCell>
            <TableCell>
              <FullScreenDeliveriesStatus
                status={deliveries.deliveryStatus}
                customerId={deliveries.customerId}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FullscreenDeliveriesDesktop;
