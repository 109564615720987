import InvoiceCostSummary from "./InvoiceCostSummary";
import InvoiceDeliveryItems from "./InvoiceDeliveryItems";
import InvoiceSummary from "./InvoiceSummary";

const InvoiceDetails = ({ currentInvoiceData }) => {
  const currentOrderProp = { currentInvoiceData };

  return (
    <>
      <InvoiceSummary {...currentOrderProp} />
      <InvoiceDeliveryItems
        deliveryItems={currentInvoiceData.lineItems}
        currency={currentInvoiceData.currency}
      />
      <InvoiceCostSummary {...currentOrderProp} />
    </>
  );
};

export default InvoiceDetails;
