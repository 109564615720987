import { Box } from "@mui/material";
import parse from "html-react-parser";
import { useGetLegalQuery } from "reduxStore/api/legalApiSlice";
import LegalPageOssCard from "./LegalPageOssCard";

const LegalPageContent = ({ page = "terms" }) => {
  const { data, isFetching, isError, isSuccess } = useGetLegalQuery(page);

  return (
    <Box
      sx={{
        fontSize: ".8rem",
        "&>*": { wordBreak: "break-word", textAlign: "justify" },
      }}
    >
      {isFetching && `Loading...`}
      {isError && `There was an error loading this page`}
      {isSuccess &&
        !isFetching &&
        (page === "oss"
          ? data?.results.map((lib) => (
              <LegalPageOssCard lib={lib} key={lib.library} />
            ))
          : parse(data.text))}
    </Box>
  );
};

export default LegalPageContent;
