import useCreditWidget from "hooks/useCreditWidget";
import { OverviewBoxStyled } from "../Overview/Shared";
import CreditWidgetInstance from "./CreditWidgetInstance";
import CreditWidgetLegendBox from "./CreditWidgetLegendBox";
import CreditWidgetTopText from "./CreditWidgetTopText";

const CreditWidgetOverview = () => {
  const { creditWidgetData, isSuccess, localStorageCustomer } =
    useCreditWidget();

  return (
    isSuccess &&
    creditWidgetData.map((creditData, index) => (
      <OverviewBoxStyled sx={{ marginBottom: 2 }} key={index}>
        <CreditWidgetTopText
          payer={localStorageCustomer?.customerName}
          payerNumber={creditData.payerNumber}
        />

        <CreditWidgetInstance
          creditData={creditData}
          payer={localStorageCustomer?.customerName}
        />
        <CreditWidgetLegendBox creditData={creditData} />
      </OverviewBoxStyled>
    ))
  );
};

export default CreditWidgetOverview;
