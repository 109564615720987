import {
  Box,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const OISpecialInstructionsInput = ({ driverInstructions }) => {
  const { t } = useTranslation();

  const [specialInstructions, setSpecialInstructions] =
    useState(driverInstructions);
  const [isError, setIsError] = useState(false);
  const [switchState, setSwitchState] = useState(false);

  const handleSwitch = () => setSwitchState((prev) => !prev);
  const handleSpecialInstructions = (input) => {
    if (/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(input)) {
      setIsError(true);
    } else {
      setSpecialInstructions(input);
      setIsError(false);
    }
  };

  useEffect(() => {
    if (!switchState) {
      handleSpecialInstructions("");
    }
  }, [switchState]);

  useEffect(() => {
    if (driverInstructions) {
      setSwitchState(true);
      handleSpecialInstructions(driverInstructions);
    }

    if (driverInstructions === "") {
      setSwitchState(false);
      handleSpecialInstructions("");
    }
  }, [driverInstructions]);

  const { setValue } = useFormContext();

  useEffect(() => {
    setValue("driverInstructions", specialInstructions);
  }, [setValue, specialInstructions]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 1,
        }}
      ></Box>
      <FormControlLabel
        labelPlacement="start"
        control={
          <Switch size="small" checked={switchState} onChange={handleSwitch} />
        }
        label={t("orderIntake.addDriverInstruction")}
        sx={{ display: "flex", justifyContent: "space-between", margin: 0 }}
      />

      <TextField
        fullWidth
        multiline
        error={isError}
        rows={4}
        value={specialInstructions}
        onChange={(e) => handleSpecialInstructions(e.target.value)}
        id="oi-delivery-form-instructions"
        label={t("orderIntake.driverInstructions")}
        variant="filled"
        helperText={`${specialInstructions.length} / 200`}
        inputProps={{ maxLength: 200 }}
        sx={switchState ? { display: "inline-flex" } : { display: "none" }}
      />

      {isError && (
        <Typography
          variant="caption"
          sx={{ color: (theme) => theme.palette.error.main }}
        >
          {t("orderIntake.driverInstructionsInvalidCharacters")}
        </Typography>
      )}
    </>
  );
};

export default OISpecialInstructionsInput;
