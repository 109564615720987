import GroupIcon from "@mui/icons-material/Group";
import { Link, ListItemIcon, MenuItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const ManageMenu = () => {
  const { t } = useTranslation();

  return (
    <MenuItem key="Manage users">
      <ListItemIcon>
        <GroupIcon fontSize="small" />
      </ListItemIcon>
      <Typography textAlign="center">
        <Link
          sx={{ marginLeft: 0, textDecoration: "none", color: "inherit" }}
          href="/admin/clients/user/"
        >
          {t("header.userAdmin")}
        </Link>
      </Typography>
    </MenuItem>
  );
};

export default ManageMenu;
