import { Button, Typography } from "@mui/material";
import Countdown from "react-countdown";
import { useTranslation } from "react-i18next";

const QuickCodeCountdown = () => {
  const { t } = useTranslation();

  const renderer = ({ completed, formatted: { minutes, seconds } }) => {
    let countdown;
    if (completed) {
      return <Button sx={{ marginY: 2 }}>{t("landingPage.getCode")}</Button>;
    } else if (minutes < 1) {
      countdown = seconds;
    }

    return (
      <Typography
        variant="body"
        sx={{ marginY: 2, padding: "6px 8px", textAlign: "center" }}
      >
        {t("landingPage.countdown", { seconds: countdown })}
      </Typography>
    );
  };

  return (
    <Countdown
      zeroPadTime={0}
      daysInHours
      renderer={renderer}
      date={Date.now() + 59000}
    />
  );
};

export default QuickCodeCountdown;
