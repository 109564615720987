import { Box, ButtonBase, Paper, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const FilterSelectedBox = ({ label, icon, children, handleClick }) => {
  const { t } = useTranslation();

  return (
    <ButtonBase
      onClick={handleClick}
      sx={{
        display: "block",
        width: "100%",
      }}
    >
      <Paper
        square={false}
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: (theme) => theme.palette.inputBackgroundColor.main,
          border: (theme) => `1px solid ${theme.palette.inputBorderColor.main}`,
          padding: "5px",
          paddingBottom: "8px",
          paddingLeft: "12px",
          "&:hover": {
            background: "#E7E7E7",
          },
        }}
      >
        <Box maxWidth="90%" minWidth="195px">
          <Stack textAlign="left">
            <Typography variant="caption" color="text.secondary">
              {t(label)}
            </Typography>
            {children}
          </Stack>
        </Box>
        <Box sx={{ paddingTop: "9px", marginLeft: "5px" }}>{icon}</Box>
      </Paper>
    </ButtonBase>
  );
};

export default FilterSelectedBox;
