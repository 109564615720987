import { Box, Button, Divider } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { OrderCardHeading, OrderCardText } from "../UI/Typography/OrderCard";
import InvoiceDeliveryCards from "./InvoiceDeliveryCards";

const InvoiceDeliveryItems = ({ deliveryItems, currency }) => {
  const { t } = useTranslation();
  const [isViewAll, setIsViewAll] = useState(false);

  if (deliveryItems.length === 0) return null;

  const handleViewAll = () => setIsViewAll((prev) => !prev);

  return (
    <>
      <Divider sx={{ marginY: 1 }} />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <OrderCardHeading variant="subtitle">
            {t("order.orderDetails.deliveryItems")}
          </OrderCardHeading>
          <OrderCardText>
            {deliveryItems.length} {t("order.orderDetails.deliveryItems")}
          </OrderCardText>
        </Box>
        <Button onClick={handleViewAll}>{t("overview.showAll")}</Button>
      </Box>
      <Box>
        <InvoiceDeliveryCards
          isViewAll={isViewAll}
          deliveryCards={deliveryItems}
          currency={currency}
        />
      </Box>
    </>
  );
};

export default InvoiceDeliveryItems;
