import CloseIcon from "@mui/icons-material/Close";
import { Box, ButtonBase, Divider, Grid } from "@mui/material";
import InvoiceDeliveryItems from "Components/Invoices/InvoiceDeliveryItems";
import {
  OrderCardHeading,
  OrderCardSub,
  OrderCardText,
} from "Components/UI/Typography/OrderCard";
import { useTranslation } from "react-i18next";

const FullscreenInvoiceDeliveriesItems = ({
  setIsDeliveryItemsOpen,
  currentInvoiceData,
}) => {
  const { t } = useTranslation();
  const closeDetailsCard = () => setIsDeliveryItemsOpen(false);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <OrderCardText>
          {t("invoiceDetail.invoiceDeliveriesItems")}
        </OrderCardText>
        <Box>
          <ButtonBase onClick={closeDetailsCard}>
            <CloseIcon
              sx={{
                padding: "12px",
              }}
            />
          </ButtonBase>
        </Box>
      </Box>
      <Divider sx={{ marginY: 1 }} />
      <Grid
        container
        spacing={2}
        sx={{ alignItems: "center", marginBottom: 1 }}
      >
        <Grid item xs={12} sx={{ display: "flex", flexDirection: "column" }}>
          <OrderCardHeading>
            {t("invoiceDetail.lineItems.dateShipped")}
          </OrderCardHeading>
          <OrderCardSub>09/27/2024</OrderCardSub>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "left" }}>
          <OrderCardHeading>
            {t("invoiceDetail.lineItems.expand.materialDescription")}
          </OrderCardHeading>
          <Box>
            <OrderCardSub>ЦЕМ I 42,5Н</OrderCardSub>
          </Box>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: "left" }}>
          <OrderCardHeading>
            {t("invoiceDetail.lineItems.expand.quantity")}
          </OrderCardHeading>
          <Box>
            <OrderCardSub>105 t</OrderCardSub>
          </Box>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: "left" }}>
          <OrderCardHeading>
            {t("invoiceDetail.lineItems.expand.netAmount")}
          </OrderCardHeading>
          <Box>
            <OrderCardSub>₽699,212.49</OrderCardSub>
          </Box>
        </Grid>
      </Grid>
      <InvoiceDeliveryItems currentInvoiceData={currentInvoiceData} />
    </>
  );
};

export default FullscreenInvoiceDeliveriesItems;
