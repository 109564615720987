import Paper from "@mui/material/Paper";
import PaymentSummary from "Components/Invoices/PaymentSummary";
import { useDetailsCard } from "hooks/useDetailsCard";
import { useGetTableData } from "hooks/useGetTableData";
import { useSelectedItemsCard } from "hooks/useSelectedItemsCard";
import { useSelector } from "react-redux";
import { slideCard } from "Theme";
import InvoiceDetails from "../../Invoices/InvoiceDetails";
import OrderDetails from "../../OrderList/OrderDetails";
import NoDataOverlay from "./PageCard/NoDataOverlay";
import PageCardOrderExport from "./PageCard/PageCardOrderExport";

const ItemDetails = () => {
  const { isDetailsCardOpen } = useDetailsCard();
  const { isSelectedOpen } = useSelectedItemsCard();
  const { data, isInvoices } = useGetTableData();

  const currentOrderId = useSelector((state) => state.contentTable.orderId);
  const currentItemData = data?.results.length && data.results[currentOrderId];

  const initialView = isInvoices ? <PaymentSummary /> : <NoDataOverlay />;
  const content = isInvoices ? (
    <InvoiceDetails currentInvoiceData={currentItemData} />
  ) : (
    <OrderDetails currentOrderData={currentItemData} />
  );

  const renderDetails = () => {
    if (isSelectedOpen) return <PageCardOrderExport />;
    if (!currentItemData || !isDetailsCardOpen) return initialView;
    return content;
  };

  return (
    <Paper
      data-testid="itemDetailsContainer"
      elevation={4}
      sx={(theme) => ({
        padding: "16px",
        display: "flex",
        flexDirection: "column",
        position: "sticky",
        top: "10px",
        overflowY: "auto",
        height: "600px",
        [theme.breakpoints.down("lgr")]: {
          display: !isDetailsCardOpen && "none",
          position: "fixed",
          top: "200px",
          right: "10px",
          minWidth: "300px",
          width: "40%",
          animation: `${slideCard} .2s`,
          zIndex: "6",
        },
      })}
    >
      {renderDetails()}
    </Paper>
  );
};

export default ItemDetails;
