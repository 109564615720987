import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  ButtonBase,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import FormatDate from "Components/UI/FormatDate/FormatDate";
import { useActions } from "hooks/useActions";
import useUser from "hooks/useUser";
import moment from "moment";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSendOrdersMutation } from "reduxStore/api/deliveryApiSlice";
import EmailInput from "../UI/EmailInput/EmailInput";
import { PageSubHeading } from "../UI/Typography/SharedTypography";

const DeliveryOISummary = ({ isCollect }) => {
  const { t } = useTranslation();

  const {
    setIsConfirmation,
    setIsTabsFormOpen,
    setSummary,
    setTabsForm,
    updateRow,
  } = useActions();
  const data = useUser();
  const isCutOffTime = useSelector((state) => state.deliveryOI.isCutOffTime);

  const [sendOrders, { isLoading, isUninitialized }] = useSendOrdersMutation();

  const currentOption = useSelector((state) => state.deliveryOI.currentOption);
  const summary = useSelector((state) => state.deliveryOI.summary);

  const tableRows = useSelector((state) => state.deliveryOI.tableRows);

  const placeOrder = () => {
    tableRows.forEach(async (order) => {
      try {
        await sendOrders({
          businessLine: currentOption.materials[0].businessLine,
          createdOn: new Date().toISOString(),
          customerNumber: currentOption.customerNumber,
          siteNumber: currentOption.siteNumber,
          countryId: currentOption.countryId,
          orgUnitId: currentOption.orgUnitId,
          type: "createOrder",
          status: "notValidated",
          contact: {
            fullName: data?.name,
          },
          requestData: {
            additionalDriverInfo: {},
            businessLineCode: currentOption.materials[0].businessLine,
            contact: {
              backupPhone: "",
              carbonCopyEmail: "",
              confirmationSapEmailEnabled: false,
              fullName: "Cloned user 612810",
              mainPhone: "+74872456777",
              shipToEmail: "",
            },
            contractItemPosNumber: order.materials.contractItemPositionNumber,
            contractNumber: order.materials.contractNumber,
            country: "RU",
            countryId: currentOption.countryId,
            customerIdPreffix: "eu",
            customerNumber: currentOption.customerNumber,
            customerReference: order.customerReference,
            deliveryDate: moment(order.deliveryDate).format("YYYY-MM-DD"),
            deliveryTime: {
              earliest: order.defaultDeliveryWindow.defaultEarliestLoadTime,
              latest: order.defaultDeliveryWindow.defaultLatestLoadTime,
            },
            haulerInfo: {
              driverName: "",
              driverPhoneNumber: "",
              trailerLicensePlate: "",
              truckLicensePlate: "",
            },
            isSendingConfirmationEmailUnChecked: false,
            material: {
              contractNumber: order.materials.contractNumber,
              materialDescription: order.materials.materialDescription,
              materialDescriptionLong: order.materials.materialDescription,
              materialEnteredNumber: order.materials.materialEnteredNumber,
            },
            materialNumber: order.materials.materialEnteredNumber,
            plantNumber: "RU22",
            quantity: order.truckCapacity.capacity,
            quantityType: "truckCapacity",
            quantityUom: order.truckCapacity.capacityUom || t("capacityTons"),
            shippingAddress: {
              city: currentOption.city,
              country: currentOption.countryId,
              postalCode: currentOption.postalCode,
              siteName: currentOption.siteName,
              state: currentOption.state,
              street: currentOption.street,
            },
            shippingType: isCollect ? "collect" : "deliver",
            siteNumber: currentOption.siteNumber,
          },
          isSendingConfirmationEmailUnChecked: false,
        }).unwrap();
        updateRow({ ...order, isError: false });
      } catch {
        updateRow({ ...order, isError: true });
      }
    });
  };

  const handleEditClick = (order) => {
    setIsTabsFormOpen(true);
    setTabsForm(order);
  };

  useEffect(() => {
    if (!isUninitialized && !isLoading) {
      setSummary();
      setIsConfirmation(true);
    }
  }, [isUninitialized, isLoading, setIsConfirmation, setSummary]);

  return (
    <Box>
      <Box marginBottom={2}>
        <PageSubHeading>{t("orderIntake.orderSummary")}</PageSubHeading>
      </Box>

      {Object.keys(summary).map((material, index) => {
        return (
          <Box key={index} marginBottom={3}>
            <Typography>{`${summary[material].total}${
              currentOption?.truckCapacity?.capacityUom || t("capacityTons")
            } | ${material}`}</Typography>

            {summary[material].orders.map((order, _, array) => {
              return (
                <Box
                  key={order.orderId}
                  sx={(theme) => ({
                    display: "flex",
                    justifyContent: "space-between",
                    color: theme.palette.text.secondary,
                  })}
                >
                  {array.length > 1 && (
                    <Typography marginRight={3}>
                      {`${order.truckCapacity.capacity}${
                        currentOption?.truckCapacity?.capacityUom ||
                        t("capacityTons")
                      }`}
                    </Typography>
                  )}
                  <Typography flexGrow={1}>
                    <FormatDate
                      day="2-digit"
                      month="2-digit"
                      year="numeric"
                      timeStart={
                        order?.deliveryDate ||
                        order?.businessDays[
                          isCutOffTime
                            ? "daysAfterCutOffTime"
                            : "daysBeforeCutOffTime"
                        ][0]
                      }
                    />{" "}
                    |{" "}
                    {/* <FormatDate
                      hour="2-digit"
                      minute="2-digit"
                      timeStart={
                        order.defaultDeliveryWindow.defaultEarliestLoadTime
                      }
                      timeEnd={
                        order.defaultDeliveryWindow.defaultLatestLoadTime
                      }
                    /> */}
                    {` ${order.defaultDeliveryWindow.defaultEarliestLoadTime} - ${order.defaultDeliveryWindow.defaultLatestLoadTime}`}
                  </Typography>
                  <Box>
                    <ButtonBase onClick={() => handleEditClick(order)}>
                      <EditIcon fontSize="small" />
                    </ButtonBase>
                  </Box>
                </Box>
              );
            })}
          </Box>
        );
      })}

      <Box marginBottom={2}>
        <PageSubHeading marginBottom={1}>
          {isCollect
            ? t("orderIntake.collectionAddress")
            : t("orderIntake.deliverTo")}
        </PageSubHeading>
        <Box>
          <Typography variant="subtitle">
            {`${currentOption?.siteName}`}
          </Typography>
          <br />
          <Typography variant="subtitle">
            {`${currentOption?.street}, ${currentOption?.city}`}
          </Typography>
        </Box>
      </Box>
      <Box marginBottom={1}>
        <EmailInput />
      </Box>
      <Box marginBottom={1}>
        <FormControlLabel
          control={<Checkbox size="small" />}
          label={
            <Typography sx={{ fontSize: "14px" }}>
              {t("orderIntake.sendConfirmationEmail")}
            </Typography>
          }
        />
      </Box>
      <Button variant="contained" fullWidth onClick={placeOrder}>
        {isLoading ? `${t("loading")}...` : t("orderIntake.placeOrder")}
      </Button>
    </Box>
  );
};

export default DeliveryOISummary;
