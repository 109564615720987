import moment from "moment";
import { actions } from "../slices/deliveryOI";
import { apiSlice } from "./apiSlice";

const GET_OPTIONS = "order-requests/options/";
const POST_DELIVERIES = "order-request/";

export const deliveryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDeliveryOptions: builder.query({
      query: (shippingType) => `${GET_OPTIONS}${shippingType}/`,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: deliveryOptions } = await queryFulfilled;

          if (deliveryOptions.length === 0)
            return dispatch(actions.setCurrentOption(undefined));

          const groupedSites = Object.groupBy(
            deliveryOptions,
            ({ siteName, siteNumber, street, city }) =>
              `${siteName}-${siteNumber}-${street}-${city}`
          );

          const merged = {};
          for (const property in groupedSites) {
            groupedSites[property].forEach(
              (site) =>
                (merged[property] = {
                  countryId: site.countryId,
                  customerNumber: site.customerNumber,
                  orgUnitId: site.orgUnitId,
                  siteName: site.siteName,
                  siteNumber: site.siteNumber,
                  street: site.street,
                  city: site.city,
                  truckCapacity: site.truckCapacity,
                  customerReference: site.customerReference,
                  driverInstructions: site.driverInstructions,
                  defaultDeliveryWindow: site.defaultDeliveryWindow,
                  materials: merged[property]?.materials
                    ? [...merged[property].materials, ...site.materials]
                    : [...site.materials],
                  businessDays: {
                    cutOffTime: site.businessDays.cutOffTime,
                    daysAfterCutOffTime: merged[property]?.businessDays
                      ?.daysAfterCutOffTime
                      ? [
                          ...merged[property].businessDays.daysAfterCutOffTime,
                          ...site.businessDays.daysAfterCutOffTime,
                        ]
                      : [...site.businessDays.daysAfterCutOffTime],
                    daysBeforeCutOffTime: merged[property]?.businessDays
                      ?.daysBeforeCutOffTime
                      ? [
                          ...merged[property].businessDays.daysBeforeCutOffTime,
                          ...site.businessDays.daysBeforeCutOffTime,
                        ]
                      : [...site.businessDays.daysBeforeCutOffTime],
                  },
                  options: merged[property]?.options
                    ? [...merged[property].options, site]
                    : [site],
                })
            );

            const uniqueMaterials = [];
            merged[property].materials = merged[property].materials.filter(
              (material) => {
                if (uniqueMaterials.includes(material.materialEnteredNumber)) {
                  return false;
                } else {
                  uniqueMaterials.push(material.materialEnteredNumber);
                  return true;
                }
              }
            );
          }

          const sitesResult = [];
          for (const property in merged) {
            sitesResult.push(merged[property]);
          }

          dispatch(actions.setDeliveryOptions(sitesResult));
          dispatch(actions.setCurrentOption(sitesResult[0]));
          dispatch(
            actions.setIsCutOffTime(
              moment().isAfter(sitesResult[0].businessDays.cutOffTime)
            )
          );
          dispatch(
            actions.setTabsForm({
              ...sitesResult[0],
              materials: sitesResult[0].materials[0],
              orderId: 0,
            })
          );
        } catch (err) {
          console.log(err);
        }
      },
      transformResponse: (response) => response.results,
    }),
    sendOrders: builder.mutation({
      query: (payload) => ({
        url: POST_DELIVERIES,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Orders"],
    }),
  }),
});

export const { useGetDeliveryOptionsQuery } = deliveryApiSlice;
export const { useSendOrdersMutation } = deliveryApiSlice;
