import { createSlice } from "@reduxjs/toolkit";
import { today, weekFromToday } from "constants/dates";
import { getDefaultCustomerFromLS } from "helpers/localstoragehelper";

const localStorageCustomer = getDefaultCustomerFromLS();

const initialState = {
  isDetailsCardOpen: false,
  isSelectedOpen: false,
  deliveriesToExport: [],
  orderId: undefined,
  currentCustomer: localStorageCustomer || {},
  orderQuery: {
    customerId: "",
    siteId: "",
    shippingDate: today.toISOString(),
    endDate: weekFromToday.toISOString(),
    limit: 10,
    offset: 0,
  },
  invoiceQuery: {
    customerId: "",
    siteId: "",
    shippingDate: today.toISOString(),
    endDate: weekFromToday.toISOString(),
    limit: 10,
    offset: 0,
  },
};

const contentTableSlice = createSlice({
  name: "contentTable",
  initialState,
  reducers: {
    setCurrentCustomer: (state, action) => {
      state.currentCustomer = action.payload;
    },
    setInvoiceQuery: (state, { payload }) => {
      state.invoiceQuery = {
        ...state.invoiceQuery,
        ...payload,
      };
    },
    setOrderQuery: (state, { payload }) => {
      state.orderQuery = {
        ...state.orderQuery,
        ...payload,
      };
    },
    setIsDetailsCardOpen: (state, action) => {
      state.isDetailsCardOpen = action.payload;
    },
    setIsSelectedOpen: (state, action) => {
      state.isSelectedOpen = action.payload;
    },
    setDeliveriesToExport: (state, action) => {
      state.deliveriesToExport = action.payload;
    },
    setOrderId: (state, action) => {
      state.orderId = action.payload;
    },
  },
});

export const { actions } = contentTableSlice;
export default contentTableSlice.reducer;
