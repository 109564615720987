import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Typography } from "@mui/material";
import FormatCurrency from "Components/UI/FormatCurrency/FormatCurrency";
import { OverviewBoxHeadingStyled } from "Components/UI/Typography/SharedTypography";
import { CaptionHeading } from "../UI/Typography/SharedTypography";

const CreditWidgetLegend = ({
  name,
  amount,
  currency,
  color,
  dispatched,
  exceededBy,
}) => {
  const isExceeded = exceededBy > 0;

  return (
    <Box>
      <Box sx={{ display: "flex", columnGap: "4px", paddingX: 1 }}>
        <Box sx={{ background: color, padding: "2px" }}></Box>
        <Box>
          <CaptionHeading sx={{ textTransform: "uppercase", fontWeight: 700 }}>
            {name}
          </CaptionHeading>
          <OverviewBoxHeadingStyled sx={{ color: isExceeded && "red" }}>
            <FormatCurrency
              value={isExceeded ? 0 : amount}
              currency={currency}
            />
          </OverviewBoxHeadingStyled>
        </Box>
      </Box>

      {dispatched && (
        <OverviewBoxHeadingStyled sx={{ paddingX: 1, marginTop: 0.5 }}>
          {dispatched}
        </OverviewBoxHeadingStyled>
      )}
      {isExceeded && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: 1,
            color: "red",
            paddingX: "12px",
          }}
        >
          <InfoOutlinedIcon fontSize="small" />
          <Box sx={{ "&>p": { fontSize: "12px" } }}>
            <Typography>Exceeded by</Typography>
            <Typography>
              <FormatCurrency value={exceededBy} currency={currency} />
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CreditWidgetLegend;
