import { Box, Button, styled, Typography } from "@mui/material";
import { rowsPerPage } from "constants/constants";
import { useTranslation } from "react-i18next";
import ContentTableExportButton from "./ContentTableExportButton";

export const OrderTablePaginationButton = styled((props) => (
  <Button {...props} />
))(() => ({}));

const ContentTablePagination = ({ handlePagination, pageSize }) => {
  const { t } = useTranslation();

  const handlePageSize = (pageSize) => {
    const limit = +pageSize.target.innerText;
    handlePagination({ page: 0, pageSize: limit });
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        marginX: "-16px",
        marginBottom: 4,
        padding: "10px 32px",
        background: (theme) => theme.palette.inputBackgroundColor.main,
      }}
    >
      <Box>
        <Typography display="inline">
          {t("order.resultsPerPage.header")}
        </Typography>
        {rowsPerPage.map((size) => (
          <OrderTablePaginationButton
            key={size}
            onClick={handlePageSize}
            sx={{ fontWeight: pageSize === size ? 700 : 500 }}
          >
            {size}
          </OrderTablePaginationButton>
        ))}
      </Box>
      <ContentTableExportButton />
    </Box>
  );
};

export default ContentTablePagination;
