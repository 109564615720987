import DownloadIcon from "@mui/icons-material/Download";
import { Button, IconButton } from "@mui/material";
import { useIsDesktop } from "hooks/useIsDesktop";
import { useTranslation } from "react-i18next";

const FSExportButtonMobile = () => {
  return (
    <IconButton
      sx={{
        backgroundColor: "white",
        "&:hover": { color: "black", backgroundColor: "white" },
      }}
    >
      <DownloadIcon />
    </IconButton>
  );
};

const FSExportButtonDesktop = () => {
  const { t } = useTranslation();
  return (
    <Button
      startIcon={<DownloadIcon />}
      variant="outlined"
      sx={{
        color: "white",
        borderColor: "lightgray",
        textTransform: "none",
        paddingX: 6,
        paddingY: 1,
        borderRadius: "7px",
      }}
    >
      {t("invoice.export.buttontitle")}
    </Button>
  );
};

const FSExportButton = () => {
  const isDesktop = useIsDesktop();
  return isDesktop ? <FSExportButtonDesktop /> : <FSExportButtonMobile />;
};

export default FSExportButton;
