import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import NavLinkStyled from "../../UI/NavLinkStyled/NavLinkStyled";

const NavBurger = ({ pages }) => {
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        color="inherit"
      >
        <MenuIcon
          fontSize="small"
          sx={{
            color: "black",
            borderRadius: "100%",
            padding: "10px",
            boxShadow: "-2px 0px 8px rgba(0, 0, 0, 0.2)",
          }}
        />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        disableScrollLock
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: { xs: "block", md: "none" },
        }}
      >
        {pages.map((page) => {
          return (
            <NavLinkStyled key={page.pageLink} to={page.pageLink}>
              <MenuItem key={page} onClick={handleCloseNavMenu}>
                <Typography color={"#00374d"} textAlign="center">
                  {page.pageName}
                </Typography>
              </MenuItem>
            </NavLinkStyled>
          );
        })}
      </Menu>
    </Box>
  );
};

export default NavBurger;
