import SearchIcon from "@mui/icons-material/Search";
import { Alert, IconButton, TextField } from "@mui/material";
import { useCustomerQuery } from "hooks/useCustomerQuery";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const overviewProps = {
  InputLabelProps: {
    shrink: true,
    sx: {
      color: "white",
      transform: "none",
      fontSize: "12px",
      "&.Mui-focused": {
        color: "white",
      },
    },
  },
  InputProps: {
    sx: {
      color: "white",
      "&.Mui-focused": { background: "none" },
    },
  },
  sx: {
    maxWidth: { md: "70%", xs: "100%" },
    "& .MuiInputBase-root": {
      backgroundColor: "transparent",
      border: "none",
    },
    "& .MuiInputBase-root:hover": {
      background: "none",
    },
    "& .MuiFilledInput-root": {
      borderBottom: "2px solid white",
    },
    "& .MuiFilledInput-input": {
      paddingLeft: "0px",
    },
    "& .MuiFilledInput-root:after": {
      border: "none",
    },
  },
};

const tablePageProps = {
  InputProps: {
    sx: {
      borderRadius: 0,
    },
  },
  sx: {
    "& .MuiInputBase-root": {
      background: "none",
      border: "none",
    },
  },
};

const CustomerNumberFilter = ({ isOverview = false }) => {
  const { t } = useTranslation();

  const {
    data,
    isFetching,
    customerNumberName,
    clear,
    sendCustomerNumber,
    handleCustomerNumberChange,
    handleOnBlur,
  } = useCustomerQuery();

  const handleSubmit = () => {
    sendCustomerNumber();
    setSearchedName(customerNumberName);
  };

  const [searchedName, setSearchedName] = useState("");

  const calculatedProps = isOverview ? overviewProps : tablePageProps;
  const textFieldProps = {
    ...calculatedProps,
    InputProps: {
      endAdornment: (
        <IconButton onClick={handleSubmit}>
          <SearchIcon sx={{ color: isOverview ? "white" : "inherit" }} />
        </IconButton>
      ),
      sx: {
        color: "white",
        "&.Mui-focused": { background: "none" },
      },
      ...calculatedProps.InputProps,
    },
  };

  return (
    <>
      <TextField
        autoComplete="off"
        fullWidth
        variant="filled"
        onFocus={clear}
        onBlur={handleOnBlur}
        disabled={isFetching}
        label={t("filterBar.customerLabel")}
        value={customerNumberName}
        onChange={handleCustomerNumberChange}
        onKeyDown={({ key }) => key === "Enter" && handleSubmit()}
        {...textFieldProps}
      />
      {isOverview && data?.length === 0 && (
        <Alert
          icon={false}
          sx={{
            mt: 3,
            backgroundColor: (theme) => theme.palette.text.primary,
            color: "white",
          }}
        >
          {t("filterBar.errorCustomerNotFound", {
            CUSTOMER_NUMBER: searchedName,
          })}
        </Alert>
      )}
    </>
  );
};

export default CustomerNumberFilter;
