import { Box } from "@mui/material";
import {
  dispatchedColor,
  notDispatchedColor,
  unpaidColor,
} from "constants/constants";
import { useTranslation } from "react-i18next";
import CreditWidgetLegend from "./CreditWidgetLegend";

const CreditWidgetLegendBox = ({ creditData, flexDirection = "row" }) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        flexDirection,
        "&>div": { marginY: 1, marginRight: 2 },
      }}
    >
      <CreditWidgetLegend
        name={t("overview.cashBalance.unpaid")}
        amount={creditData.receivables}
        currency={creditData.currency}
        color={unpaidColor}
      />
      <CreditWidgetLegend
        name={t("overview.cashBalance.notYetInvoiced")}
        amount={creditData.deliveredNotBilled}
        currency={creditData.currency}
        color={dispatchedColor}
        dispatched={t("overview.cashBalance.dispatched")}
      />
      <CreditWidgetLegend
        name={t("overview.cashBalance.notYetInvoiced")}
        amount={creditData.orderedNotDelivered}
        currency={creditData.currency}
        color={notDispatchedColor}
        dispatched={t("overview.cashBalance.notYetDispatched")}
      />
      <CreditWidgetLegend
        name={t("overview.cashBalance.creditLeft")}
        amount={creditData.remainingCredit}
        currency={creditData.currency}
        color="black"
        exceededBy={creditData.exceededLimit}
      />
    </Box>
  );
};

export default CreditWidgetLegendBox;
