import { apiSlice } from "./apiSlice";
const COMPONENTS = "components/";

export const componentsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getComponents: builder.query({
      query: (userId) => `${COMPONENTS}?userId=${userId}`,
      transformResponse: (response) => response.results,
    }),
  }),
});

export const { useGetComponentsQuery } = componentsApiSlice;
