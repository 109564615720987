import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import { HCLogo, HubLogo } from "assets/img/images";
import Logo from "./Logo";
import NavMenu from "./Navbar/NavMenu";
import UserMenu from "./UserMenu";

const AppHeader = ({ children }) => {
  return (
    <>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "white",
        }}
      >
        <Container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minWidth: { xs: "0" },
            maxWidth: { xs: "none" },
            paddingX: { xs: 0, lg: "20px" },
          }}
        >
          <Toolbar sx={{ width: "100vw" }}>
            <NavMenu />
            <Logo logo={HCLogo} mobileHide={true} />
            <Logo logo={HubLogo} />
            <UserMenu />
          </Toolbar>
        </Container>
      </AppBar>
      {children}
    </>
  );
};
export default AppHeader;
