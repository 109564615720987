import CloseIcon from "@mui/icons-material/Close";
import DateRangeIcon from "@mui/icons-material/DateRange";
import DeleteIcon from "@mui/icons-material/Delete";
import EastIcon from "@mui/icons-material/East";
import EventNoteIcon from "@mui/icons-material/EventNote";
import InfoIcon from "@mui/icons-material/Info";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import {
  Alert,
  Button,
  ButtonBase,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { getAvailableDate } from "helpers/helpers";
import { useActions } from "hooks/useActions";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  a11yProps,
  CustomTabPanel,
} from "../UI/GenericTabImports/GenericTabImports";
import InputHelperCount from "../UI/InputHelperCount/InputHelperCount";
import StickyButtonBox from "../UI/StickyButtonBox/StickyButtonBox";
import { PageSubHeading } from "../UI/Typography/SharedTypography";
import DriverPhoneInput from "./OIForm/DriverPhoneInput";
import HubDatePicker from "./OIForm/HubDatePicker";
import IncrementInput from "./OIForm/IncrementInput";
import MaterialDropdown from "./OIForm/MaterialDropdown";
import OISpecialInstructionsInput from "./OIForm/OISpecialInstructionsInput";
import POInput from "./OIForm/POInput";
import OISummary from "./OISummary";
import OITimeTabs from "./OITimeTabs";

const StyledTab = styled((props) => <Tab {...props} />)(() => ({
  textTransform: "none",
}));

const OITabsForm = ({ isCollect }) => {
  const { t } = useTranslation();

  const {
    removeTableRow,
    setIsTabsFormOpen,
    setSummary,
    setTableRow,
    setTabsForm,
  } = useActions();

  const hookFormMethods = useForm();
  const { register, handleSubmit } = hookFormMethods;
  const [tabNum, setTabNum] = useState(0);

  const currentOption = useSelector((state) => state.deliveryOI.currentOption);

  const tabsForm = useSelector((state) => state.deliveryOI.tabsForm);
  const tableRows = useSelector((state) => state.deliveryOI.tableRows);
  const currentMaterial = useSelector(
    (state) => state.deliveryOI.currentMaterial
  );

  const deliveryDate = getAvailableDate(tabsForm.deliveryDate);
  const isTabsFormOpen = useSelector(
    (state) => state.deliveryOI.isTabsFormOpen
  );

  const handleChange = (_, newValue) => setTabNum(newValue);
  const backButtonFunc = () => setTabNum(0);
  const handleClose = () => {
    setSummary();
    setIsTabsFormOpen(false);
  };
  const handleRemoveTableRow = (id) => {
    removeTableRow(id);
  };

  const submitHookForms = (data) => {
    setTableRow({ ...data, orderId: tabsForm.orderId });
    setTabsForm({
      ...data,
      orderId: tabsForm.orderId,
      materials: currentMaterial,
    });

    setSummary();
    setIsTabsFormOpen(false);
  };

  useEffect(() => {
    setTableRow(tabsForm);
    setSummary(tabsForm);
  }, [setTableRow, setSummary, tabsForm]);

  if (!currentOption) return null;

  return isTabsFormOpen ? (
    <Box>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "transparent",
          position: "relative",
        }}
      >
        <ButtonBase
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: "-4px",
            right: "-4px",
            zIndex: 2,
          }}
        >
          <CloseIcon color="primary" sx={{ fontSize: "20px" }} />
        </ButtonBase>
        <Tabs
          value={tabNum}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="delivery-form-tabs"
        >
          <StyledTab
            icon={<EventNoteIcon />}
            iconPosition="start"
            label={t("orderIntake.materialOrder.details")}
            {...a11yProps(0)}
          />
          <StyledTab
            icon={<DateRangeIcon />}
            iconPosition="start"
            label={t("orderIntake.materialOrder.dateTime")}
            {...a11yProps(1)}
          />
          {isCollect && (
            <StyledTab
              icon={<LocalShippingIcon />}
              iconPosition="start"
              label={t("orderIntake.haulerInfo.haulerInfo")}
              {...a11yProps(2)}
            />
          )}
        </Tabs>
      </Box>
      <FormProvider {...hookFormMethods}>
        <CustomTabPanel value={tabNum} index={0}>
          <Box position="relative">
            <Box>
              <Stack>
                <Box>
                  <Box marginBottom={1}>
                    <Typography variant="caption">
                      {isCollect
                        ? t("orderIntake.collectionAddress")
                        : t("orderIntake.deliverTo")}
                    </Typography>
                    <Box>
                      <Typography variant="subtitle">
                        {`${currentOption?.siteName}`}
                      </Typography>
                      <br />
                      <Typography variant="subtitle">
                        {`${currentOption?.street}, ${currentOption?.city}`}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: tabsForm?.isTimeOut ? "block" : "none",
                      marginBottom: 2,
                      border: (theme) =>
                        `1px solid ${theme.palette.text.headings}`,
                      background: (theme) =>
                        `${theme.palette.inputBackgroundColor.main}`,
                      borderRadius: "4px",
                      padding: 2,
                    }}
                  >
                    <PageSubHeading
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <InfoIcon sx={{ marginRight: 2 }} />
                      The time for this date's delivery has expired.
                      <br />
                      Please select next available date.
                    </PageSubHeading>
                  </Box>

                  <Stack
                    sx={{ display: tabsForm?.isTimeOut ? "none" : "block" }}
                  >
                    <Box marginBottom={2}>
                      <MaterialDropdown
                        register={register}
                        propOptions={currentOption.materials}
                        selection={tabsForm.materials}
                      />
                    </Box>
                    <Box marginBottom={1}>
                      <Stack>
                        <IncrementInput
                          truckCapacity={tabsForm.truckCapacity}
                        />
                      </Stack>
                    </Box>
                  </Stack>

                  <Box marginBottom={1}>
                    <POInput customerReference={tabsForm.customerReference} />
                  </Box>
                  {!isCollect && (
                    <OISpecialInstructionsInput
                      driverInstructions={tabsForm.driverInstructions}
                    />
                  )}
                </Box>
              </Stack>
            </Box>
          </Box>
        </CustomTabPanel>

        <CustomTabPanel value={tabNum} index={1}>
          <Box position="relative">
            <Box>
              <Stack>
                <Box>
                  <Box marginBottom={2}>
                    <Typography variant="caption">
                      {isCollect
                        ? t("orderIntake.materialOrder.location")
                        : t("orderIntake.materialOrder.shipTo")}
                    </Typography>
                    <Box>
                      <Typography variant="subtitle">
                        {`${currentOption?.siteName}`}
                      </Typography>
                    </Box>
                  </Box>
                  <HubDatePicker deliveryDate={deliveryDate} />
                  {false && (
                    <Alert severity="warning" sx={{ mt: 3 }}>
                      {t("orderIntake.supplyingDestinationDifferent")}
                    </Alert>
                  )}
                  <OITimeTabs time={tabsForm?.defaultDeliveryWindow} />
                </Box>
              </Stack>
            </Box>
          </Box>
        </CustomTabPanel>

        {isCollect && (
          <CustomTabPanel value={tabNum} index={2}>
            <InputHelperCount
              label={t("order.orderDetails.driverName")}
              id="driverName"
              value={tabsForm?.haulerInfo?.driverName}
            />
            <DriverPhoneInput value={tabsForm?.haulerInfo?.driverPhoneNumber} />
            <InputHelperCount
              label={t("orderIntake.haulerInfo.truckLicensePlate")}
              id="truckLicensePlate"
              value={tabsForm?.haulerInfo?.truckLicensePlate}
            />
            <InputHelperCount
              label={t("orderIntake.haulerInfo.trailerLicensePlate")}
              id="trailerLicensePlate"
              value={tabsForm?.haulerInfo?.trailerLicensePlate}
            />
            <Box sx={{ textAlign: "right" }}></Box>
          </CustomTabPanel>
        )}

        <StickyButtonBox>
          <Box
            sx={{
              display: "flex",
              columnGap: "10px",
              marginBottom: 1,
            }}
          >
            {tabNum === 1 && (
              <Button
                onClick={backButtonFunc}
                variant="outlined"
                size="small"
                startIcon={<KeyboardBackspaceIcon />}
                sx={{ boxSizing: "content-box" }}
              >
                {t("orderIntake.materialOrder.back")}
              </Button>
            )}
            <Button
              onClick={handleSubmit(submitHookForms)}
              variant="contained"
              fullWidth
              startIcon={<EastIcon />}
            >
              {t("orderIntake.materialOrder.apply")}
            </Button>
          </Box>
          {tableRows.length > 1 && (
            <Button
              variant="outlined"
              fullWidth
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => handleRemoveTableRow(tabsForm.orderId)}
            >
              {t("orderIntake.materialOrder.delete")}
            </Button>
          )}
        </StickyButtonBox>
      </FormProvider>
    </Box>
  ) : (
    <OISummary isCollect={isCollect} />
  );
};

export default OITabsForm;
