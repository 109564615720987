import { ListItemIcon } from "@mui/material";
import * as flags from "assets/img/lang/langFlags";

const LanguageFlag = ({ language }) => {
  return (
    <ListItemIcon sx={{ width: "15%" }}>
      <img
        src={flags[language]}
        alt="language flag"
        style={{ width: "60%", height: "auto" }}
      />
    </ListItemIcon>
  );
};

export default LanguageFlag;
