export const today = new Date(new Date().setHours(0, 0, 0, 0, 0));
export const todayEnd = new Date(new Date().setHours(23, 59, 59, 999, 999));

const weekFromTodayinSeconds = new Date(todayEnd).setDate(
  new Date(todayEnd).getDate() + 7
);
export const weekFromToday = new Date(weekFromTodayinSeconds);

export const lastMonthStart = new Date(today);
lastMonthStart.setDate(1);
lastMonthStart.setMonth(new Date().getMonth() - 1);
const lastMonthEndInSeconds = new Date(todayEnd).setDate(0);

export const lastMonthEnd = new Date(lastMonthEndInSeconds);

export const last3rdMonth = new Date(today);
last3rdMonth.setDate(1);
last3rdMonth.setMonth(new Date().getMonth() - 3);

const last14DaysInSeconds = new Date(todayEnd).setDate(
  new Date(todayEnd).getDate() - 14
);

export const last14Days = new Date(last14DaysInSeconds);

const last30DaysInSeconds = new Date(todayEnd).setDate(
  new Date(todayEnd).getDate() - 30
);

export const last30Days = new Date(last30DaysInSeconds);
