import { Box, Typography } from "@mui/material";
import { noOrderInCard } from "assets/img/images";
import usePageType from "hooks/usePageType";
import { useTranslation } from "react-i18next";

const NoDataOverlay = () => {
  const { t } = useTranslation();
  const { isInvoices } = usePageType();

  return (
    <Box
      sx={{
        margin: "auto",
        textAlign: "center",
      }}
    >
      <Box>
        <Box>
          <img
            src={noOrderInCard}
            alt="choose order"
            width="auto"
            height="auto"
          />
        </Box>
        <Box>
          <Typography>
            {isInvoices
              ? t("invoiceDetail.selectInvoice")
              : t("order.orderDetails.selectOrder")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default NoDataOverlay;
