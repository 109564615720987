import { TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const POInput = ({ customerReference }) => {
  const { t } = useTranslation();
  const [po, setPo] = useState("");
  const [isError, setIsError] = useState(false);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    inputValue === "" ? setIsError(true) : setIsError(false);
    setPo(inputValue);
  };

  useEffect(() => {
    setPo(customerReference);
  }, [customerReference]);

  const { setValue } = useFormContext();

  useEffect(() => {
    setValue("customerReference", po);
  }, [setValue, po]);

  return (
    <>
      <Typography marginBottom={1}>
        {t("orderIntake.customerReference")}
      </Typography>
      <TextField
        fullWidth
        value={po}
        error={isError}
        onChange={handleInputChange}
        id="oi-delivery-form-PO"
        label={t("orderIntake.customerReference")}
        helperText={t("required")}
        variant="filled"
        FormHelperTextProps={{ sx: { ml: 0 } }}
        placeholder={t("orderIntake.customerReferencePlaceholder")}
      />
    </>
  );
};

export default POInput;
