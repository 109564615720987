import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import HourglassDisabledIcon from "@mui/icons-material/HourglassDisabled";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import { Typography } from "@mui/material";
import { formatInvoiceStatusText } from "helpers/helpers";

const InvoiceStatusColumn = ({ status }) => {
  const statusMap = {
    open: {
      backgroundColor: "#0184441A",
      color: "#018444",
      component: HourglassBottomIcon,
    },
    partially_paid: {
      backgroundColor: "#BAB3081A",
      color: "#BAB308",
      component: HourglassTopIcon,
    },
    closed: {
      backgroundColor: "#A0A0A01A",
      color: "rgb(127, 155, 166)",
      component: HourglassDisabledIcon,
    },
  };

  const statusIcon = statusMap[status] || {};

  return (
    <Typography
      sx={{
        alignSelf: "flex-start",
        display: "flex",
        fontSize: "14px",
        color: statusIcon.color,
        backgroundColor: statusIcon.backgroundColor,
        padding: 0.5,
        borderRadius: "4px",
        alignItems: "center",
        columnGap: 0.5,
        textTransform: "capitalize",
        boxSizing: "border-box",
        "&>svg": {
          fontSize: "14px",
        },
      }}
    >
      <statusIcon.component />
      {formatInvoiceStatusText(status)}
    </Typography>
  );
};

export default InvoiceStatusColumn;
