import { Box, Button, Typography } from "@mui/material";
import HubSnackbar from "Components/UI/HubSnackbar/HubSnackbar";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import VerificationInput from "react-verification-input";
import QuickCodeCountdown from "./QuickCodeCountdown";
import "./VerificationInput.css";

const ByQuickCode = ({ setPasswordLogin, username }) => {
  const { t } = useTranslation();

  const handleSubmit = () => {};

  return (
    <>
      <HubSnackbar msg={123} isOpen={true} />
      <Typography variant="h6" marginBottom={3}>
        {t("landingPage.insertCode")}
      </Typography>
      <Typography variant="body" marginBottom={3}>
        {t("landingPage.codeSent", { email: username })}
      </Typography>
      <form style={{ display: "contents" }} onSubmit={handleSubmit}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography>{username}</Typography>
          <Button
            onClick={setPasswordLogin}
            sx={{ p: 0, textTransform: "capitalize" }}
          >
            {t("landingPage.change")}
          </Button>
        </Box>
        <Box className="custom-styles" sx={{ my: 2, textAlign: "center" }}>
          <VerificationInput
            placeholder=""
            length={6}
            classNames={{
              container: "code-input-container",
              character: "code-input-character",
            }}
          />
        </Box>
        <Button variant="contained" disabled={false} type="submit">
          {t("landingPage.loginButton")}
        </Button>
        <QuickCodeCountdown />
        <Link onClick={setPasswordLogin}>{t("landingPage.usePassword")}</Link>
      </form>
    </>
  );
};

export default ByQuickCode;
