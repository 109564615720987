import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Grid } from "@mui/material";
import { useState } from "react";
import FilterSelectedBox from "../FilterSelectedBox/FilterSelectedBox";
import PaymentStatusPopover from "./PaymentStatusPopover";
import PaymentStatusText from "./PaymentStatusText";

const PaymentStatusFilter = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverWidth, setPopoverWidth] = useState(null);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
    setPopoverWidth(e.currentTarget.offsetWidth);
  };
  const statusArray = [
    "filterMenu.allStatus",
    "invoice.paymentStatusFilter.open",
    "invoice.paymentStatusFilter.closed",
    "invoice.paymentStatusFilter.partiallyPaid",
  ];

  const [choices, setChoices] = useState(statusArray);

  const popoverContentProps = {
    popoverWidth,
    setChoices,
    choices,
    statusArray,
    anchorEl,
    setAnchorEl,
  };

  return (
    <Grid item>
      <FilterSelectedBox
        label={"invoice.paymentStatus"}
        icon={<ArrowDropDownIcon />}
        handleClick={handleClick}
      >
        <PaymentStatusText choices={choices} />
      </FilterSelectedBox>
      <PaymentStatusPopover {...popoverContentProps} />
    </Grid>
  );
};

export default PaymentStatusFilter;
