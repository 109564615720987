import { Box } from "@mui/material";

const PageWrapper = ({ children }) => {
  return (
    <Box
      sx={{
        padding: { xs: "8px 16px", lgr: "15px 52px" },
      }}
    >
      {children}
    </Box>
  );
};

export default PageWrapper;
