import { Box, Grid, Paper, Toolbar, Typography } from "@mui/material";
import OIAdditionalInfo from "Components/OrderIntake/OIAdditionalInfo.jsx";
import OIConfirmation from "Components/OrderIntake/OIConfirmation.jsx";
import OIOverview from "Components/OrderIntake/OIOverview.jsx";
import OITabsForm from "Components/OrderIntake/OITabsForm.jsx";
import BackButton from "Components/UI/BackButton/BackButton.jsx";
import { useActions } from "hooks/useActions.jsx";
import { useIsComponentEnabled } from "hooks/useIsComponentEnabled.jsx";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useGetDeliveryOptionsQuery } from "reduxStore/api/deliveryApiSlice.js";

const OrderIntake = ({ isCollect = false }) => {
  const { t } = useTranslation();
  const { setIsConfirmation, setIsTabsFormOpen } = useActions();

  const isConfirmation = useSelector(
    (state) => state.deliveryOI.isConfirmation
  );

  const {
    isFetching: isFetchingDeliveries,
    isSuccess: isSuccessDeliveries,
    data,
  } = useGetDeliveryOptionsQuery(isCollect ? "collect" : "deliver", {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    setIsConfirmation(false);
    setIsTabsFormOpen(true);
  }, [setIsConfirmation, setIsTabsFormOpen]);

  const { getIsComponentEnabled } = useIsComponentEnabled();
  const isComponentEnabled = getIsComponentEnabled("OrderIntake");

  if (!isComponentEnabled) return <Navigate to="/overview" replace={true} />;

  if (isFetchingDeliveries)
    return <Typography sx={{ color: "white" }}>Loading!</Typography>;

  if (!data?.length)
    return <Typography sx={{ color: "white" }}>No options!</Typography>;

  return (
    <>
      <Toolbar>
        <BackButton>
          {isCollect
            ? t("overview.orderIntakeCollectWidgetTitle")
            : t("overview.scheduleNewDelivery")}
        </BackButton>
      </Toolbar>
      <Box
        sx={{
          p: { md: 4 },
          display: "flex",
          justifyContent: "center",
        }}
      >
        {!isFetchingDeliveries && isSuccessDeliveries && (
          <Grid container maxWidth="1280px">
            <Grid item xs={12} md={8} sx={{ marginBottom: { xs: 4, md: 0 } }}>
              <Paper square={false} sx={{ padding: 4 }}>
                {isConfirmation ? (
                  <OIConfirmation isCollect={isCollect} />
                ) : (
                  <OIOverview isCollect={isCollect} />
                )}
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} sx={{ paddingLeft: { xs: "0", md: 2 } }}>
              <Paper square={false} sx={{ p: 4 }}>
                {isConfirmation ? (
                  <OIAdditionalInfo isCollect={isCollect} />
                ) : (
                  <OITabsForm isCollect={isCollect} />
                )}
              </Paper>
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default OrderIntake;
