import {
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  Paper,
  Popover,
} from "@mui/material";
import { convertPaymentStatusToQuery } from "helpers/helpers";
import { useGetTableData } from "hooks/useGetTableData";
import { useTranslation } from "react-i18next";

const PaymentStatusPopover = ({
  popoverWidth,
  setChoices,
  choices,
  statusArray,
  anchorEl,
  setAnchorEl,
}) => {
  const { t } = useTranslation();
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const { setQueryParams } = useGetTableData();

  const handleClose = () => {
    setAnchorEl(null);

    const statusQuery = convertPaymentStatusToQuery(choices);
    console.log(statusQuery);

    setQueryParams({ status: statusQuery });
    if (choices.length === 0) setChoices(statusArray);
  };

  const handleCheck = (e, option, index, options) => {
    const isAllStatus = t(option) === t("filterMenu.allStatus");
    const isAllChoicesSelected = choices.length === options.length;

    if (e.target.checked) {
      if ((!isAllStatus && choices.length === 2) || isAllStatus) {
        setChoices(options);
      } else {
        setChoices((prev) => [...prev, options[index]]);
      }
    } else {
      if (isAllChoicesSelected && isAllStatus) return;
      if (isAllChoicesSelected && !isAllStatus) {
        setChoices([option]);
      } else {
        setChoices((prev) =>
          prev.filter((choice) => choice !== options[index])
        );
      }
    }
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      disableScrollLock
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{ marginTop: 1 }}
    >
      <Paper sx={{ width: popoverWidth, pt: 1 }}>
        <List
          sx={{
            paddingY: 0,
            maxHeight: "250px",
            overflowY: "auto",
          }}
        >
          {statusArray.map((option, index, options) => {
            const isChecked = choices.includes(options[index]);
            return (
              <ListItem
                key={index}
                disablePadding
                divider={index < options.length - 1}
                sx={{
                  alignItems: "flex-start",
                  paddingX: 1,
                  "&:hover": {
                    background: "rgba(0, 0, 0, 0.04);",
                  },
                }}
              >
                <FormControlLabel
                  sx={{ width: "100%" }}
                  control={<Checkbox />}
                  checked={isChecked}
                  label={t(option)}
                  onChange={(e) => handleCheck(e, option, index, options)}
                />
              </ListItem>
            );
          })}
        </List>
      </Paper>
    </Popover>
  );
};

export default PaymentStatusPopover;
