import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { OverviewBoxHeadingStyled } from "Components/UI/Typography/SharedTypography";
import { useTranslation } from "react-i18next";
import CreditWidgetOverview from "../CreditWidget/CreditWidgetOverview";
import OrderListOIButton from "../UI/OrderListOIButton/OrderListOIButton";
import { PageHeading } from "../UI/Typography/SharedTypography";
import {
  OverviewBoxStyled,
  OverviewButtonBoxStyled,
  OverviewGridContainerStyled,
} from "./Shared";

const FinanceGrid = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageHeading sx={{ marginY: 4 }}>{t("overview.finance")}</PageHeading>
      <CreditWidgetOverview />
      <OverviewGridContainerStyled>
        <Grid item md={6} xs={12}>
          <OverviewBoxStyled sx={{ minHeight: "120px" }}>
            <OverviewBoxHeadingStyled>
              {t("overview.invoiceByDate")}
            </OverviewBoxHeadingStyled>
            <OverviewButtonBoxStyled sx={{ display: "flex" }}>
              <OrderListOIButton>{t("overview.prevMonth")}</OrderListOIButton>
              <OrderListOIButton>{t("overview.prevQuarter")}</OrderListOIButton>
            </OverviewButtonBoxStyled>
          </OverviewBoxStyled>
        </Grid>
        <Grid item md={6} xs={12}>
          <OverviewBoxStyled sx={{ minHeight: "120px" }}>
            <OverviewBoxHeadingStyled>
              {t("overview.latestInvoices", { days: 30 })}
            </OverviewBoxHeadingStyled>
            <Box sx={{ overflowX: "auto" }}>
              <Table
                sx={{
                  "& .MuiTableCell-root": {
                    color: (theme) => theme.palette.text.secondary,
                    borderBottom: "none",
                  },
                }}
              >
                <TableBody>
                  <TableRow sx={{ cursor: "pointer" }}>
                    <TableCell>08/14/2024</TableCell>
                    <TableCell>6000205673</TableCell>
                    <TableCell>open</TableCell>
                    <TableCell>₽279,685.00</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
            <Typography variant="caption">
              {t("overview.noInvoices", { days: 30 })}
            </Typography>
            <Box>
              <Button sx={{ textTransform: "none" }}>
                {t("overview.showAll")}
              </Button>
            </Box>
          </OverviewBoxStyled>
        </Grid>
      </OverviewGridContainerStyled>
    </>
  );
};

export default FinanceGrid;
