import { Box } from "@mui/material";
import FinanceGrid from "../Components/Overview/FinanceGrid";
import OrdersGrid from "../Components/Overview/OrdersGrid";
import OverviewGrid from "../Components/Overview/OverviewGrid";

const Overview = () => {
  return (
    <Box sx={{ padding: "48px 16px 16px", color: "white" }}>
      <Box
        sx={{
          maxWidth: "1280px",
          margin: "0 auto",
        }}
      >
        <OverviewGrid />
        <FinanceGrid />
        <OrdersGrid />
      </Box>
    </Box>
  );
};

export default Overview;
