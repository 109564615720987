import {
  getDefaultCustomerFromLS,
  getUserId,
  setObjectToLS,
} from "helpers/localstoragehelper";
import { useEffect, useState } from "react";
import { useGetCustomerQuery } from "reduxStore/api/orderListApiSlice";
import { useActions } from "./useActions";
import { useGetTableData } from "./useGetTableData";

export const useCustomerQuery = () => {
  const { setCurrentCustomer } = useActions();
  const { setQueryParams } = useGetTableData();

  const localStorageCustomer = getDefaultCustomerFromLS();
  const customerNameFromLS = localStorageCustomer?.customerName;
  const customerNumberFromLS = localStorageCustomer?.customerNumber;

  const [customerNumberName, setCustomerNumberName] = useState(
    customerNameFromLS || ""
  );

  const [customerNumber, setCustomerNumber] = useState(
    customerNumberFromLS || ""
  );

  const handleCustomerNumberChange = ({ target }) =>
    setCustomerNumberName(target.value);

  const sendCustomerNumber = () => {
    if (customerNumberName === customerNumber)
      return setCustomerNumberName(customerNameFromLS);

    setCustomerNumber(customerNumberName);
  };
  const clear = () => setCustomerNumberName("");

  const handleOnBlur = () =>
    !customerNumberName.length && setCustomerNumberName(customerNameFromLS);

  const { data, isFetching, isSuccess } = useGetCustomerQuery(customerNumber, {
    skip: !customerNumber,
  });

  useEffect(() => {
    if (data?.length) {
      setCustomerNumberName(data[0].customerName);
      setQueryParams({ customerId: data[0].customerId });
      setCurrentCustomer(data[0]);
      setObjectToLS(`default-customer-${getUserId()}`, data[0]);
    }
  }, [data, setQueryParams, setCurrentCustomer]);

  return {
    clear,
    handleOnBlur,
    sendCustomerNumber,
    handleCustomerNumberChange,
    isFetching,
    isSuccess,
    customerNumberName,
    data,
  };
};
