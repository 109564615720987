import { createTheme } from "@mui/material/styles";
import { keyframes } from "@mui/system";

export const theme = createTheme({
  typography: {
    fontFamily: `"Inter", "Roboto", "Arial", sans-serif`,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      lgr: 1280,
      xl: 1536,
    },
  },
  palette: {
    text: {
      primary: "#00374D",
      secondary: "#7F9BA6",
      headings: "#54708C",
    },
    inputBackgroundColor: {
      main: "#F7F7F7",
    },
    inputBorderColor: {
      main: "#E7E7E7",
    },
    error: {
      main: "#CC0801",
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          ":hover": {
            cursor: "pointer",
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxSizing: "border-box",
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          "&&&:before": {
            borderBottom: "none",
          },
          "&&:after": {
            borderBottom: "none",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            backgroundColor: "#F7F7F7",
            border: "1px solid #E7E7E7",
            borderRadius: "4px",
            transition: "none",
          },
          "& .MuiInputBase-root:hover": {
            background: "#E7E7E7",
          },
        },
      },
    },
  },
});

export const slideCard = keyframes`from {
  right: -100%;
}
to {
  right: 0;
}
`;

export const ease = keyframes`from {
  opacity: 0;
}
to {
  opacity: 1;
}
`;
