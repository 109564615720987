import { Box } from "@mui/material";
import { OverviewBoxHeadingStyled } from "Components/UI/Typography/SharedTypography";
import {
  dispatchedColor,
  notDispatchedColor,
  unpaidColor,
} from "constants/constants";
import { useTranslation } from "react-i18next";
import CreditWidgetBrackets from "./CreditWidgetBrackets";
import CreditWidgetColorBar from "./CreditWidgetColorBar";

const CreditWidgetInstance = ({ creditData }) => {
  const { t } = useTranslation();
  return (
    <>
      <CreditWidgetBrackets creditData={creditData} />
      <Box
        sx={{
          display: "flex",
          height: "10px",
          width: "100%",
          background: "black",
          overflow: "clip",
          marginBottom: 2,
        }}
      >
        <CreditWidgetColorBar
          color={unpaidColor}
          amount={creditData.receivables}
          total={creditData.total}
        />
        <CreditWidgetColorBar
          color={dispatchedColor}
          amount={creditData.deliveredNotBilled}
          total={creditData.total}
        />
        <CreditWidgetColorBar
          color={notDispatchedColor}
          amount={creditData.orderedNotDelivered}
          total={creditData.total}
        />
      </Box>
      <Box>
        <OverviewBoxHeadingStyled>
          {t("overview.cashBalance.noRecentPayment", { days: 30 })}
        </OverviewBoxHeadingStyled>
      </Box>
    </>
  );
};

export default CreditWidgetInstance;
