import translationEN from "./en/en.json";
import translationRU from "./ru/ru.json";

export const WEBLATE_URL = "https://weblate.hdcem.ru/api/";

const envMode = process.env.NODE_ENV;

const devLanguageData = {
  backend: {
    loadPath: `${WEBLATE_URL}translations/digital/zakaz/{{lng}}/file/`,
    addPath: "src/utils/i18n/{{lng}}/{{lng}}.json",
    customHeaders: {
      Authorization: process.env.REACT_APP_WEBLATE_API_KEY,
    },
  },
};

const resources = {
  resources: {
    en: {
      translation: translationEN,
    },
    ru: {
      translation: translationRU,
    },
  },
};

export const getLanguageData = () => {
  if (envMode === "development") return devLanguageData;
  if (envMode === "production") return resources;

  return resources;
};
