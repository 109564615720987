import { Box } from "@mui/material";
import { calculatedWidth } from "helpers/helpers";

const CreditWidgetColorBar = ({ color, amount, total }) => {
  return (
    <Box
      sx={{
        width: calculatedWidth(amount, total),
        background: color,
      }}
    />
  );
};

export default CreditWidgetColorBar;
