import { Grid, Paper } from "@mui/material";
import { useIsDesktop } from "hooks/useIsDesktop";

const LeftGrid = ({ children }) => {
  const isDesktop = useIsDesktop();

  return (
    <Grid item xs={12} lgr={8}>
      <Paper
        square={false}
        elevation={4}
        sx={isDesktop ? { padding: "10px 16px" } : { paddingTop: "5px" }}
      >
        {children}
      </Paper>
    </Grid>
  );
};

export default LeftGrid;
