import { getDefaultCustomerFromLS } from "helpers/localstoragehelper";
import { useGetCreditWidgetDataQuery } from "reduxStore/api/creditWidgetApiSlice";
import { OverviewBoxStyled } from "../Overview/Shared";
import CreditWidgetInstance from "./CreditWidgetInstance";
import CreditWidgetLegendBox from "./CreditWidgetLegendBox";
import CreditWidgetTopText from "./CreditWidgetTopText";

const CreditWidgetOverview = () => {
  const localStorageCustomer = getDefaultCustomerFromLS();

  const { data, isSuccess } = useGetCreditWidgetDataQuery(
    localStorageCustomer?.customerId,
    {
      skip: !localStorageCustomer?.customerId,
    }
  );

  return (
    isSuccess &&
    data.map((creditData, index) => (
      <OverviewBoxStyled sx={{ marginBottom: 2 }} key={index}>
        <CreditWidgetTopText
          payer={localStorageCustomer?.customerName}
          payerNumber={creditData.payerNumber}
        />

        <CreditWidgetInstance
          creditData={creditData}
          payer={localStorageCustomer?.customerName}
        />
        <CreditWidgetLegendBox creditData={creditData} />
      </OverviewBoxStyled>
    ))
  );
};

export default CreditWidgetOverview;
