import EastIcon from "@mui/icons-material/East";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import {
  Box,
  Button,
  ButtonBase,
  CardContent,
  Grid,
  Paper,
} from "@mui/material";
import {
  OrderCardHeading,
  OrderCardText,
} from "Components/UI/Typography/OrderCard";
import { useTranslation } from "react-i18next";

const FSMobileGroupedDelivery = ({ invoicedItem, setIsDeliveryItemsOpen }) => {
  const { t } = useTranslation();
  const handleOpenDeliveryItem = () => setIsDeliveryItemsOpen(true);

  return (
    <Paper variant="outlined">
      <CardContent>
        <Grid container spacing={1}>
          <Grid xs={12} item>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <OrderCardHeading variant="subtitle1">
                  {t("invoiceDetail.lineItems.dateShipped")}
                </OrderCardHeading>
                <OrderCardText>09/27/2024</OrderCardText>
              </Box>
              <ButtonBase onClick={handleOpenDeliveryItem}>
                <OpenInFullIcon />
              </ButtonBase>
            </Box>
          </Grid>
          <Grid xs={12} item>
            <OrderCardHeading variant="subtitle1">
              {t("invoiceDetail.lineItems.expand.materialDescription")}
            </OrderCardHeading>
            <OrderCardText>ЦЕМ I 42,5Н</OrderCardText>
          </Grid>
          <Grid xs={12} item>
            <OrderCardHeading variant="subtitle1">
              {t("invoiceDetail.lineItems.contract")}
            </OrderCardHeading>
            <OrderCardText>3309007053</OrderCardText>
          </Grid>
          <Grid xs={6} item>
            <OrderCardHeading variant="subtitle1">
              {t("invoiceDetail.lineItems.expand.quantity")}
            </OrderCardHeading>
            <OrderCardText>105 t</OrderCardText>
          </Grid>
          <Grid xs={6} item>
            <OrderCardHeading variant="subtitle1">
              {t("invoiceDetail.lineItems.netAmount")}
            </OrderCardHeading>
            <OrderCardText>₽699,212.49</OrderCardText>
          </Grid>
          <Grid xs={12} item>
            <Button
              variant="outlined"
              fullWidth
              startIcon={<EastIcon />}
              onClick={handleOpenDeliveryItem}
              sx={{ padding: "12px 10px" }}
            >
              {t("viewDetails")}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Paper>
  );
};

export default FSMobileGroupedDelivery;
