import InvoiceCostSummary from "./InvoiceCostSummary";
import DeliveryItem from "./InvoiceDeliveryItems";
import InvoiceSummary from "./InvoiceSummary";

const InvoiceDetails = ({ currentInvoiceData }) => {
  const currentOrderProp = { currentInvoiceData };
  return (
    <>
      <InvoiceSummary {...currentOrderProp} />
      <DeliveryItem {...currentOrderProp} />
      <InvoiceCostSummary {...currentOrderProp} />
    </>
  );
};

export default InvoiceDetails;
