import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useActions } from "./useActions";

export const useDetailsCard = () => {
  const isDetailsCardOpen = useSelector(
    (state) => state.contentTable.isDetailsCardOpen
  );
  const { setIsDetailsCardOpen, setOrderId } = useActions();

  const openDetailsCard = () => setIsDetailsCardOpen(true);
  const closeDetailsCard = useCallback(
    () => setIsDetailsCardOpen(false),
    [setIsDetailsCardOpen]
  );

  return { isDetailsCardOpen, closeDetailsCard, openDetailsCard, setOrderId };
};
