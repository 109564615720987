export const setObjectToLS = (name, object) =>
  localStorage.setItem(name, JSON.stringify(object));

export const getObjectFromLS = (name) => JSON.parse(localStorage.getItem(name));

export const getDefaultCustomerFromLS = () => {
  const userId = localStorage.getItem("userId");
  return JSON.parse(localStorage.getItem(`default-customer-${userId}`) || null);
};

export const getUserId = () => localStorage.getItem("userId");
