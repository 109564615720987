import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { ruRU } from "@mui/x-data-grid/locales";
import { rowsPerPage } from "constants/constants";
import { useDetailsCard } from "hooks/useDetailsCard";
import { useGetTableData } from "hooks/useGetTableData";
import { useSelectedItemsCard } from "hooks/useSelectedItemsCard";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { OrderTableStyles } from "./ContentTableBodyStyles";

const ContentTableBody = ({ paginationModel, handlePagination }) => {
  const { i18n } = useTranslation();
  const apiRef = useGridApiRef();

  const { isFetching, data, dataItem, columns, isInvoices } = useGetTableData();
  const { openDetailsCard, closeDetailsCard, setOrderId } = useDetailsCard();

  const {
    openSelectedCard,
    setDeliveriesToExport,
    closeSelectedCard,
    rowSelectionModel,
    setRowSelectionModel,
  } = useSelectedItemsCard(apiRef);

  const openOrderCard = ({ id }) => {
    setOrderId(id - 1);
    openDetailsCard();
  };

  const handlePageSize = ({ pageSize, page }) => {
    handlePagination({
      page: paginationModel.pageSize !== pageSize ? 0 : page,
      pageSize: pageSize,
    });
  };

  const handleRowSelection = (rowIds) => {
    setRowSelectionModel(rowIds);

    if (isInvoices) return;

    const deliveryIds = rowIds
      .filter((rowId) => data.results[rowId - 1].deliveries.length)
      .map((rowId) => data.results[rowId - 1].deliveries[0].deliveryId);

    setDeliveriesToExport(deliveryIds);

    if (rowIds.length) {
      openDetailsCard();
      openSelectedCard();
    }
    if (!rowIds.length) closeSelectedCard();
  };

  useEffect(() => {
    if (isFetching) setRowSelectionModel([]);
  }, [isFetching, setRowSelectionModel]);

  useEffect(() => {
    closeDetailsCard();
  }, [closeDetailsCard]);

  useEffect(() => {
    if (!isFetching) {
      apiRef.current.autosizeColumns({
        includeHeaders: true,
        includeOutliers: true,
      });
    }
  }, [isFetching, apiRef]);

  const rows = data?.results.map((item, index) => dataItem(item, index)) || [];

  return (
    <DataGrid
      autosizeOptions={{
        includeOutliers: true,
        includeHeaders: true,
      }}
      autosizeOnMount
      autoHeight
      checkboxSelection
      disableRowSelectionOnClick
      disableColumnMenu
      disableColumnResize
      rowCount={data?.count || 0}
      paginationMode="server"
      columnHeaderHeight={40}
      rows={rows}
      loading={isFetching}
      apiRef={apiRef}
      columns={columns}
      getRowHeight={() => "auto"}
      pageSizeOptions={rowsPerPage}
      onRowClick={openOrderCard}
      sortingOrder={["desc", "asc", null]}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 10 },
        },
      }}
      paginationModel={paginationModel}
      onPaginationModelChange={handlePageSize}
      onRowSelectionModelChange={handleRowSelection}
      rowSelectionModel={rowSelectionModel}
      sx={OrderTableStyles}
      localeText={
        i18n.language === "ru"
          ? ruRU.components.MuiDataGrid.defaultProps.localeText
          : null
      }
    />
  );
};

export default ContentTableBody;
