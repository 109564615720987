import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Button, ButtonBase } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const PhoneInput = ({
  label,
  marginTop = 0,
  fullWidth = false,
  isHideable = false,
  visible = false,
}) => {
  const { t } = useTranslation();
  const [isBackupPhoneVisible, setIsBackupPhoneVisible] = useState(visible);
  const handleIsBackupPhoneVisible = () =>
    setIsBackupPhoneVisible((prev) => !prev);

  const [value, setValue] = useState("");
  const [isValid, setIsValid] = useState(false);

  const handleChange = (newValue) => {
    setIsValid(!matchIsValidTel(newValue));
    setValue(newValue);
  };

  return isBackupPhoneVisible ? (
    <MuiTelInput
      fullWidth={fullWidth}
      error={isValid}
      id="contact-phone"
      label={label}
      helperText={t("required")}
      variant="filled"
      defaultCountry="RU"
      FormHelperTextProps={{ sx: { marginLeft: 0 } }}
      value={value}
      onChange={handleChange}
      sx={{ marginTop: marginTop, flexBasis: "45%" }}
      InputProps={{
        disableUnderline: true,
        endAdornment: isHideable && (
          <Tooltip title={t("orderIntake.alternativePhoneRemoveTooltip")}>
            <ButtonBase onClick={handleIsBackupPhoneVisible}>
              <CancelIcon />
            </ButtonBase>
          </Tooltip>
        ),
      }}
    />
  ) : (
    <Box
      sx={{
        display: "flex",
        flexBasis: "45%",
        justifyContent: "center",
      }}
    >
      <Button
        onClick={handleIsBackupPhoneVisible}
        startIcon={<AddCircleIcon />}
      >
        {t("orderIntake.addBackupPhone")}
      </Button>
    </Box>
  );
};

export default PhoneInput;
