import { Box, Link, Typography } from "@mui/material";
import parse from "html-react-parser";
import { MainColorHeading } from "../UI/Typography/SharedTypography";

const LegalPageOssCard = ({ lib }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", columnGap: 4 }}>
      <Box paddingY={2}>
        <MainColorHeading>{lib.library}</MainColorHeading>
        <Typography>{lib.version}</Typography>
        <Typography>{lib.license}</Typography>
        <Link href={lib.link} target="blank">
          {lib.link}
        </Link>
      </Box>
      <Box>{parse(lib.text)}</Box>
    </Box>
  );
};

export default LegalPageOssCard;
