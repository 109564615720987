import { apiSlice } from "./apiSlice";

const USERS = "users/";

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query({
      query: (id) => `${USERS}${id}/`,
    }),
    sendPrivacyAccepted: builder.mutation({
      query: ({ id, payload }) => ({
        url: `${USERS}${id}/`,
        method: "PATCH",
        body: payload,
      }),
    }),
  }),
});

export const { useGetUserQuery } = userApiSlice;
export const { useSendPrivacyAcceptedMutation } = userApiSlice;
