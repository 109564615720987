import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const PaymentStatusText = ({ choices }) => {
  const { t } = useTranslation();

  const renderStatusText = () => {
    if (choices.length === 0) return t("filterMenu.allStatus");

    const initialChoice = t(choices[0]);
    if (choices.length > 1 && initialChoice !== t("filterMenu.allStatus"))
      return `${initialChoice} + 1`;

    return initialChoice;
  };

  return (
    <Typography variant="body1" noWrap>
      {renderStatusText()}
    </Typography>
  );
};

export default PaymentStatusText;
