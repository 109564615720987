import { Box, Tooltip } from "@mui/material";
import {
  deliverTruckBulk,
  pickupTruckBulk,
} from "assets/img/statuses/statusImages";
import FormatDate from "Components/UI/FormatDate/FormatDate";
import InvoiceStatusColumn from "Components/UI/PageComponents/ContentTable/ContentTableBody/Columns/InvoiceStatusColumn";
import {
  InvoiceItem,
  OrderItem,
} from "Components/UI/PageComponents/ContentTable/ContentTableBody/ContentTableItems";
import PdfLink from "Components/UI/PdfLink/PdfLink";
import StatusIcon from "Components/UI/StatusIcon/StatusIcon";
import {
  OrderTableCaption,
  OrderTableText,
} from "Components/UI/Typography/OrderList";
import { dateComparator } from "helpers/helpers";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  useGetInvoicesQuery,
  useLazyFilterByInvoiceQuery,
} from "reduxStore/api/invoicesListApiSlice";
import {
  useGetOrderListQuery,
  useLazyFilterByOrderQuery,
} from "reduxStore/api/orderListApiSlice";
import { useActions } from "./useActions";
import usePageType from "./usePageType";

export const useGetTableData = () => {
  const { t } = useTranslation();

  const { isInvoices } = usePageType();

  const { setInvoiceQuery, setOrderQuery } = useActions();

  const invoiceQuery = useSelector((state) => state.contentTable.invoiceQuery);
  const orderQuery = useSelector((state) => state.contentTable.orderQuery);

  const [filterByInvoice] = useLazyFilterByInvoiceQuery(invoiceQuery);
  const [filterByOrder] = useLazyFilterByOrderQuery(orderQuery);

  const {
    data: invoiceData,
    isSuccess: isSuccessInvoiceData,
    isFetching: isFetchingInvoiceData,
    isLoading: isLoadingInvoiceData,
    refetch: refetchInvoiceData,
  } = useGetInvoicesQuery(invoiceQuery, {
    skip: !invoiceQuery?.customerId || !isInvoices,
  });

  const {
    data: OrderData,
    isSuccess: isSuccessOrderData,
    isFetching: isFetchingOrderData,
    isLoading: isLoadingOrderData,
    refetch: refetchOrderData,
  } = useGetOrderListQuery(orderQuery, {
    skip: !orderQuery?.customerId || isInvoices,
  });

  const OrderTableColumns = [
    {
      field: "shippingDate",
      headerName: t("order.requestedDate"),
      sortComparator: dateComparator,
      renderCell: (params) => (
        <Box>
          <OrderTableText sx={{ fontWeight: 500 }}>
            <FormatDate
              day="2-digit"
              month="2-digit"
              year="numeric"
              timeStart={params.value.date}
            />
          </OrderTableText>
          <OrderTableCaption>
            <FormatDate
              hour="2-digit"
              minute="2-digit"
              timeStart={params.value.startTime}
              timeEnd={params.value.endTime}
            />
          </OrderTableCaption>
        </Box>
      ),
      flex: 0,
    },
    {
      field: "po-order",
      headerName: t("order.orderNumber"),
      renderCell: (params) => (
        <Box>
          <OrderTableText>{params.value.customerReference}</OrderTableText>
          <OrderTableCaption>{params.value.orderNumber}</OrderTableCaption>
        </Box>
      ),
      flex: 1,
      sortable: false,
    },
    {
      field: "destination",
      headerName: t("order.project"),
      renderCell: (params) => (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <OrderTableText>{params.value.siteName}</OrderTableText>
          <OrderTableCaption>{params.value.street}</OrderTableCaption>
        </Box>
      ),
      flex: 1,
      sortable: false,
    },
    {
      field: "material",
      headerName: t("order.material"),
      renderCell: (params) => <OrderTableText>{params.value}</OrderTableText>,
      flex: 1,
      sortable: false,
    },
    {
      field: "ordered-delivered",
      headerName: t("order.orderProgress"),
      renderCell: ({ value }) => {
        const isShippingTypeCollect = value.shippingType === "collect";
        const shippingType = isShippingTypeCollect
          ? pickupTruckBulk
          : deliverTruckBulk;

        const tooltipTitle = isShippingTypeCollect
          ? t("order.modeOfTransport.PickupBulkCarrier")
          : t("order.modeOfTransport.DeliverBulkCarrier");

        return (
          <Box sx={{ display: "flex", columnGap: "5px" }}>
            <OrderTableText>
              {value.quantity} {t("capacityTons")}
            </OrderTableText>
            <Tooltip title={tooltipTitle}>
              <img src={shippingType} alt={shippingType} width="24px" />
            </Tooltip>
          </Box>
        );
      },
      flex: 0,
      sortable: false,
    },
    {
      field: "status",
      headerName: t("order.orderStatus"),
      renderCell: (params) => <StatusIcon status={params.value} />,
      flex: 0,
      maxWidth: 60,
      sortable: false,
    },
  ];

  const InvoiceTableColumns = [
    {
      field: "creationDate",
      headerName: t("invoice.date"),
      sortComparator: dateComparator,
      renderCell: (params) => (
        <Box>
          <OrderTableText sx={{ fontWeight: 500 }}>
            <FormatDate
              day="2-digit"
              month="2-digit"
              year="numeric"
              timeStart={params.value}
            />
          </OrderTableText>
        </Box>
      ),
      flex: 1,
    },
    {
      field: "invoiceNumber",
      headerName: t("invoice.number"),
      renderCell: (params) => <PdfLink>{params.value}</PdfLink>,
      flex: 1.5,
      sortable: false,
    },
    {
      field: "type",
      headerName: t("invoice.type"),
      renderCell: (params) => <OrderTableText>{params.value}</OrderTableText>,
      flex: 1,
      sortable: false,
    },
    {
      field: "invoiceNetValue",
      headerName: t("invoice.netvalue"),
      renderCell: (params) => <OrderTableText>{params.value}</OrderTableText>,
      flex: 1,
      sortable: false,
    },
    {
      field: "invoiceGrossValue",
      headerName: t("invoice.grossValue"),
      renderCell: (params) => <OrderTableText>{params.value}</OrderTableText>,
      flex: 1,
      sortable: false,
    },
    {
      field: "status",
      headerName: t("invoice.status"),
      renderCell: (params) => <InvoiceStatusColumn status={params.value} />,
      sortable: false,
      minWidth: 100,
      flex: 1,
    },
  ];

  return {
    isInvoices,
    columns: isInvoices ? InvoiceTableColumns : OrderTableColumns,
    data: isInvoices ? invoiceData : OrderData,
    query: isInvoices ? invoiceQuery : orderQuery,
    setQueryParams: isInvoices ? setInvoiceQuery : setOrderQuery,
    isSuccess: isInvoices ? isSuccessInvoiceData : isSuccessOrderData,
    isFetching: isInvoices ? isFetchingInvoiceData : isFetchingOrderData,
    isLoading: isInvoices ? isLoadingInvoiceData : isLoadingOrderData,
    findOne: isInvoices ? filterByInvoice : filterByOrder,
    dataItem: isInvoices ? InvoiceItem : OrderItem,
    refetch: isInvoices ? refetchInvoiceData : refetchOrderData,
  };
};
