import { createTheme, ThemeProvider } from "@mui/material/styles";
import { keyframes } from "@mui/system";
import { useIsComponentEnabled } from "hooks/useIsComponentEnabled";
import FullscreenInvoice from "Pages/FullscreenInvoice";
import { Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import RequireAuth from "./auth/requireAuth";
import Layout from "./Components/Layout/Layout";
import Loader from "./Components/UI/Loader/Loader";
import { AuthProvider } from "./context/AuthProvider";
import FullscreenOrder from "./Pages/FullscreenOrder";
import Invoices from "./Pages/Invoices";
import LegalPage from "./Pages/LegalPage";
import Login from "./Pages/Login";
import NotFound from "./Pages/NotFound";
import OrderIntake from "./Pages/OrderIntake";
import OrderList from "./Pages/OrderList";
import Overview from "./Pages/Overview";
import "./utils/i18n";

const theme = createTheme({
  typography: {
    fontFamily: `"Inter", "Roboto", "Arial", sans-serif`,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      lgr: 1280,
      xl: 1536,
    },
  },
  palette: {
    text: {
      primary: "#00374D",
      secondary: "#7F9BA6",
      headings: "#54708C",
    },
    inputBackgroundColor: {
      main: "#F7F7F7",
    },
    inputBorderColor: {
      main: "#E7E7E7",
    },
    error: {
      main: "#CC0801",
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          ":hover": {
            cursor: "pointer",
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxSizing: "border-box",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            backgroundColor: "#F7F7F7",
            border: "1px solid #E7E7E7",
            borderRadius: "4px",
            transition: "none",
          },
          "& .MuiInputBase-root:hover": {
            background: "#E7E7E7",
          },
        },
      },
      defaultProps: {
        InputProps: {
          disableUnderline: true,
        },
      },
    },
  },
});

export const slideCard = keyframes`from {
  right: -100%;
}
to {
  right: 0;
}
`;

export const ease = keyframes`from {
  opacity: 0;
}
to {
  opacity: 1;
}
`;

const App = () => {
  const { isFetching } = useIsComponentEnabled();

  if (isFetching) return <Loader />;

  return (
    <Suspense fallback={<Loader />}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <AuthProvider>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/tos" element={<LegalPage page="terms" />} />
              <Route path="/privacy" element={<LegalPage page="privacy" />} />
              <Route path="/oss" element={<LegalPage page="oss" />} />
              <Route element={<RequireAuth />}>
                <Route element={<Layout />}>
                  <Route
                    path="/"
                    element={<Navigate to="/overview" replace={true} />}
                  />
                  <Route path="/orders" element={<OrderList />} />
                  <Route path="/order/:orderId" element={<FullscreenOrder />} />
                  <Route path="/invoices" element={<Invoices />} />
                  <Route
                    path="/invoices/:invoiceId"
                    element={<FullscreenInvoice />}
                  />
                  <Route path="/bulkCementDelivery" element={<OrderIntake />} />
                  <Route
                    path="/bulkCementCollection"
                    element={<OrderIntake isCollect />}
                  />
                  <Route path="*" element={<NotFound />} />
                  <Route path="/overview" element={<Overview />} />
                </Route>
              </Route>
            </Routes>
          </AuthProvider>
        </BrowserRouter>
      </ThemeProvider>
    </Suspense>
  );
};

export default App;
