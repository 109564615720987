import EastIcon from "@mui/icons-material/East";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { Button, ButtonBase, Card, CardContent, Divider } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { destination, hourglass, tontruck } from "assets/img/images";
import FormatDate from "Components/UI/FormatDate/FormatDate";
import { localizeTime } from "helpers/helpers";
import { useNavigate } from "react-router-dom";

const PageCardOrder = ({ item }) => {
  const shippingDate = `${localizeTime(
    item.schedules[0].scheduleEarliestStartDate
  )} - ${localizeTime(item.schedules[0].scheduleStartDate)}`;

  const navigate = useNavigate();
  const navigateToFullscreen = () =>
    navigate(`/order/${item.orderNumber}`, {
      state: {
        ...item,
        shippingTime: shippingDate,
      },
    });

  return (
    <Card
      variant="outlined"
      sx={{
        margin: "20px 16px",
        textAlign: "left",
        minHeight: "500px",
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexGrow: 1,
              marginBottom: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <img src={hourglass} alt="hourglass" height="16px" />
              <Box sx={{ margin: "4px" }} />
              <Typography variant="h6">{item.orderStatus}</Typography>
            </Box>
            <ButtonBase onClick={navigateToFullscreen}>
              <OpenInFullIcon />
            </ButtonBase>
          </Box>
          <Box sx={{ marginBottom: "10px" }}>
            <Typography>REQUESTED DATE</Typography>
            <Box>
              <Box>
                <Typography variant="h6">
                  <FormatDate
                    day="2-digit"
                    month="2-digit"
                    year="numeric"
                    timeStart={item.shippingDate}
                  />
                </Typography>
                <Typography variant="body2">{shippingDate}</Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ marginBottom: "10px" }}>
            <Typography>PLACED DATE</Typography>
            <Box>
              <Box>
                <Typography variant="h6">
                  <FormatDate
                    day="2-digit"
                    month="2-digit"
                    year="numeric"
                    timeStart={item.placedDate}
                  />
                </Typography>
                <Typography variant="body2">
                  <FormatDate
                    hour="2-digit"
                    minute="2-digit"
                    timeStart={item.placedDate}
                  />
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              marginBottom: "10px",
            }}
          >
            <Typography>PURCHASE ORDER #</Typography>
            <Box>
              <Typography variant="h6">{item.customerReference}</Typography>
              <Typography variant="body2">{item.orderNumber}</Typography>
            </Box>
          </Box>
          <Box
            sx={{
              marginBottom: "10px",
            }}
          >
            <Typography>MATERIAL & VOLUME</Typography>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6">
                  {item.lineItems[0].materialDescription}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    paddingRight: "64px",
                  }}
                >
                  <Typography variant="body2">
                    {item.lineItems[0].quantity}t
                  </Typography>
                  <Box sx={{ margin: "4px" }} />
                  <img src={tontruck} alt="truck" height="24px" />
                </Box>
              </Box>
            </Box>
          </Box>
          <Divider />
          <Box>
            <Box>
              <img src={destination} alt="truck" height="24px" />
            </Box>
            <Box>
              <Box>
                {item.customer.customerName}
                <Typography variant="body2">
                  {`${item.shippingAddress.street} ${item.shippingAddress.city}, ${item.shippingAddress.postalCode}`}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            <Button
              onClick={navigateToFullscreen}
              startIcon={<EastIcon />}
              fullWidth
              variant="outlined"
            >
              View details
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
export default PageCardOrder;
