import CloseIcon from "@mui/icons-material/Close";
import { Box, ButtonBase, Divider, Grid } from "@mui/material";
import InvoiceDeliveryItems from "Components/Invoices/InvoiceDeliveryItems";
import FormatCurrency from "Components/UI/FormatCurrency/FormatCurrency";
import FormatDate from "Components/UI/FormatDate/FormatDate";
import {
  OrderCardHeading,
  OrderCardSub,
  OrderCardText,
} from "Components/UI/Typography/OrderCard";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const FSGroupedDelivery = ({ setIsDeliveryItemsOpen }) => {
  const { t } = useTranslation();
  const invoiceDeliveryDetails = useSelector(
    (state) => state.invoices.invoiceDeliveryDetails
  );

  const closeDetailsCard = () => setIsDeliveryItemsOpen(false);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <OrderCardText>
          {t("invoiceDetail.invoiceDeliveriesItems")}
        </OrderCardText>
        <Box>
          <ButtonBase onClick={closeDetailsCard}>
            <CloseIcon
              sx={{
                padding: "12px",
              }}
            />
          </ButtonBase>
        </Box>
      </Box>
      <Divider sx={{ marginY: 1 }} />
      <Grid
        container
        spacing={2}
        sx={{ alignItems: "center", marginBottom: 1 }}
      >
        <Grid item xs={12} sx={{ display: "flex", flexDirection: "column" }}>
          <OrderCardHeading>
            {t("invoiceDetail.lineItems.dateShipped")}
          </OrderCardHeading>
          <OrderCardSub>
            <FormatDate
              day="2-digit"
              month="2-digit"
              year="numeric"
              timeStart={invoiceDeliveryDetails.summedData.shippingDate}
            />
          </OrderCardSub>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "left" }}>
          <OrderCardHeading>
            {t("invoiceDetail.lineItems.expand.materialDescription")}
          </OrderCardHeading>
          <Box>
            <OrderCardSub>
              {invoiceDeliveryDetails.summedData.materialDescription}
            </OrderCardSub>
          </Box>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: "left" }}>
          <OrderCardHeading>
            {t("invoiceDetail.lineItems.expand.quantity")}
          </OrderCardHeading>
          <Box>
            <OrderCardSub>
              {invoiceDeliveryDetails.summedData.quantity}
              {invoiceDeliveryDetails.summedData.quantityUom}
            </OrderCardSub>
          </Box>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: "left" }}>
          <OrderCardHeading>
            {t("invoiceDetail.lineItems.expand.netAmount")}
          </OrderCardHeading>
          <Box>
            <OrderCardSub>
              <FormatCurrency
                value={invoiceDeliveryDetails.summedData.unitPrice}
                currency={invoiceDeliveryDetails.summedData.currency}
              />
            </OrderCardSub>
          </Box>
        </Grid>
      </Grid>
      <InvoiceDeliveryItems
        deliveryItems={invoiceDeliveryDetails.deliveries}
        currency={invoiceDeliveryDetails.summedData.currency}
      />
    </>
  );
};

export default FSGroupedDelivery;
