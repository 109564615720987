import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  IconButton,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import HubSnackbar from "Components/UI/HubSnackbar/HubSnackbar";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useLoginMutation } from "reduxStore/api/authApiSlice";

const StyledTextnput = styled((props) => (
  <TextField hiddenLabel variant="filled" required {...props} />
))(() => ({
  ".Mui-error": {
    border: "1px solid red",
    borderRadius: "4px",
  },
}));

const ByPassword = ({ setCodeLogin, username, handleUsername }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [login, { isLoading }] = useLoginMutation();

  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handlePassword = ({ target: { value } }) => setPassword(value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userData = await login({ username, password }).unwrap();
      localStorage.setItem("token", userData.token);
      localStorage.setItem("userId", userData.userId);
      navigate("/", { replace: true });
    } catch (error) {
      console.log(error);
      setErrorMsg(`Error ${error?.status}: ${error?.data?.error}`);
      setIsError(true);
    }
  };

  useEffect(() => {
    const errorTimeOut = setTimeout(() => {
      setIsError(false);
    }, 6000);

    return () => {
      clearTimeout(errorTimeOut);
    };
  }, [isError]);

  return (
    <>
      <HubSnackbar msg={errorMsg} isOpen={isError} />
      <Typography variant="h6" marginBottom={3}>
        {t("landingPage.login")}
      </Typography>
      <form style={{ display: "contents" }} onSubmit={handleSubmit}>
        <StyledTextnput
          value={username}
          name="zakaz-login"
          onChange={handleUsername}
          error={isError}
          placeholder="login"
          sx={{ marginBottom: 1 }}
        />
        <StyledTextnput
          value={password}
          name="zakaz-password"
          onChange={handlePassword}
          error={isError}
          placeholder="password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <IconButton
                aria-label={
                  showPassword ? "hide the password" : "display the password"
                }
                onClick={handleClickShowPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            ),
          }}
          sx={{ marginBottom: 2 }}
        />
        <Button variant="contained" disabled={isLoading} type="submit">
          {t("landingPage.loginButton")}
        </Button>
        <Button onClick={setCodeLogin} sx={{ marginY: 2 }}>
          {t("landingPage.getCode")}
        </Button>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            columnGap: "12px",
            marginTop: "auto",
            marginBottom: 2,
          }}
        >
          <Typography>{t("landingPage.requestAccessText")}</Typography>
          <Link>{t("landingPage.requestAccessLink")}</Link>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            columnGap: "12px",
            marginTop: "auto",
            marginBottom: 2,
          }}
        >
          <Typography>{t("landingPage.forgotPassword")}</Typography>
          <Link>{t("landingPage.resetPassword")}</Link>
        </Box>
      </form>
    </>
  );
};

export default ByPassword;
