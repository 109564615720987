import { Box } from "@mui/material";
import { useIsDesktop } from "hooks/useIsDesktop";
import usePageType from "hooks/usePageType";
import AccountFilter from "../Filters/AccountFilter/AccountFilter";
import CustomerNumberFilter from "../Filters/CustomerNumberFilter/CustomerNumberFilter";
import DateRangeFilter from "../Filters/DateRangeFilter/DateRangeFilter";
import NumberFilter from "../Filters/NumberFilter/NumberFilter";
import PaymentStatusFilter from "../Filters/PaymentStatusFilter/PaymentStatusFilter";
import SitesFilter from "../Filters/SitesFilter/SitesFilter";

const PageFilters = () => {
  const { isInvoices } = usePageType();
  const isDesktop = useIsDesktop();

  return (
    <Box display="flex" marginBottom={4} flexWrap="wrap" rowGap="8px">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          gap: "10px",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={(theme) => ({
            display: "flex",
            backgroundColor: theme.palette.inputBackgroundColor.main,
            border: `1px solid ${theme.palette.inputBorderColor.main}`,
            borderRadius: "4px",
            "&:hover": {
              background: "#e7e7e7",
            },
          })}
        >
          <CustomerNumberFilter />
        </Box>
        {false && <AccountFilter />}
        {isDesktop && <NumberFilter />}
      </Box>
      <Box sx={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
        <DateRangeFilter />
        <SitesFilter />
        {isInvoices && <PaymentStatusFilter />}
      </Box>
    </Box>
  );
};

export default PageFilters;
