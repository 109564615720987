import { Typography } from "@mui/material";
import FormatDate from "Components/UI/FormatDate/FormatDate";
import { useGetTableData } from "hooks/useGetTableData";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const ContentTableSearchInfo = () => {
  const { t } = useTranslation();

  const { query } = useGetTableData();
  const currentCustomerName = useSelector(
    (state) => state.contentTable.currentCustomer
  )?.customerName;

  if (!currentCustomerName) return null;

  return (
    <Typography
      sx={{
        fontWeight: 700,
        marginBottom: 3,
        fontSize: "18px",
        paddingLeft: 2,
      }}
    >
      {`${t("resultsFor", { message: currentCustomerName })}, `}
      <FormatDate
        day="2-digit"
        month="2-digit"
        year="numeric"
        timeStart={query.shippingDate}
        timeEnd={query.endDate}
      />
    </Typography>
  );
};

export default ContentTableSearchInfo;
