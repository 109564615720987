import AddIcon from "@mui/icons-material/Add";
import { IconButton, Paper, Popover } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import OIButtons from "./OIButtons";
import QRCodeCaption from "./QRCodeCaption";

const MobileButtonsOI = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <IconButton
        onClick={handleClick}
        disableRipple
        sx={{
          backgroundColor: "white",
          margin: "6px",
          borderRadius: "50%",
        }}
      >
        <AddIcon sx={{ fontSize: "1.25rem", padding: 0.5 }} color="info" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "300px",
            padding: 1,
          }}
        >
          <OIButtons variant="contained" sx={{ paddingY: "0", marginX: 0 }} />
          <QRCodeCaption />
        </Paper>
      </Popover>
    </>
  );
};

export default MobileButtonsOI;
