import { ThemeProvider } from "@mui/material/styles";
import { useIsComponentEnabled } from "hooks/useIsComponentEnabled";
import FullscreenInvoice from "Pages/FullscreenInvoice";
import { Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { theme } from "Theme";
import "./App.css";
import RequireAuth from "./auth/requireAuth";
import Layout from "./Components/Layout/Layout";
import Loader from "./Components/UI/Loader/Loader";
import { AuthProvider } from "./context/AuthProvider";
import FullscreenOrder from "./Pages/FullscreenOrder";
import Invoices from "./Pages/Invoices";
import LegalPage from "./Pages/LegalPage";
import Login from "./Pages/Login";
import NotFound from "./Pages/NotFound";
import OrderIntake from "./Pages/OrderIntake";
import OrderList from "./Pages/OrderList";
import Overview from "./Pages/Overview";
import "./utils/i18n";

const App = () => {
  const { isFetching } = useIsComponentEnabled();

  if (isFetching) return <Loader />;

  return (
    <Suspense fallback={<Loader />}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <AuthProvider>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/tos" element={<LegalPage page="terms" />} />
              <Route path="/privacy" element={<LegalPage page="privacy" />} />
              <Route path="/oss" element={<LegalPage page="oss" />} />
              <Route element={<RequireAuth />}>
                <Route element={<Layout />}>
                  <Route
                    path="/"
                    element={<Navigate to="/overview" replace={true} />}
                  />
                  <Route path="/orders" element={<OrderList />} />
                  <Route path="/order/:orderId" element={<FullscreenOrder />} />
                  <Route path="/invoices" element={<Invoices />} />
                  <Route
                    path="/invoices/details/"
                    element={<FullscreenInvoice />}
                  />
                  <Route path="/bulkCementDelivery" element={<OrderIntake />} />
                  <Route
                    path="/bulkCementCollection"
                    element={<OrderIntake isCollect />}
                  />
                  <Route path="*" element={<NotFound />} />
                  <Route path="/overview" element={<Overview />} />
                </Route>
              </Route>
            </Routes>
          </AuthProvider>
        </BrowserRouter>
      </ThemeProvider>
    </Suspense>
  );
};

export default App;
