import { Button, Tooltip } from "@mui/material";

export const ButtonWithTooltip = ({ tooltipText = "", onClick, children }) => {
  return (
    <Tooltip title={tooltipText}>
      <Button
        disabled={Boolean(tooltipText)}
        onClick={onClick}
        sx={{
          color: (theme) => theme.palette.text.primary,
          "&:hover": {
            background: (theme) => theme.palette.inputBackgroundColor.main,
          },
        }}
      >
        {children}
      </Button>
    </Tooltip>
  );
};

export default ButtonWithTooltip;
