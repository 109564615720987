import { calculatedPage } from "helpers/helpers";
import { useGetTableData } from "hooks/useGetTableData";
import { useState } from "react";
import ContentTableBody from "./ContentTableBody/ContentTableBody";
import ContentTablePagination from "./ContentTablePagination";
import ContentTableSearchInfo from "./ContentTableSearchInfo";

const ContentTable = () => {
  const { query, setQueryParams } = useGetTableData();

  const [paginationModel, setPaginationModel] = useState({
    pageSize: query.limit,
    page: calculatedPage(query.offset, query.limit),
  });

  const handlePagination = (pageModel) => {
    setQueryParams({
      offset: pageModel.page * query.limit,
      limit: pageModel.pageSize,
    });
    setPaginationModel({
      page: pageModel.page,
      pageSize: pageModel.pageSize,
    });
  };

  return (
    <>
      <ContentTablePagination
        handlePagination={handlePagination}
        pageSize={paginationModel.pageSize}
      />
      <ContentTableSearchInfo />
      <ContentTableBody
        paginationModel={paginationModel}
        handlePagination={handlePagination}
      />
    </>
  );
};

export default ContentTable;
