import { getDefaultCustomerFromLS } from "helpers/localstoragehelper";
import { useGetCreditWidgetDataQuery } from "reduxStore/api/creditWidgetApiSlice";

const useCreditWidget = () => {
  const localStorageCustomer = getDefaultCustomerFromLS();

  const { data: creditWidgetData, isSuccess } = useGetCreditWidgetDataQuery(
    localStorageCustomer?.customerId,
    {
      skip: !localStorageCustomer?.customerId,
    }
  );
  return { creditWidgetData, isSuccess, localStorageCustomer };
};

export default useCreditWidget;
