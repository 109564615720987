import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Box, Link } from "@mui/material";

const PdfLink = ({ children, onClick }) => {
  return (
    <Link
      component="button"
      onClick={onClick}
      variant="body2"
      sx={{
        fontSize: "16px",
        color: "rgb(41, 170, 255)",
        textDecoration: "underline rgba(41, 170, 255, 0.4)",
        textAlign: "left",
      }}
    >
      <Box
        sx={{ gap: "8px", display: "inline-flex", textDecoration: "inherit" }}
      >
        <PictureAsPdfIcon fontSize="small" />
        {children}
      </Box>
    </Link>
  );
};

export default PdfLink;
