import { CardContent, Grid, Paper } from "@mui/material";
import FullScreenGridItem from "Components/FullscreenOrder/FullScreenGridItem";
import FormatCurrency from "Components/UI/FormatCurrency/FormatCurrency";
import PdfLink from "Components/UI/PdfLink/PdfLink";
import usePageType from "hooks/usePageType";
import { useTranslation } from "react-i18next";
import { OrderCardHeading, OrderCardText } from "../UI/Typography/OrderCard";

const DeliveryItem = ({ delivery, rate, isShown = false }) => {
  const { t } = useTranslation();
  const PageType = usePageType();

  console.log(rate);

  return (
    <Paper
      elevation={0}
      sx={{
        marginY: 1,
        border: (theme) => `1px solid ${theme.palette.inputBorderColor.main}`,
        background: (theme) => theme.palette.inputBackgroundColor.main,
        width: "100%",
        maskImage: isShown
          ? "none"
          : "linear-gradient(rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%)",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)",
        borderRadius: "8px",
        borderImage: "initial",
        overflow: "hidden",
      }}
    >
      <CardContent sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          <FullScreenGridItem>
            <OrderCardHeading>
              {t("invoiceDetail.lineItems.expand.deliveryNumber")}
            </OrderCardHeading>
            <PdfLink>{delivery.deliveryNumber}</PdfLink>
          </FullScreenGridItem>
          <FullScreenGridItem>
            <OrderCardHeading>
              {t("invoice.expand.orderPoNumber")}
            </OrderCardHeading>
            <OrderCardText>{delivery.orderNumber}</OrderCardText>
          </FullScreenGridItem>
          {PageType && (
            <FullScreenGridItem sm={12}>
              <OrderCardHeading>
                {t("invoiceDetail.lineItems.expand.materialDescription")}
              </OrderCardHeading>
              <OrderCardText>{`${delivery.materialDescription}`}</OrderCardText>
            </FullScreenGridItem>
          )}
          <FullScreenGridItem sm={12}>
            <OrderCardHeading>
              {t("invoiceDetail.lineItems.expand.quantity")}
            </OrderCardHeading>
            <OrderCardText>{`${delivery.loadQuantity} ${delivery.loadQuantityUom}`}</OrderCardText>
          </FullScreenGridItem>
          <FullScreenGridItem>
            <OrderCardHeading>
              {t("order.expand.modeoftransport.truck")}
            </OrderCardHeading>
            <OrderCardText>{delivery.truckName}</OrderCardText>
          </FullScreenGridItem>
          {PageType && (
            <FullScreenGridItem>
              <OrderCardHeading>
                {t("invoiceDetail.lineItems.expand.shippingType")}
              </OrderCardHeading>
              <OrderCardText>
                {t(`invoiceDetail.lineItems.expand.${delivery.shippingType}`)}
              </OrderCardText>
            </FullScreenGridItem>
          )}
          {!PageType && (
            <FullScreenGridItem>
              <OrderCardHeading>
                {t("invoiceDetail.lineItems.expand.unitPrice")}
              </OrderCardHeading>
              <OrderCardText>
                <FormatCurrency
                  value={rate.unitPrice}
                  currency={rate.currency}
                />
              </OrderCardText>
            </FullScreenGridItem>
          )}
        </Grid>
      </CardContent>
    </Paper>
  );
};

export default DeliveryItem;
