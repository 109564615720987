import { Box } from "@mui/material";
import {
  CaptionHeading,
  OverviewBoxHeadingStyled,
} from "Components/UI/Typography/SharedTypography";
import { calculatedWidth, localizeCurrency } from "helpers/helpers";
import { useTranslation } from "react-i18next";

const CreditWidgetBrackets = ({ creditData }) => {
  const { t } = useTranslation();
  const total = creditData.total + creditData.exceededLimit;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
      }}
    >
      {creditData.deposit > 0 && creditData.creditLimit > 0 && (
        <Box
          sx={{
            display: "flex",
            alignItems: "stretch",
            width: calculatedWidth(creditData.deposit, total),
          }}
        >
          <Box sx={{ textAlign: "center", width: "100%" }}>
            <CaptionHeading>{t("overview.cashBalance.deposit")}</CaptionHeading>
            <OverviewBoxHeadingStyled>
              {localizeCurrency(creditData.deposit, creditData.currency)}
            </OverviewBoxHeadingStyled>
            <Box
              sx={{
                height: "10px",
                border: "1px solid grey",
                borderBottom: "none",
              }}
            />
          </Box>
        </Box>
      )}
      {creditData.creditLimit > 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: calculatedWidth(creditData.creditLimit, total),
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <CaptionHeading>
              {t("overview.cashBalance.creditLine")}
            </CaptionHeading>
            <OverviewBoxHeadingStyled>
              {localizeCurrency(creditData.creditLimit, creditData.currency)}
            </OverviewBoxHeadingStyled>
            <Box
              sx={{
                height: "10px",
                border: "1px solid grey",
                borderBottom: "none",
              }}
            />
          </Box>
        </Box>
      )}
      {creditData.exceededLimit > 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: calculatedWidth(creditData.exceededLimit, total),
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <CaptionHeading>
              {t("overview.cashBalance.exceededBy")}
            </CaptionHeading>
            <OverviewBoxHeadingStyled>
              {localizeCurrency(creditData.exceededLimit, creditData.currency)}
            </OverviewBoxHeadingStyled>
            <Box
              sx={{
                height: "10px",
                border: "1px solid red",
                borderBottom: "none",
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CreditWidgetBrackets;
