import { localizeCurrency } from "helpers/helpers";

export const OrderItem = (order, index) => ({
  id: index + 1,
  shippingDate: {
    date: order.shippingDate,
    startTime: order.schedules[0].scheduleEarliestStartDate,
    endTime: order.schedules[0].scheduleStartDate,
  },
  "po-order": {
    customerReference: order.customerReference,
    orderNumber: order.orderNumber,
    index,
  },
  destination: {
    siteName: order.shippingAddress.siteName,
    street: order.shippingAddress.street,
  },
  material: order.lineItems[0].materialDescription,
  "ordered-delivered": {
    quantity: order.lineItems[0].quantity,
    shippingType: order.shippingType,
  },
  status: order.orderStatus,
});

export const InvoiceItem = (invoice, index) => ({
  id: index + 1,
  creationDate: invoice.creationDate,
  invoiceNumber: invoice.customerInvoiceNumber,
  type: invoice.type,
  invoiceNetValue: localizeCurrency(
    invoice.invoiceNetValue,
    invoice.invoiceCurrency
  ),
  invoiceGrossValue: localizeCurrency(
    invoice.invoiceGrossValue,
    invoice.invoiceCurrency
  ),
  status: invoice.status,
});
