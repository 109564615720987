import { Typography } from "@mui/material";
import FormatDate from "Components/UI/FormatDate/FormatDate";
import {
  lastMonthEnd,
  lastMonthStart,
  today,
  weekFromToday,
} from "constants/dates";
import { useTranslation } from "react-i18next";
import StyledCalendarToggleButton from "./StyledCalendarToggleButton";

const SelectionOrders = ({ setLastMonth, setOneWeek, setToday }) => {
  const { t } = useTranslation();
  return (
    <>
      <StyledCalendarToggleButton
        value="week"
        aria-label="one week"
        onClick={setOneWeek}
      >
        <Typography>{t("order.datePicker.oneWeek")}</Typography>
        <Typography>
          <FormatDate
            day="2-digit"
            month="2-digit"
            year="numeric"
            timeStart={today}
            timeEnd={weekFromToday}
          />
        </Typography>
      </StyledCalendarToggleButton>
      <StyledCalendarToggleButton
        value="today"
        aria-label="today"
        onClick={setToday}
      >
        <Typography>{t("order.datePicker.today")}</Typography>
        <Typography>
          <FormatDate
            day="2-digit"
            month="2-digit"
            year="numeric"
            timeStart={today}
          />
        </Typography>
      </StyledCalendarToggleButton>
      <StyledCalendarToggleButton
        value="last month"
        aria-label="last month"
        onClick={setLastMonth}
      >
        <Typography>{t("order.datePicker.lastMonth")}</Typography>
        <Typography>
          <FormatDate
            day="2-digit"
            month="2-digit"
            year="numeric"
            timeStart={lastMonthStart}
            timeEnd={lastMonthEnd}
          />
        </Typography>
      </StyledCalendarToggleButton>
    </>
  );
};

export default SelectionOrders;
