import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  ButtonBase,
  FormControl,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Popover from "@mui/material/Popover";
import { useActions } from "hooks/useActions";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const DestinationDropdown = () => {
  const { t } = useTranslation();
  const deliveryOptions = useSelector(
    (state) => state.deliveryOI.deliveryOptions
  );
  const currentOption = useSelector((state) => state.deliveryOI.currentOption);

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverWidth, setPopoverWidth] = useState(null);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
    setPopoverWidth(e.currentTarget.offsetWidth);
  };

  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const popoverContentProps = {
    popoverWidth,
    deliveryOptions,
    handleClose,
  };

  return (
    <Grid item>
      <ButtonBase
        onClick={handleClick}
        sx={{
          display: "block",
          width: "100%",
        }}
      >
        <Paper
          square={false}
          elevation={0}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: (theme) => theme.palette.inputBackgroundColor.main,
            border: (theme) =>
              `1px solid ${theme.palette.inputBorderColor.main}`,
            padding: "5px",
            paddingBottom: "8px",
            paddingLeft: "12px",
            "&:hover": {
              background: "#E7E7E7",
            },
          }}
        >
          <Box maxWidth="90%" minWidth="222px">
            <Stack textAlign="left">
              <Typography variant="caption" color="text.secondary">
                {t("orderIntake.destination")}
              </Typography>
              <Typography variant="body1" noWrap>
                {`${currentOption?.siteName} - ${currentOption?.siteNumber} - ${currentOption?.street} - ${currentOption?.city}`}
              </Typography>
              <Typography variant="caption">{currentOption?.city}</Typography>
            </Stack>
          </Box>
          <Box>
            <ArrowDropDownIcon />
          </Box>
        </Paper>
      </ButtonBase>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableScrollLock
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ marginTop: 1 }}
      >
        <PopoverContent {...popoverContentProps} />
      </Popover>
    </Grid>
  );
};

const PopoverContent = ({ popoverWidth, deliveryOptions, handleClose }) => {
  const { t } = useTranslation();
  const { setCurrentOption, setTableRow, setTabsForm } = useActions();

  const [searchInput, setSearchInput] = useState("");
  const handleSearchInput = (e) => setSearchInput(e.target.value);

  const tabsForm = useSelector((state) => state.deliveryOI.tabsForm);

  return (
    <Paper sx={{ width: popoverWidth, pl: 1, pt: 1 }}>
      <FormControl fullWidth>
        <TextField
          value={searchInput}
          onChange={handleSearchInput}
          label={t("dropdown.searchList")}
          InputLabelProps={{ shrink: true }}
          variant="standard"
          id="site-search"
          aria-describedby="search site input"
          InputProps={{
            disableUnderline: false,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            sx: {
              "&.MuiInputBase-root": {
                backgroundColor: "white",
                borderColor: "white",
                mr: 1,
              },
            },
          }}
        />
      </FormControl>
      <List
        sx={{
          paddingBottom: 0,
          maxHeight: "250px",
          overflowY: "auto",
        }}
      >
        {deliveryOptions
          .filter((option) => {
            const inputArray = searchInput.toLowerCase().split(" ");
            const optionString =
              `${option.siteName} ${option.siteNumber} ${option.street} ${option.city} ${option.postalCode}`.toLowerCase();
            return inputArray.every((item) => optionString.includes(item));
          })
          .map((option, index, options) => (
            <ListItem
              key={index}
              disablePadding
              divider={index < options.length - 1}
              sx={{
                alignItems: "flex-start",
              }}
            >
              <ListItemButton
                sx={{
                  alignItems: "flex-start",
                  pl: "5px",
                }}
                onClick={() => {
                  handleClose();
                  setCurrentOption(deliveryOptions[index]);
                  setTabsForm({
                    ...deliveryOptions[index],
                    orderId: 0,
                    materials: deliveryOptions[index].materials[0],
                  });
                  setTableRow(tabsForm);
                }}
              >
                <Box>
                  <Typography>{option.siteName}</Typography>
                  <Typography display="block" variant="caption">
                    {`${option.street}, ${option.city}, ${option.postalCode}`}
                  </Typography>
                </Box>
              </ListItemButton>
            </ListItem>
          ))}
      </List>
    </Paper>
  );
};

export default DestinationDropdown;
