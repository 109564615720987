import { Box } from "@mui/material";
import { useIsDesktop } from "hooks/useIsDesktop";
import { useTranslation } from "react-i18next";
import { PageSubHeading } from "../UI/Typography/SharedTypography";
import FullscreenDeliveriesDesktop from "./FullscreenDeliveriesDesktop";
import FullscreenDeliveriesMobile from "./FullscreenDeliveriesMobile";

const FullScreenDeliveriesContainer = ({ deliveries }) => {
  const isDesktop = useIsDesktop();
  const { t } = useTranslation();

  return (
    <Box>
      <PageSubHeading sx={{ marginY: 3 }}>
        {t("order.deliveries")}
      </PageSubHeading>
      {isDesktop ? (
        <FullscreenDeliveriesDesktop deliveries={deliveries} />
      ) : (
        <FullscreenDeliveriesMobile deliveries={deliveries} />
      )}
    </Box>
  );
};

export default FullScreenDeliveriesContainer;
