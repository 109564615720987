import { styled, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  a11yProps,
  CustomTabPanel,
} from "../UI/GenericTabImports/GenericTabImports";
import TimePickerOwn from "./DeliveryOIForm/TimePickerOwn";

const StyledTabs = styled((props) => <Tabs {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.inputBackgroundColor.main,
  border: `1px solid ${theme.palette.inputBorderColor.main}`,
  borderRadius: "7px",
}));

const StyledTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  textTransform: "none",
  flex: "1 1 0",
  border: "none",
  "&.Mui-selected": {
    color: "white",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "7px",
  },
}));

const DeliveryTimeTabs = ({ time }) => {
  const { t } = useTranslation();

  const [value, setValue] = useState(0);
  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: "100%", my: 2 }}>
        <StyledTabs
          onChange={handleChange}
          value={value}
          aria-label="Tabs to choose between whole day and time range delivery"
          TabIndicatorProps={{ hidden: true }}
        >
          <StyledTab
            label={t("orderIntake.timePicker.timeRange")}
            {...a11yProps(0)}
          />
          <StyledTab label={t("orderIntake.wholeDay")} {...a11yProps(1)} />
        </StyledTabs>
        <CustomTabPanel isTimeTabs value={value} index={0}>
          <TimePickerOwn time={time} />
        </CustomTabPanel>
        <CustomTabPanel isTimeTabs value={value} index={1}>
          <TimePickerOwn />
        </CustomTabPanel>
      </Box>
      <Typography sx={{ fontSize: "14px", color: "#00374d", mb: 1 }}>
        {`${t("orderIntake.timePicker.note")}: ${t(
          "orderIntake.timePicker.noteDescription",
          {
            from: "01:00 PM",
            to: "11:59 PM",
          }
        )}`}
      </Typography>
    </>
  );
};

export default DeliveryTimeTabs;
