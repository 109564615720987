import { Box } from "@mui/material";
import OIButtons from "./OIButtons";
import QRCodeCaption from "./QRCodeCaption";

const DesktopButtonsOI = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        marginBottom: "5px",
      }}
    >
      <OIButtons />
      <QRCodeCaption />
    </Box>
  );
};

export default DesktopButtonsOI;
