import { styled, Typography } from "@mui/material";

export const OrderCardHeading = styled((props) => <Typography {...props} />)(
  () => ({
    fontWeight: 700,
    fontSize: "12px",
    color: "#628390",
    textTransform: "uppercase",
  })
);

export const OrderCardText = styled((props) => <Typography {...props} />)(
  () => ({
    fontWeight: 500,
    color: "#00274d",
  })
);

export const OrderCardSub = styled((props) => <Typography {...props} />)(
  ({ theme }) => ({
    fontWeight: 500,
    fontSize: "14px",
    textTransform: "capitalize",
    color: theme.palette.text.primary,
  })
);
