import { Divider } from "@mui/material";
import Box from "@mui/material/Box";
import FormatCurrency from "Components/UI/FormatCurrency/FormatCurrency";
import { useTranslation } from "react-i18next";
import { OrderCardHeading, OrderCardText } from "../UI/Typography/OrderCard";

const InvoiceCostSummary = ({ currentInvoiceData }) => {
  const { t } = useTranslation();
  const boxFlex = { display: "flex", justifyContent: "space-between" };
  return (
    <>
      <OrderCardText>{t("invoiceDetail.costSummary")}</OrderCardText>
      <Divider sx={{ marginY: 1 }} />
      <OrderCardHeading variant="subtitle1">
        {t("invoiceDetail.materials")}
      </OrderCardHeading>
      <Box sx={boxFlex}>
        <OrderCardText>
          {currentInvoiceData?.lineItems[0]?.materialDescription}
        </OrderCardText>
        <OrderCardText>
          <FormatCurrency
            value={currentInvoiceData.invoiceNetValue}
            currency={currentInvoiceData.invoiceCurrency}
          />
        </OrderCardText>
      </Box>
      <OrderCardHeading variant="subtitle1">
        {t("invoiceDetail.tax")}
      </OrderCardHeading>
      <Box sx={boxFlex}>
        <OrderCardText> {t("invoiceDetail.salesTax")}</OrderCardText>
        <OrderCardText>
          <FormatCurrency
            value={currentInvoiceData.invoiceTaxValue}
            currency={currentInvoiceData.invoiceCurrency}
          />
        </OrderCardText>
      </Box>
      <Box
        sx={{
          ...boxFlex,
          padding: 1,
          marginY: 1,
          marginX: -1,
          background: "rgb(235, 243, 252);",
          "&>p": { fontWeight: 700 },
        }}
      >
        <OrderCardText>{t("invoiceDetail.total")}</OrderCardText>
        <OrderCardText>
          <FormatCurrency
            value={currentInvoiceData.invoiceGrossValue}
            currency={currentInvoiceData.invoiceCurrency}
          />
        </OrderCardText>
      </Box>
    </>
  );
};

export default InvoiceCostSummary;
